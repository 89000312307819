import { Button, Modal, TextAreaInput, TextInput } from '@octano/global-ui';
import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { TranslationsKeys } from '../../../../../locales/translations';
import { CourseFormContext } from '../../providers/CourseProvider';
import { CourseFormFields, CourseLearningResult } from '../../types';

interface Props {
  onConfirm: (data: any) => void;
  onDecline: () => void;
}

export interface ModalAddCourseLearningRef {
  openModal: (row?: number) => void;
  closeModal: () => void;
}

type CourseLearningResultTpe = CourseLearningResult | undefined;

const ModalAddCourseLearning = forwardRef<ModalAddCourseLearningRef, Props>(
  ({ onConfirm, onDecline }, ref) => {
    const { t } = useTranslation(
      TranslationsKeys.ACADEMIC_OFFER_COURSES_UPDATE,
    );

    const [modal, setModal] = useState(false);
    const [courseLearningResult, setCourseLearningResult] =
      useState<CourseLearningResultTpe>(undefined);

    const { course } = useContext(CourseFormContext);
    const { watch, getValues, setValue } = useFormContext<CourseFormFields>();
    const learningResults = watch('learningResults');
    const courseCodeFromForm = watch('code');
    //codigo del curso al cual se le editan los resultados de aprendizaje

    const code = useMemo(
      () => courseCodeFromForm ?? course?.code,
      [course, courseCodeFromForm],
    );

    //cuando se edita un learning result, se agrega el su index en la tabla
    const action = useMemo(
      () =>
        typeof courseLearningResult?.indexOnTable === 'number' ? 'edit' : 'add',
      [courseLearningResult?.indexOnTable],
    );

    //Valida si se pueden agregar nuevos learning results
    const canAddNewLearningResult = useMemo(() => {
      if (action === 'edit' && courseLearningResult) {
        return !learningResults?.some(
          (lr, index) =>
            lr.code === courseLearningResult.code &&
            index !== courseLearningResult.indexOnTable,
        );
      }
      return !learningResults?.some(
        (lr) => lr.code === courseLearningResult?.code,
      );
    }, [learningResults, courseLearningResult, action]);

    const textError = useMemo(() => {
      if (!code) return t('modalLearningResultCourseCodeError');
      if (!canAddNewLearningResult) {
        return action === 'edit'
          ? t('modalLearningResultCodeDuplicateError') // Mensaje diferente para edición
          : t('modalLearningResultCodeError');
      }

      return undefined;
    }, [code, action, canAddNewLearningResult, t]);

    const onSave = useCallback(() => {
      if (action === 'add') {
        courseLearningResult && onConfirm(courseLearningResult);
        setCourseLearningResult(undefined);
        setModal(false);
      } else {
        const index = courseLearningResult!.indexOnTable!;
        setValue(`learningResults.${index}`, {
          ...courseLearningResult,
        });
        setModal(false);
      }
    }, [onConfirm, action, courseLearningResult, setValue]);

    useImperativeHandle(
      ref,
      () => ({
        openModal(index?: number) {
          if (typeof index === 'number') {
            const learningResult = getValues(`learningResults.${index}`);
            learningResult.indexOnTable = index;
            setCourseLearningResult(learningResult);
          }
          setModal(true);
        },
        closeModal() {
          setModal(false);
        },
      }),
      [getValues],
    );

    return (
      <Modal
        isOpen={modal}
        onClosed={() => {
          setCourseLearningResult(undefined);
        }}
        toggle={() => !modal}
        size="lg"
        unmountOnClose
      >
        <Row>
          <Col xs={12}>
            <p className="fs-22 text-dark text-center mb-4">
              {action === 'add'
                ? t(`modalLearningResultTitle`)
                : t(`modalLearningResultEditTitle`)}
            </p>
          </Col>
          <Col xs={12}>
            <TextInput
              name="learningResultCode"
              label={t(`modalLearningResultCode`)}
              value={courseLearningResult?.code || ''}
              formatter={(value) => {
                if (value && value.startsWith(`${code}-`)) {
                  return value;
                }
                return `${code}-${value}`;
              }}
              onChange={(e) => {
                let newValue = e.target.value;
                const prevValue = courseLearningResult?.code || '';

                // Mantiene siempre el prefix (code-)
                // si se trata de eliminar
                if (
                  prevValue.startsWith(`${code}-`) &&
                  !newValue.startsWith(`${code}-`)
                ) {
                  newValue = prevValue;
                } else if (!newValue.startsWith(`${code}-`) && !prevValue) {
                  newValue = `${code}-${newValue}`;
                }

                setCourseLearningResult((prev) => ({
                  ...prev,
                  code: newValue,
                }));
              }}
              errorText={textError}
              disabled={!code}
            />
          </Col>
          <Col xs={12}>
            <TextAreaInput
              name="description"
              label={t(`modalLearningResultDescription`)}
              value={courseLearningResult?.description}
              onChange={(e) =>
                setCourseLearningResult((prev) => {
                  return { ...prev, description: e.target.value };
                })
              }
            />
          </Col>
        </Row>
        <Row className="pt-5">
          <Col xs={12} lg={6} className="pb-2 order-2 order-lg-1">
            <Button
              type="button"
              outlined
              onClick={() => {
                onDecline();
                setCourseLearningResult(undefined);
                setModal(false);
              }}
              text={t(`modalCompatibilityCancel`)}
              fullwidth
            />
          </Col>
          <Col xs={12} lg={6} className="pb-2 order-1 order-lg-2">
            <Button
              text={
                action === 'add'
                  ? t(`modalLearningResultSave`)
                  : t(`modalLearningResultEdit`)
              }
              onClick={onSave}
              fullwidth
              disabled={
                !code || (action === 'edit' && !canAddNewLearningResult)
              }
            />
          </Col>
        </Row>
      </Modal>
    );
  },
);

export default ModalAddCourseLearning;
