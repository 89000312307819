import {
  Button,
  DateInput,
  OutlinedSelect,
  OutlinedSelectOptionType,
} from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { studentsPetitionsListExcelFilter } from '../../../../api/requests/studentPetitions';
import { useValidations } from '../../../../hooks/useValidations';

type SearchParamsExcel = {
  petitionTypeId?: string;
  studyPlanId?: number;
  status?: string;
  startDate?: string;
  endDate?: string;
};
type SearchParamsForm = {
  search?: string;
  studyPlanId?: number;
  status?: string;
};

export type ShowSearchParamsExcelF = {
  status?: boolean;
  studyPlanId?: boolean;
  petitionTypeId?: boolean;
};

export type SearchParamsExcelF = SearchParamsExcel & SearchParamsForm;

interface PetitionsExcelReportFormProps {
  texts: any;
  filters: SearchParamsExcelF;
  showFilters?: ShowSearchParamsExcelF;
  onDownload: (formParams: SearchParamsExcelF) => void;
  onCancel: () => void;
}

const PetitionsExcelReportForm = ({
  texts,
  filters,
  showFilters = {},
  onDownload,
  onCancel,
}: PetitionsExcelReportFormProps) => {
  const { t } = useTranslation();
  const [states, setStates] = useState<OutlinedSelectOptionType[]>([]);
  const [studyPlans, setStudyPlans] = useState<OutlinedSelectOptionType[]>([]);
  const [petitionTypes, setPetitionTypes] = useState<
    OutlinedSelectOptionType[]
  >([]);

  const getDefaultValues = useCallback(
    (filters: SearchParamsExcelF | null) => {
      const defaultPetitionType = petitionTypes.find(
        (petitionTypes) => filters?.petitionTypeId === petitionTypes.value,
      ) ?? {
        label: texts.body.default.petitionTypeValue,
        value: null,
      };

      const defaultStudyPlan = studyPlans.find(
        (studyPlans) => filters?.studyPlanId === studyPlans.value,
      ) ?? {
        label: texts.body.default.studyPlanValue,
        value: null,
      };

      const defaultStates = states.find(
        (status) => filters?.status === status.value,
      ) ?? { label: texts.body.default.statusValue, value: null };

      return {
        petitionTypeId: defaultPetitionType,
        studyPlanId: defaultStudyPlan,
        status: defaultStates,
        startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
        endDate: dayjs(new Date()).format('YYYY-MM-DD'),
      };
    },
    [states, studyPlans, petitionTypes, texts],
  );
  useEffect(() => {
    reset(getDefaultValues(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, getDefaultValues]);

  const methods = useForm<any>({
    mode: 'onSubmit',
    defaultValues: getDefaultValues(filters),
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    reset,
    watch,
  } = methods;

  const [startDateWatch, endDateWatch] = watch(['startDate', 'endDate']);

  const { msgValidations, validateAfterDate } = useValidations();
  const onSubmit = useCallback(
    async (formValues: any) => {
      const { studyPlanId, petitionTypeId, status } = formValues;
      const formParams = {
        startDate: startDateWatch,
        endDate: endDateWatch,
        status: status?.value as string,
        studyPlanId: studyPlanId?.value as number,
        petitionTypeId: petitionTypeId?.value as string,
      };
      await onDownload(formParams);
    },
    [onDownload, startDateWatch, endDateWatch],
  );

  const getFilters = useCallback(async () => {
    const translationPreffix = 'studentPetitions.common.excel';
    const { data } = await studentsPetitionsListExcelFilter();
    if (data) {
      const newStates: any[] = [
        { label: texts.body.default.statusValue, value: null },
      ];
      data.data.status.forEach((value: string) =>
        newStates.push({
          label: t(`${translationPreffix}.status.${value}`),
          value: value,
        }),
      );
      setStates(newStates);

      const newStudyPlans: any[] = [
        {
          label: texts.body.default.studyPlanValue,
          value: null,
        },
      ];
      data.data.studyPlans.forEach(({ id: value, name: label }) =>
        newStudyPlans.push({
          label,
          value,
        }),
      );
      setStudyPlans(newStudyPlans);

      const newPetitionTypes: any[] = [
        {
          label: texts.body.default.petitionTypeValue,
          value: null,
        },
      ];
      data.data.petitionTypes.forEach(({ id: value, name: label }) =>
        newPetitionTypes.push({
          label,
          value,
        }),
      );
      setPetitionTypes(newPetitionTypes);
    }
  }, [t, texts]);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  const handleClear = () => {
    reset(getDefaultValues(filters));
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Row className="mt-0 mt-md-3">
              <Col xs="12" md="6">
                <DateInput
                  name="startDate"
                  label={texts.body.dateStart}
                  control={control}
                  maxDate={new Date()}
                  rules={{
                    required: msgValidations.required,
                  }}
                />
              </Col>
              <Col xs="12" md="6">
                <DateInput
                  name="endDate"
                  label={texts.body.dateEnd}
                  control={control}
                  maxDate={new Date()}
                  rules={{
                    required: msgValidations.required,
                    validate: validateAfterDate(startDateWatch),
                  }}
                />
              </Col>
            </Row>

            {showFilters.status && (
              <Row className="mt-0 mt-md-3">
                <Col xs="12" className="mt-2 mt-md-0">
                  <OutlinedSelect
                    name="status"
                    label={texts.body.state}
                    placeholder={texts.body.state}
                    control={control}
                    options={states}
                    isClearable={false}
                  />
                </Col>
              </Row>
            )}

            {showFilters.studyPlanId && (
              <Row className="mt-0 mt-md-3">
                <Col xs="12" className="mt-2 mt-md-0">
                  <OutlinedSelect
                    name="studyPlanId"
                    label={texts.body.studyPlan}
                    placeholder={texts.body.studyPlan}
                    control={control}
                    options={studyPlans}
                    isClearable={false}
                  />
                </Col>
              </Row>
            )}

            {showFilters.petitionTypeId && (
              <Row className="mt-0 mt-md-3">
                <Col xs="12" className="mt-2 mt-md-0">
                  <OutlinedSelect
                    name="petitionTypeId"
                    label={texts.body.petitionType}
                    placeholder={texts.body.petitionType}
                    control={control}
                    options={petitionTypes}
                    isClearable={false}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        <Row>
          <Col className="mt-5">
            <Row>
              <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
                <Button
                  type="button"
                  text={t('common.actions.cancel')}
                  outlined
                  onClick={() => {
                    onCancel();
                    handleClear();
                  }}
                  fullwidth
                />
              </Col>
              <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
                <Button
                  type="submit"
                  text={texts.btn.action}
                  loading={isSubmitting}
                  fullwidth
                  className="mb-3 mb-md-0"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};
export default PetitionsExcelReportForm;
