import { ColumnTable, Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../locales/translations';
import { useTuitionContinuityDiscountManagementFilterLoader } from '../parts/TuitionContinuityDiscountManagementFilterLoader';
import { DiscountManagementListResponse } from '../types';

interface Params {
  onClickUpdate: (row: DiscountManagementListResponse) => void;
  onClickDelete: (tuitionContinuityDiscountId: number) => void;
}

const prefix = (key: string) => `dicountTable.${key}`;

export default function useColumns({
  onClickUpdate,
  onClickDelete,
}: Params): ColumnTable<DiscountManagementListResponse>[] {
  const { t } = useTranslation(TranslationsKeys.TC_DISCOUNT_MANAGEMENT);
  const { data } = useTuitionContinuityDiscountManagementFilterLoader();
  return [
    {
      columnName: 'nameOrId',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      headerText: t(prefix('nameOrId')),
      cellFormat: ({ row }) => (
        <div className="d-flex flex-column">
          <p className="m-0">{row.studentNames}</p>
          <p className="m-0">{row.studentIdentifier}</p>
        </div>
      ),
    },
    {
      columnName: 'school',
      headerText: t(prefix('school')),
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
    {
      columnName: 'studyPlan',
      headerText: t(prefix('studyPlan')),
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
    {
      columnName: 'periodEntry',
      headerText: t(prefix('entryPeriod')),
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
    {
      columnName: 'campus',
      headerText: t(prefix('campus')),
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
    {
      columnName: 'schedule',
      headerText: t(prefix('schedule')),
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
    {
      columnName: 'modality',
      headerText: t(prefix('modality')),
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
    {
      columnName: 'discount',
      headerText: t(prefix('discount')),
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
    {
      columnName: 'discountType',
      headerText: t(prefix('discountType')),
      thClassName: 'text-center',
      tdClassName: 'text-center',
      cellFormat: ({ row }) => {
        return (
          <p>
            {data?.discountTypes.find((x) => x.id === row.discountType)?.name}
          </p>
        );
      },
    },
    {
      columnName: 'discountPeriod',
      headerText: t(prefix('discountPeriod')),
    },
    {
      columnName: 'id',
      headerText: t(prefix('action')),
      thClassName: 'text-center',
      tdClassName: 'text-center',
      cellFormat: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            <button className="btn" onClick={() => onClickUpdate(row)}>
              <Icon color="primary" name="edit" />
            </button>

            <button
              className="btn"
              onClick={() => onClickDelete(row.discountId)}
            >
              <Icon name="trash" color="primary" />
            </button>
          </div>
        );
      },
    },
  ];
}
