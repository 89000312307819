import {
  DateInput,
  Select,
  SelectOptionType,
  TextAreaInput,
  TextInput,
} from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { Col } from 'reactstrap';
import { useValidations } from '../../../../../../../hooks/useValidations';
import { useTexts } from '../../hooks/textsContext';
import { EducationalBackgroundType, EducationRecordFile } from '../../type';
import { useEducationalBackgroundFormDataLoader } from '../Loaders/EducationalBackgroundFormDataLoader';

interface Props {
  modalType: EducationalBackgroundType;
  requiresInstitute: boolean;
  requiresCustomInstitute: boolean;
  requiresRecognition: boolean;
  originInstitutes: SelectOptionType[];
  recognizedOptions: SelectOptionType[];
}

export interface ModalFormData {
  id: number | null;
  name: string;
  studyCarriedOut: string;
  originInstituteCustom: string | null;
  dateOfIssue: Date;
  description: string;
  originInstitute: SelectOptionType | null;
  isRecognized: SelectOptionType | null;
  country: SelectOptionType | null;
  degreeLevel: SelectOptionType | null;
  files: EducationRecordFile<File>[];
  type: EducationalBackgroundType;
}

export const defaultValues: Partial<ModalFormData> = {
  id: null,
  name: '',
  country: null,
  degreeLevel: null,
  studyCarriedOut: '',
  originInstituteCustom: null,
  originInstitute: undefined,
  dateOfIssue: undefined,
  description: '',
  type: undefined,
  files: [],
};

const CreateOrUpdateModalForm = ({
  modalType,
  requiresInstitute,
  requiresRecognition,
  requiresCustomInstitute,
  recognizedOptions,
  originInstitutes,
}: Props) => {
  const texts = useTexts();

  const { control } = useFormContext<ModalFormData>();
  const { data } = useEducationalBackgroundFormDataLoader();
  const { msgValidations } = useValidations();

  return (
    <>
      {modalType !== EducationalBackgroundType.Certificate && (
        <>
          <Col md={6} xs={12}>
            <Select
              name="degreeLevel"
              options={
                modalType === EducationalBackgroundType.AcademicDegree
                  ? data?.degreeLevels ?? []
                  : data?.specialtyLevels ?? []
              }
              label={texts.form.degreeLevel}
              placeholder={texts.form.degreeLevel}
              control={control}
              rules={{ required: msgValidations.required }}
            />
          </Col>
          <Col md={6} xs={12}>
            <TextInput
              name="studyCarriedOut"
              label={texts.form.studyCarriedOut}
              control={control}
              rules={{ required: msgValidations.required }}
            />
          </Col>
        </>
      )}
      <Col md={6} xs={12}>
        <TextInput
          name="name"
          label={
            modalType === EducationalBackgroundType.Certificate
              ? texts.form.name
              : texts.form.title
          }
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </Col>
      <Col md={6} xs={12}>
        <DateInput
          name="dateOfIssue"
          label={texts.form.dateOfIssue}
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </Col>
      <Col md={6} xs={12}>
        <Select
          name="country"
          options={data?.countries ?? []}
          label={texts.form.country}
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </Col>
      {!!requiresInstitute && (
        <Col md={6} xs={12}>
          <Select
            name="originInstitute"
            options={originInstitutes}
            label={
              modalType === EducationalBackgroundType.Certificate
                ? texts.form.originInstituteOrPlataform
                : texts.form.originInstitute
            }
            control={control}
          />
        </Col>
      )}
      {!!requiresCustomInstitute && (
        <Col md={6} xs={12}>
          <TextInput
            name="originInstituteCustom"
            label={
              modalType === EducationalBackgroundType.Certificate
                ? texts.form.originInstituteCustomOrPlataform
                : texts.form.originInstituteCustom
            }
            placeholder={
              modalType === EducationalBackgroundType.Certificate
                ? texts.form.originInstituteCustomOrPlataform
                : texts.form.originInstituteCustom
            }
            control={control}
          />
        </Col>
      )}

      {!!requiresRecognition && (
        <Col md={6} xs={12}>
          <Select
            name="isRecognized"
            options={recognizedOptions}
            label={texts.form.isRecognizedLabel}
            control={control}
          />
        </Col>
      )}

      <Col md={12} xs={12}>
        <TextAreaInput
          name="description"
          label={texts.form.description}
          placeholder={''}
          control={control}
        />
      </Col>
    </>
  );
};

export default CreateOrUpdateModalForm;
