import { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import { CheckInput } from '@octano/global-ui';

import { CourseFormContext } from '../../providers/CourseProvider';
import { SectionTitle } from '../../../../../components/text';
import { CourseFormFields, CourseFormMode } from '../../types';
import { TranslationsKeys } from '../../../../../locales/translations';

const CourseActivityTypeSection = () => {
  const { t } = useTranslation(TranslationsKeys.ACADEMIC_OFFER_COURSES_UPDATE);

  const { control } = useFormContext<CourseFormFields>();

  const { mode, formData, course } = useContext(CourseFormContext);
  const { activities = [] } = formData;

  if (!activities?.length) {
    return <></>;
  }

  return (
    <Row>
      <Col xs={12} className="pt-4">
        <SectionTitle text={t(`activityTypes`)} />
      </Col>
      <Col xs={12}>
        <p className="fs-18 fw-300">{t(`activityTypeDescription`)}</p>
      </Col>
      <Col xs={12}>
        <Row className="px-md-3 py-2">
          <Controller
            control={control}
            name="attendance"
            render={({ field }) => (
              <>
                {activities.map((activity) => (
                  <Col
                    xs={6}
                    md={4}
                    lg={3}
                    xl={2}
                    key={`activity_${activity.value}`}
                  >
                    <CheckInput
                      name={`activity_${activity.value}`}
                      label={activity.label}
                      onChange={() => {
                        if (
                          !field?.value?.find(
                            (e) => e.activityTypeId === +activity.value,
                          )
                        ) {
                          field?.onChange(
                            (field?.value ?? [])?.concat({
                              activityTypeId: +activity.value,
                              minPercentage:
                                course?.assistanceRequirements?.find(
                                  (e) => e.activityTypeId === +activity.value,
                                )?.minPercentage ?? 0,
                            }),
                          );
                        } else {
                          field?.onChange(
                            field?.value?.filter(
                              (e) => e.activityTypeId !== +activity.value,
                            ),
                          );
                        }
                      }}
                      value={
                        !!field?.value?.some(
                          (e) => e.activityTypeId === activity.value,
                        )
                      }
                      disabled={mode === CourseFormMode.WATCH}
                    />
                  </Col>
                ))}
              </>
            )}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default CourseActivityTypeSection;
