import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../../../locales/translations';

interface Texts {
  title: string;
  subtitle: string;
  modal: {
    grados: string;
    especialidades: string;
    certificados: string;
    delete: string;
  };
  table: {
    academicDegrees: {
      title: string;
      columns: {
        degreeLevel: string;
        studyCarriedOut: string;
        name: string;
        description: string;
        country: string;
        originInstitute: string;
        originInstituteCustom: string;
        dateOfIssue: string;
        isRecognizedLabel: string;
        actions: string;
      };
      add: string;
    };
    specialties: {
      title: string;
      columns: {
        degreeLevel: string;
        studyCarriedOut: string;
        name: string;
        description: string;
        country: string;
        originInstitute: string;
        originInstituteCustom: string;
        dateOfIssue: string;
        isRecognizedLabel: string;
      };
      add: string;
    };
    certificates: {
      title: string;
      columns: {
        name: string;
        description: string;
        country: string;
        originInstitute: string;
        originInstituteCustom: string;
        dateOfIssue: string;
        isRecognizedLabel: string;
      };
      add: string;
    };
  };
  form: {
    title: string;
    name: string;
    country: string;
    degreeLevel: string;
    studyCarriedOut: string;
    originInstitute: string;
    originInstituteOrPlataform: string;
    originInstituteCustom: string;
    originInstituteCustomOrPlataform: string;
    originInstituteOther: string;
    isRecognizedLabel: string;
    isRecognized: string;
    isNotRecognized: string;
    isNotNeededRecognized: string;
    dateOfIssue: string;
    description: string;
    linkedFile: string;
    linkedFileName: (name: string, required?: boolean) => string;
    linkedFileNameReq: (name: string) => string;
  };
  actions: {
    add: string;
    save: string;
    cancel: string;
    confirm: string;
    download: string;
    upload: string;
  };
}

interface TextsProviderProps {
  children: ReactNode;
}

const TextsContext = createContext<Texts | undefined>(undefined);

export const TextsProvider = ({ children }: TextsProviderProps) => {
  const { t } = useTranslation(
    TranslationsKeys.TEACHER_MANAGMENT_TEACHER_FILE_EDUCATIONAL_BACKGROUND,
  );

  const texts = useMemo(
    () => ({
      title: t(`title`),
      subtitle: t(`subtitle`),
      modal: {
        grados: t(`modal.academicDegrees`),
        especialidades: t(`modal.specialties`),
        certificados: t(`modal.certificates`),
        delete: t(`modal.delete`),
      },
      table: {
        academicDegrees: {
          title: t(`table.academicDegrees.title`),
          columns: {
            ...t<string, Texts['table']['academicDegrees']['columns']>(
              `table.academicDegrees.columns`,
              {
                returnObjects: true,
              },
            ),
          },
          add: t(`table.academicDegrees.add`),
        },
        specialties: {
          title: t(`table.specialties.title`),
          columns: {
            ...t<string, Texts['table']['specialties']['columns']>(
              `table.specialties.columns`,
              {
                returnObjects: true,
              },
            ),
          },
          add: t(`table.specialties.add`),
        },
        certificates: {
          title: t(`table.certificates.title`),
          columns: {
            ...t<string, Texts['table']['certificates']['columns']>(
              `table.certificates.columns`,
              {
                returnObjects: true,
              },
            ),
          },
          add: t(`table.certificates.add`),
        },
      },
      form: {
        ...t<string, Texts['form']>(`form`, {
          returnObjects: true,
        }),
        linkedFileName: (name: string, required?: boolean) =>
          t(required ? 'form.linkedFileName' : 'form.linkedFileNameOpt', {
            name,
          }),
        linkedFileNameReq: (name: string) =>
          t('form.linkedFileNameReq', {
            name,
          }),
      },
      actions: {
        ...t<string, Texts['actions']>(`actions`, {
          returnObjects: true,
        }),
      },
    }),
    [t],
  );

  return (
    <TextsContext.Provider value={texts}>{children}</TextsContext.Provider>
  );
};

export const useTexts = () => {
  const context = useContext(TextsContext);
  if (!context) {
    throw new Error('useTexts must be used within a TextsProvider');
  }
  return context;
};
