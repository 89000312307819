import {
  useCallback,
  forwardRef,
  useImperativeHandle,
  Ref,
  useState,
  useMemo,
  useEffect,
} from 'react';
import {
  Modal,
  TextInput,
  Select,
  Button,
  SelectOptionType,
} from '@octano/global-ui';
import { Form, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../locales/translations';
import { QualitativeAssessmentRangeRow } from '../hooks/useQualitativeAssessmentRangeColumns';

import { useForm, FormProvider } from 'react-hook-form';
import { useValidations } from '../../../../hooks/useValidations';
import { QualitativeAssessmentRangeType } from '../interfaces/qualitative-assessment-range.interface';

const prefix = 'qualitativeAssessmentRange';

export type QualitativeAssessmentRangeModalMethods = {
  open: (ctx: ModalContext) => void;
  close: () => void;
};

type QualitativeAssessmentRangeModalProps = {
  onAddRecord?: (next: QualitativeAssessmentRangeRow) => void;
};

type ModalContext = {
  minScore: number;
  maxScore: number;
};

type FormFields = {
  name: QualitativeAssessmentRangeRow['name'];
  start: string;
  end: SelectOptionType | null;
};

const defaultValues: FormFields = {
  name: '',
  start: '0',
  end: null,
};

const QualitativeAssessmentRangeModal = (
  { onAddRecord }: QualitativeAssessmentRangeModalProps,
  ref: Ref<QualitativeAssessmentRangeModalMethods>,
) => {
  const { t } = useTranslation(TranslationsKeys.CONFIGURATION_ACADEMIC);
  const { msgValidations } = useValidations();

  const methods = useForm<FormFields>({
    defaultValues,
  });
  const { control, reset, watch, handleSubmit, setValue } = methods;

  const [startValue] = watch(['start']);

  const [visible, setVisible] = useState<boolean>(false);
  const [context, setContext] = useState<ModalContext>({
    minScore: 0,
    maxScore: 1,
  });

  const endOptions = useMemo(() => {
    const opts: SelectOptionType[] = [];

    for (let i = Number(startValue) + 1; i <= context.maxScore; i++) {
      opts.push({ label: `${i}`, value: i });
    }
    return opts;
  }, [context, startValue]);

  const handleOpen = useCallback(
    (ctx: ModalContext) => {
      setVisible(true);
      setContext(ctx);
      reset({ ...defaultValues, start: ctx.minScore.toString() ?? '0' });
    },
    [reset],
  );

  const handleClose = useCallback(() => {
    setVisible(false);
    reset(defaultValues);
  }, [reset]);

  const handleSave = useCallback(
    (values: FormFields) => {
      const payload: QualitativeAssessmentRangeRow = {
        name: values.name,
        start: context.minScore,
        end: Number(values.end?.value ?? 0),
        startType: QualitativeAssessmentRangeType.Inclusive,
        endType: QualitativeAssessmentRangeType.Inclusive,
      };
      !!onAddRecord && onAddRecord(payload);
      handleClose();
    },
    [onAddRecord, handleClose, context],
  );

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  useEffect(() => {
    if (startValue) {
      setValue('end', null);
    }
  }, [startValue, setValue]);

  return (
    <Modal isOpen={visible} toggle={handleClose}>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleSave)}>
          <Row>
            <Col xs={12}>
              <h1 className="text-dark fs-22 text-center">
                {t(`${prefix}.modal.titleCreate`)}
              </h1>
              <p className="fs-16 mb-4 text-center">
                {t(`${prefix}.modal.descriptionCreate`)}
              </p>
            </Col>
            <Col xs={12}>
              <TextInput
                name="name"
                label={t(`${prefix}.modal.name`)}
                placeholder={t(`${prefix}.modal.namePlaceholder`)}
                control={control}
                rules={{ required: msgValidations.required }}
              />
            </Col>
            <Col xs={12} md={6}>
              <TextInput
                name="start"
                label={t(`${prefix}.modal.start`)}
                placeholder={t(`${prefix}.modal.startPlaceholder`)}
                control={control}
                disabled={true}
              />
            </Col>
            <Col xs={12} md={6}>
              <Select
                name="end"
                label={t(`${prefix}.modal.end`)}
                placeholder={t(`${prefix}.modal.endPlaceholder`)}
                options={endOptions}
                control={control}
                isClearable={false}
                rules={{ required: msgValidations.required }}
              />
            </Col>
            <Col className="mt-4" xs={12}>
              <Button
                text={t(`common:actions.add`)}
                type="submit"
                size="md"
                className="w-100"
              />
              <Button
                text={t(`common:actions.close`)}
                type="button"
                size="md"
                className="w-100 mt-2"
                outlined
                onClick={handleClose}
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default forwardRef(QualitativeAssessmentRangeModal);
