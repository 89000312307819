import TeacherCreate from './TeacherCreate';
import IdentificationTypeLoader from './loaders/IdentificationTypeLoader';

export default function TeacherCreateContainer() {
  return (
    <IdentificationTypeLoader>
      <TeacherCreate />
    </IdentificationTypeLoader>
  );
}
