import { Button, addToast } from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Card, Col, Form, Row } from 'reactstrap';

import GoBackToListButton from '../../../components/buttons/GoBackToListButton';
import { SectionTitle } from '../../../components/text';
import { PathsLayouts } from '../../../config/routes';
import { isNumber } from '../../../utils/math';
import BaseLoader from '../parts/BaseLoader';
import StudentBox from '../parts/StudentBox';
import TextBox from '../parts/TextBox';
import { putChangePetitionStatus } from '../services/requests';
import StudentPetitionForm from './parts/StudentPetitionForm';
import SummaryPetition, {
  SummaryPetitionProps,
} from './parts/StudentPetitionSummary';
import { FormStudentsPetitionsUpdateData } from './types/petitionDetail';

const prefix = 'studentPetitionDetail.received';
export default function StudentsPetitionsReceivedDetail() {
  const { t } = useTranslation();
  const history = useHistory();
  const texts = useMemo(
    () => ({
      box: {
        student: t(`${prefix}.box.student`),
        studentId: t(`${prefix}.box.studentId`),
        email: t(`${prefix}.box.email`),
        phone: t(`${prefix}.box.phone`),
      },
      resumeTitle: t(`${prefix}.resume.title`),
      resumeSubtitle: t(`${prefix}.resume.subtitle`),
      resume: {
        ...t<string, SummaryPetitionProps['texts']>(`${prefix}.resume`, {
          returnObjects: true,
        }),
      },
      bodyTitle: t(`${prefix}.body.title`),
      body: {
        currentStatusLabel: t(`${prefix}.body.currentStatusLabel`),
        lastUpdateLabel: t(`${prefix}.body.lastUpdateLabel`),
        complementaryFileLabel: t(`${prefix}.body.complementaryFileLabel`),
        petitionStatusLabel: t(`${prefix}.body.petitionStatusLabel`),
        petitionCommentLabel: t(`${prefix}.body.petitionCommentLabel`),
        petitionCommentPlaceHolder: t(
          `${prefix}.body.petitionCommentPlaceHolder`,
        ),
        msgValidations: {
          required: t(`common.validations.required`),
        },
      },
      download: {
        label: t(`${prefix}.body.complementaryFileLabel`),
        button: t(`${prefix}.downloads.downloadAction`),
        failed: t(`${prefix}.downloads.downloadFailed`),
        success: t(`${prefix}.downloads.downloadSuccess`),
      },
      upload: {
        label: t(`${prefix}.body.complementaryFileOptionalLabel`),
        button: t(`${prefix}.upload.uploadAction`),
        failed: t(`${prefix}.upload.uploadFailed`),
        success: t(`${prefix}.upload.uploadSuccess`),
      },
    }),
    [t],
  );

  const onlyView = false;
  const { petitionId: petitionIdStr } = useParams<{ petitionId: string }>();

  const petitionId = useMemo(() => {
    if (isNumber(petitionIdStr)) {
      return Number(petitionIdStr);
    }
    return null;
  }, [petitionIdStr]);

  const goBack = useCallback(() => {
    history.push(`/student-petitions/received`);
  }, [history]);

  const methods = useForm<FormStudentsPetitionsUpdateData>({
    mode: 'onSubmit',
    defaultValues: {
      description: null,
      status: { value: 'recibida', label: 'Recibida' },
      docResponse: undefined,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = useCallback(
    async (formValues) => {
      const requestBody = {
        ...formValues,
        status: formValues?.status?.value,
      };
      const response = await putChangePetitionStatus(
        petitionIdStr,
        requestBody,
      );
      if (!response.error) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.createSuccess`),
        });
        goBack();
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t('common.errors.save'),
        });
        return response.error.code;
      }
    },
    [petitionIdStr, goBack, t],
  );

  if (!petitionId) {
    return <Redirect to={PathsLayouts.studentPetitions} />;
  }

  return (
    <Card className="p-4 mt-2 mx-3">
      <BaseLoader petitionId={petitionId}>
        {(data) => (
          <>
            <section className="petition-info mb-4">
              <Row>
                <Col xs={12} sm={3}>
                  <GoBackToListButton path={PathsLayouts.studentPetitions} />
                </Col>
                <Col xs={12} sm={9}>
                  <StudentBox
                    studentName={data.student.fullName}
                    studentRut={data.student.run}
                    studentMail={data.email}
                    studentPhone={data.phone}
                    texts={texts.box}
                  />
                </Col>
              </Row>
              <br className="mt-4" />
            </section>

            <section className="petition-summary mb-4">
              <Row>
                <Col xs={12} sm={12}>
                  <div className="mb-3">
                    <SectionTitle text={texts.resumeTitle} />
                  </div>
                  <div className="mb-3">
                    <p>{texts.resumeSubtitle}</p>
                  </div>
                </Col>
                <Col xs={12} sm={12}>
                  <SummaryPetition
                    texts={texts.resume}
                    {...{
                      petitionTitle: data.title,
                      petitionType: data.type.name,
                      studyPlan: data.studyPlanVersion.name,
                      petitionDate: data.createdAt,
                      evaluationDate: data.evaluationDate,
                      section: data.section,
                    }}
                  />
                </Col>
              </Row>
            </section>
            <section className="petition-form mb-4">
              <Card className="petition-form__subject mb-3">
                <Row>
                  <Col md={12} className="mb-3">
                    <SectionTitle text={texts.bodyTitle} />
                  </Col>

                  <Col md={12} className="mb-3">
                    <TextBox text={data.description} />
                  </Col>
                </Row>
              </Card>

              <FormProvider {...methods}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Card className="petition-form__subject mb-3">
                    <Row>
                      <Col md={12} className="mb-3">
                        <StudentPetitionForm
                          onlyView={onlyView}
                          texts={texts}
                          currentStatus={data.status}
                          lastUpdate={data.lastUpdate.comment}
                          downloadFile={
                            data?.pdfFile
                              ? {
                                  name: data.linkedFile.name,
                                  url: data.pdfFile.url,
                                }
                              : undefined
                          }
                        />
                      </Col>
                      <Col md={12} className="mb-3">
                        <Card className="mb-4">
                          <Row className="d-flex justify-content-end">
                            <Col md={4}>
                              <Button
                                text={t(`common.actions.confirm`)}
                                className="mt-0"
                                type="submit"
                                fullwidth={true}
                                loading={isSubmitting}
                                disabled={onlyView || isSubmitting}
                              />
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                </Form>
              </FormProvider>
            </section>
          </>
        )}
      </BaseLoader>
    </Card>
  );
}
