import { addToast, showDialogConfirm } from '@octano/global-ui';
import { useCallback, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import { TranslationsKeys } from '../../../../locales/translations';
import { CourseFormContext } from '../providers/CourseProvider';
import { CourseFormFields } from '../types';
import { courseToFormFields, submitRequiresConfirmation } from '../utils';
import CourseActivityTypeSection from './sections/CourseActivityTypeSection';
import CourseAttendanceSection from './sections/CourseAttendanceSection';
import CourseCompatibilitiesSection from './sections/CourseCompatibilitiesSection';
import CourseDetailsSection from './sections/CourseDetailsSection';
import CourseFooterSection from './sections/CourseFooterSection';
import CourseHeaderSection from './sections/CourseHeaderSection';
import CoursePrerequisitesSection from './sections/CoursePrerequisitesSection';
import CourseSyllabusSection from './sections/CourseSyllabusSection';
import CourseTypeSection from './sections/CourseTypeSection';
import LearningResultSection from './sections/LearningResultSection';

type CourseFormProps = {
  processing?: boolean;
  onSubmit: (p: {
    values: CourseFormFields;
    isDisabledToUpdate: boolean;
  }) => void;
  onBack?: () => void;
};

const CourseForm = ({ processing, onBack, onSubmit }: CourseFormProps) => {
  const { t } = useTranslation(TranslationsKeys.ACADEMIC_OFFER_COURSES_UPDATE);
  const { mode, course, formData, isDisabledToUpdate } =
    useContext(CourseFormContext);

  const methods = useForm<CourseFormFields>();
  const { reset, handleSubmit } = methods;

  const handleSave = useCallback(
    async (values: CourseFormFields) => {
      if (!values?.courseTypes?.length) {
        addToast({
          icon: 'warning',
          color: 'warning',
          text: t(`validateCourseType`),
        });
        return;
      }
      if (!values?.attendance?.length) {
        addToast({
          icon: 'warning',
          color: 'warning',
          text: t(`validateAttendance`),
        });
        return;
      }

      if (submitRequiresConfirmation(values, mode)) {
        return showDialogConfirm({
          icon: {
            name: 'warning',
            color: 'warning',
          },
          title: t(`confirmModalTitle`),
          subtitle: t(`confirmModalBody`),
          btnConfirm: {
            text: t(`confirmModalAgree`),
            onConfirm: () => {
              onSubmit({
                values,
                isDisabledToUpdate,
              });
            },
          },
          btnClose: {
            text: t(`confirmModalCancel`),
          },
        });
      } else {
        onSubmit({
          values,
          isDisabledToUpdate,
        });
      }
    },
    [t, onSubmit, isDisabledToUpdate, mode],
  );

  useEffect(() => {
    if (course && formData) {
      reset(courseToFormFields(course, formData));
    }
  }, [course, formData, reset]);

  return (
    <Form onSubmit={handleSubmit(handleSave)}>
      <FormProvider {...methods}>
        <CourseHeaderSection onBack={onBack} />
        <CourseDetailsSection />
        <CourseTypeSection />
        <CourseActivityTypeSection />
        <Row className="pt-4">
          <Col xs={12} md={4}>
            <CourseAttendanceSection />
          </Col>
          <Col xs={12} md={8}>
            <CoursePrerequisitesSection />
          </Col>
          <Col xs={12} md={4}>
            <CourseSyllabusSection />
          </Col>
          <Col xs={12} md={8}>
            <CourseCompatibilitiesSection />
          </Col>
        </Row>
        <LearningResultSection />
        <CourseFooterSection processing={processing} />
      </FormProvider>
    </Form>
  );
};

export default CourseForm;
