import { Table } from '@octano/global-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../locales/translations';
import useColumns from '../hooks/useColumns';
import { useFinantialOthersState } from '../hooks/useFinantialOthersState';

function FinantialOtherPetitions() {
  const { t } = useTranslation(
    TranslationsKeys.CONFIGURATION_FIANANTIAL_OTHERS,
  );
  const {
    petitionTypesState,
    setPetitionTypesState,
  } = useFinantialOthersState();

  const [tableKey, setTableKey] = useState(0);

  const columns = useColumns({
    translateKey: 'petitionTypes',
    onChangeValue: (typeId, paymentAmount) => {
      setPetitionTypesState((prev) =>
        prev.map((c) => (c.id === typeId ? { ...c, paymentAmount } : c)),
      );
    },
    onCheck: (typeId, paymentRequired) => {
      setTableKey(tableKey + 1);
      setPetitionTypesState((prev) =>
        prev.map((c) =>
          c.id === typeId
            ? {
                ...c,
                paymentRequired,
                paymentAmount: paymentRequired ? c.paymentAmount : undefined,
              }
            : c,
        ),
      );
    },
  });

  return (
    <div>
      <p className="fs-16 fw-600 text-primary mb-0">
        {t('petitionTypes.title')}
      </p>
      <p className="fs-14 mb-3">{t('petitionTypes.description')}</p>
      <Table key={tableKey} columns={columns} data={petitionTypesState} />
    </div>
  );
}

export default FinantialOtherPetitions;
