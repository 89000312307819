import { Box, useMobile } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../locales/translations';

import { TeacherAccountStatus } from '../../../../types/Teacher';

export interface Props {
  status: TeacherAccountStatus | 'unregistered';
  lastLogin?: string;
}

export default function AccountDetailBox({ status, lastLogin }: Props) {
  const { t } = useTranslation(
    TranslationsKeys.TEACHER_MANAGMENT_TEACHER_ACTIVATION,
  );
  const isMobile = useMobile();

  const getStatusKeyByValue = (statusValue: Props['status']) => {
    if (statusValue === 'unregistered') return 'unregistered';

    const statusEntry = Object.entries(TeacherAccountStatus).filter(
      ([, value]) => {
        return value === status;
      },
    );

    return statusEntry[0][0];
  };

  const statusKey = getStatusKeyByValue(status);

  return (
    <Box
      color="primary"
      variant="outlined"
      style={{ borderRadius: 4 }}
      className={`py-2 px-3 ${isMobile && 'mt-4 w-100'}`}
      body={
        <div className="d-flex justify-content-between">
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0 w-50 text-uppercase"
            title={t(`status`)}
            style={{ minWidth: isMobile ? 0 : 140 }}
            body={
              <p className="fs-14 mb-0 text-capitalize">
                {t(`acccountStatuses.${statusKey}`)}
              </p>
            }
          />
          {lastLogin !== undefined && (
            <Box
              color="primary"
              variant="outlined"
              className="p-0 border-0 w-50 text-uppercase"
              title={t(`lastLogin`)}
              style={{ minWidth: isMobile ? 0 : 140 }}
              body={<p className="fs-14 mb-0 text-capitalize">{lastLogin}</p>}
            />
          )}
        </div>
      }
    />
  );
}
