import {
  Select,
  SelectOptionType,
  TextInput,
  DateInput,
} from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useMemo } from 'react';
import { Col } from 'reactstrap';
import { useValidations } from '../../../../../../../hooks/useValidations';
import { useTranslation } from 'react-i18next';
import { useHiringInformationFormDataLoader } from '../Loaders/HiringInformationFormDataLoader';
import { formatToOnlyPositiveIntegers } from '../../../../../../../utils/currency';
import { TranslationsKeys } from '../../../../../../../locales/translations';

interface Props {
  hoursDataSum?: number;
  hourError?: string;
}
export interface FormData {
  campus: SelectOptionType | null;
  studyPlan: SelectOptionType | null;
  teacherContractType: SelectOptionType | null;
  teacherAcademicRank: SelectOptionType | null;
  school: SelectOptionType | null;
  deanship: SelectOptionType | null;
  academicLevel: SelectOptionType | null;
  modality: SelectOptionType | null;
  startPeriod: SelectOptionType | null;
  startAt: string | null;
  hours: {
    [key: string]: number;
  };
}

const dictPrefix = 'createModal';

const CreateOrUpdateForm = ({ hoursDataSum = 0, hourError }: Props) => {
  const { t } = useTranslation(
    TranslationsKeys.TEACHER_MANAGMENT_TEACHER_FILE_HIRING_INFORMATION,
  );
  const forms = useFormContext<FormData>();
  const { control } = forms;
  const { data } = useHiringInformationFormDataLoader();
  const { msgValidations, rulesPatternDecimal } = useValidations();

  const hourTypes = useMemo(() => data?.hourTypes ?? [], [data?.hourTypes]);

  return (
    <>
      <Col xs={12} md={6}>
        <Select
          name="teacherContractType"
          options={data?.teacherContractTypes ?? []}
          label={t(`${dictPrefix}.inputs.type.label`)}
          placeholder={t(`${dictPrefix}.inputs.type.placeholder`)}
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </Col>
      <Col xs={12} md={6}>
        <DateInput
          name="startAt"
          label={t(`${dictPrefix}.inputs.startAt.label`)}
          control={control}
          rules={{
            required: msgValidations.required,
          }}
        />
      </Col>
      <Col xs={12} md={6}>
        <Select
          name="academicLevel"
          options={data?.academicLevels ?? []}
          label={t(`${dictPrefix}.inputs.academicLevel.label`)}
          placeholder={t(`${dictPrefix}.inputs.academicLevel.placeholder`)}
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </Col>
      <Col xs={12} md={6}>
        <Select
          name="school"
          options={data?.schools ?? []}
          label={t(`${dictPrefix}.inputs.school.label`)}
          placeholder={t(`${dictPrefix}.inputs.school.placeholder`)}
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </Col>
      <Col xs={12} md={6}>
        <Select
          name="deanship"
          options={data?.deanships ?? []}
          label={t(`${dictPrefix}.inputs.deanship.label`)}
          placeholder={t(`${dictPrefix}.inputs.deanship.placeholder`)}
          control={control}
          isClearable={true}
        />
      </Col>
      <Col xs={12} md={6}>
        <Select
          name="campus"
          options={data?.campuses ?? []}
          label={t(`${dictPrefix}.inputs.campus.label`)}
          placeholder={t(`${dictPrefix}.inputs.campus.placeholder`)}
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </Col>
      <Col xs={12} md={4}>
        <Select
          name="modality"
          options={data?.modalities ?? []}
          label={t(`${dictPrefix}.inputs.modality.label`)}
          placeholder={t(`${dictPrefix}.inputs.modality.placeholder`)}
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </Col>
      <Col xs={12} md={8}>
        <Select
          name="studyPlan"
          options={data?.studyPlans ?? []}
          label={t(`${dictPrefix}.inputs.studyPlan.label`)}
          placeholder={t(`${dictPrefix}.inputs.studyPlan.placeholder`)}
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </Col>
      <Col xs={12} md={6}>
        <Select
          name="teacherAcademicRank"
          options={data?.teacherAcademicRanks ?? []}
          label={t(`${dictPrefix}.inputs.teacherAcademicRank.label`)}
          placeholder={t(
            `${dictPrefix}.inputs.teacherAcademicRank.placeholder`,
          )}
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </Col>

      <Col xs={12} md={6}>
        <Select
          name="startPeriod"
          options={data?.periods ?? []}
          label={t(`${dictPrefix}.inputs.startPeriod.label`)}
          placeholder={t(`${dictPrefix}.inputs.startPeriod.placeholder`)}
          control={control}
          isClearable={true}
        />
      </Col>

      <Col xs={12}>
        <span className="w-100 text-dark fs-14 mb-2 mt-4 d-block">
          {t(`${dictPrefix}.hoursSection`)}
        </span>
      </Col>

      <Col xs={12} md={4}>
        <TextInput
          name="hours.total"
          value={`${hoursDataSum}`}
          defaultValue={0}
          label={t(`${dictPrefix}.hourTypes.total`)}
          readOnly
          placeholder="0"
          errorText={hourError}
        />
      </Col>

      {hourTypes.map((item) => (
        <Col xs={12} md={item.inputSize} key={item.id}>
          <TextInput
            name={`hours.${item.id}`}
            control={control}
            label={item.name}
            placeholder="0"
            rules={{
              required: msgValidations.required,
              pattern: rulesPatternDecimal,
            }}
            formatter={formatToOnlyPositiveIntegers}
          />
        </Col>
      ))}
    </>
  );
};

export default CreateOrUpdateForm;
