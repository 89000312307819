import { useContext, useCallback, useMemo } from 'react';
import { Row, Col, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Table } from '@octano/global-ui';

import { CourseFormContext } from '../../providers/CourseProvider';
import { SectionTitle } from '../../../../../components/text';
import { CourseFormFields, CourseFormMode } from '../../types';
import { TranslationsKeys } from '../../../../../locales/translations';

const CourseAttendanceSection = () => {
  const { t } = useTranslation(TranslationsKeys.ACADEMIC_OFFER_COURSES_UPDATE);

  const { watch, setValue } = useFormContext<CourseFormFields>();

  const { mode, formData } = useContext(CourseFormContext);
  const { activities = [] } = formData;

  const [attendance] = watch(['attendance']);

  const validActivityTypes = useMemo(
    () =>
      activities?.filter((e) =>
        attendance?.map((a) => a.activityTypeId).includes(+e.value),
      ) ?? [],
    [activities, attendance],
  );

  const onChangeAttendance = useCallback(
    (activityTypeId: number, value: string) => {
      if (isNaN(+value)) {
        return;
      }
      if (+value < 0 || +value > 100) {
        return;
      }
      const nextAttendance = [...attendance];
      const index = nextAttendance.findIndex(
        (att) => att.activityTypeId === activityTypeId,
      );
      if (index >= 0) {
        nextAttendance[index].minPercentage = +value;
      }
      setValue('attendance', nextAttendance);
    },
    [attendance, setValue],
  );

  if (!activities?.length) {
    return <></>;
  }

  return (
    <Row>
      <Col xs={12} className="pt-4">
        <SectionTitle text={t(`assistanceRequired`)} />
      </Col>
      {mode !== CourseFormMode.WATCH && (
        <Col xs={12}>
          <p className="fs-16 fw-300">{t(`assistanceRequiredSubtitle`)}</p>
        </Col>
      )}
      <Col xs={12}>
        <Table
          columns={[
            {
              columnName: 'label',
              width: '50%',
              thClassName: 'text-center',
              tdClassName: 'text-center',
              headerText: t(`activityType`),
            },
            {
              columnName: 'id',
              width: '50%',
              thClassName: 'text-center',
              tdClassName: 'text-center',
              headerText: t(`obligatoryAttendance`),
              cellFormat: ({ row }) => (
                <div
                  className="d-flex m-auto align-items-center"
                  style={{ maxWidth: '65px' }}
                >
                  <Input
                    name={`attendance_${row.label}`}
                    type="text"
                    onChange={(e) => {
                      onChangeAttendance(+row.value, e.target.value);
                    }}
                    value={
                      attendance.find((att) => att.activityTypeId === row.value)
                        ?.minPercentage ?? 0
                    }
                    disabled={mode === CourseFormMode.WATCH}
                  />
                  <span className="pl-2">%</span>
                </div>
              ),
            },
          ]}
          data={validActivityTypes}
        />
      </Col>
    </Row>
  );
};

export default CourseAttendanceSection;
