import { ColumnTable } from '@octano/global-ui';
import { useMemo } from 'react';
import { Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { CertificateType, PetitionType, PetitionTypeId } from '../type';
import { TranslationsKeys } from '../../../../locales/translations';
function useColumns({
  onCheck,
  onChangeValue,
  translateKey,
}: {
  onCheck: (typeId: number | PetitionTypeId, value: boolean) => void;
  onChangeValue: (typeId: number | PetitionTypeId, value: number) => void;
  translateKey: 'petitionTypes' | 'certificateTypes';
}) {
  const { t } = useTranslation(
    TranslationsKeys.CONFIGURATION_FIANANTIAL_OTHERS,
  );
  return useMemo<ColumnTable<PetitionType | CertificateType>[]>(
    () => [
      {
        columnName: 'paymentRequired',
        headerText: t(`${translateKey}.table.paymentRequirement`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '10%',
        cellFormat: (options) => {
          return (
            <>
              <div className="form-group">
                <label className="g-checkbox-input-container">
                  <Input
                    className="g-checkbox-input"
                    type="checkbox"
                    defaultChecked={Boolean(options.row.paymentRequired)}
                    onChange={() => {
                      onCheck(
                        options.row.id,
                        !Boolean(options.row.paymentRequired),
                      );
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
                <small className="g-input-error form-text text-danger"></small>
              </div>
            </>
          );
        },
      },
      {
        columnName: 'name',
        headerText: t(`${translateKey}.table.name`),
        tdClassName: 'text-left',
        thClassName: 'text-left',
      },
      {
        columnName: 'paymentAmount',
        headerText: t(`${translateKey}.table.paymentAmount`),
        tdClassName: 'text-left',
        thClassName: 'text-left',
        width: '10%',
        cellFormat: (options) => {
          return (
            <div className="form-group">
              <Input
                className="border-primary"
                type="number"
                disabled={!Boolean(options.row.paymentRequired)}
                defaultValue={options.value}
                onChange={(e) => {
                  onChangeValue(options.row.id, e.target.valueAsNumber);
                }}
              />
            </div>
          );
        },
      },
    ],
    [onCheck, onChangeValue, t, translateKey],
  );
}

export default useColumns;
