import { ColumnTable, Table, TablePagination } from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import DisplayError from '../../components/info/DisplayError';
import SearchRut from '../../components/inputs/SearchRutValue';
import { ModalConfirmation } from '../../components/modals/Confirmation';
import TableEmptyContent from '../../components/text/TableEmptyContent';
import { generateUrlToTuitionProcess } from '../../views/AdmissionAndEnrollments/OnSiteEnrollment/TuitionProcessSearchStudent/helper/generateUrlToTuitionProcess';
import { IdNotFound } from '../components/IdNotFound';
import { useAdmissionStatusList } from '../contexts/AdmissionStatusListContext';
import { AdmissionManagementFindItem } from '../types';
import { ModalsValidateRetract } from './parts/ModalsValidateRetract';
import { useColumnsAdmissionListEnrolled } from './parts/useColumnsAdmissionListEnrolled';

export interface AdmissionsStudentEnrolled {
  id: number;
  rut: string;
  names: string;
  lastnames: string;
  phone: string;
  email: string;
  dateEnrolled: string;
}

export interface TableColumns {
  width?: string;
  bold?: boolean;
  headerText: string;
  columnName: string;

  [key: string]: any;
}

const ITEMS_PER_PAGE_DEFAULT = 10;

const prefix = 'admission';

export default function AdmissionsListEnrolled(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  const { loading, data, error, query, updateQuery } = useAdmissionStatusList();
  const currentPage = query.page;
  const totalItems = data.total;
  const totalPages = data.total_pages;
  const searching = loading;
  const showPagination = data.total_pages > 1;

  const {
    columns,
    changeStudyPlan,
    setChangeStudyPlan,
    studentName,
    selectRetractedID,
    setSelectRetractedID,
    showRetractedModal,
    setShowRetractedModal,
  } = useColumnsAdmissionListEnrolled();

  const getColumnResponsive = useCallback((): ColumnTable<AdmissionManagementFindItem>[] => {
    let columnsRemove = ['date', 'email', 'phone', 'lastnames'];
    let limit = Math.round(window.innerWidth / 160);
    let nColumnsRemove = columns.length > limit ? columns.length - limit : 0;
    return columns.filter((column) => {
      if (
        columnsRemove.find((el) => el === column.columnName) &&
        nColumnsRemove !== 0
      ) {
        nColumnsRemove--;
        return false;
      } else return true;
    });
  }, [columns]);

  const [filterColumnsResponsive, setFilterColumnsResponsive] = useState<
    ColumnTable<AdmissionManagementFindItem>[]
  >([]);

  useEffect(() => {
    window.onresize = () => {
      let array = getColumnResponsive();
      setFilterColumnsResponsive([...array]);
    };
  }, [getColumnResponsive]);

  useEffect(() => {
    let array = getColumnResponsive();
    setFilterColumnsResponsive([...array]);
  }, [getColumnResponsive]);

  const goToChangeStudyPlan = () => {
    // Continua con el proceso de cambio de plan de estudio
    if (changeStudyPlan?.activeProcess) {
      const url = generateUrlToTuitionProcess({
        studentPostulantId: null,
        studentDetailId: changeStudyPlan?.activeProcess,
        studentAdmissionTypeId: 'SIN_SUA',
        fromStart: false,
      });
      history.push(url);
    } else {
      // Se redirecciona para comenzar un proceso de cambio de plan de estudio
      const url = generateUrlToTuitionProcess({
        studentPostulantId: null,
        studentDetailId: changeStudyPlan?.detailId,
        studentAdmissionTypeId: 'SIN_SUA',
        fromStart: true,
      });
      history.push(url);
    }
  };

  const loadingOrEmpty = useMemo(() => {
    if (query.search) {
      return <IdNotFound />;
    } else {
      return (
        <TableEmptyContent
          title={t(`${prefix}.studentEnrolled.messageNotEnrolled`)}
          subtitle={''}
        />
      );
    }
  }, [query, t]);

  if (error) {
    return (
      <DisplayError
        insideCard
        textBody={error}
        retryAction={() => updateQuery()}
        loadingAction={loading}
      />
    );
  }
  return (
    <div className="mt-3 text-info">
      <SearchRut
        value={query.search}
        onChange={(search) => updateQuery({ page: 1, search })}
        searching={searching}
      />
      <Table
        columns={filterColumnsResponsive}
        data={data.data}
        noResultsText={loadingOrEmpty}
        isLoadingResults={loading}
      />
      {showPagination && (
        <TablePagination
          pagination={{
            currentPage,
            itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
            onChangePage: (page) => {
              updateQuery({ page, search: query.search });
            },
            totalItems,
            totalPages,
          }}
        />
      )}
      <ModalsValidateRetract
        updateQuery={updateQuery}
        studentName={studentName}
        selectRetractedID={selectRetractedID}
        setSelectRetractedID={setSelectRetractedID}
        showRetractedModal={showRetractedModal}
        setRetractedModal={setShowRetractedModal}
      />
      <ModalConfirmation
        open={changeStudyPlan !== undefined}
        title={
          changeStudyPlan?.activeProcess
            ? t(`tuitionProcess.form.modalContinueChange.title`)
            : t(`tuitionProcess.form.modalConfirmChange.title`)
        }
        subtitle={
          changeStudyPlan?.activeProcess
            ? t(`tuitionProcess.form.modalContinueChange.subtitle`)
            : t(`tuitionProcess.form.modalConfirmChange.subtitle`)
        }
        onClose={() => setChangeStudyPlan(undefined)}
        onConfirm={goToChangeStudyPlan}
      />
    </div>
  );
}
