import { addToast, Table } from '@octano/global-ui';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../locales/translations';
import useActions from '../hooks/useActions';
import useColumns from '../hooks/useColumns';
import { DiscountManagementListResponse } from '../types';
import TuitionContinuityDiscountConfirmUpdateModal, {
  TuitionContinuityDiscountConfirmUpdateModalRef,
} from './modals/TuitionContinuityDiscountConfirmUpdateModal';
import TuitionContinuityDiscountDeleteModal, {
  TuitionContinuityDiscountDeleteModalRef,
} from './modals/TuitionContinuityDiscountDeleteModal';
import TuitionContinuityDiscountUpdateModal, {
  TuitionContinuityDiscountUpdateModalRef,
} from './modals/TuitionContinuityDiscountUpdateModal';
import { useTuitionContinuityDiscountManagementLoader } from './TuitionContinuityDiscountManagementLoader';

function DiscountManagementTable() {
  const { t } = useTranslation(TranslationsKeys.TC_DISCOUNT_MANAGEMENT);
  const {
    data,
    refresh,
    updateQuery,
    query,
  } = useTuitionContinuityDiscountManagementLoader();

  const updateModalRef = useRef<TuitionContinuityDiscountUpdateModalRef>(null);
  const deleteModalRef = useRef<TuitionContinuityDiscountDeleteModalRef>(null);
  const confirmUpdateModalRef = useRef<TuitionContinuityDiscountConfirmUpdateModalRef>(
    null,
  );

  const onClickUpdate = (row: DiscountManagementListResponse) => {
    updateModalRef.current?.openModal(row);
  };
  const onClickDelete = (tuitionContinuityDiscountId: number) => {
    deleteModalRef.current?.openModal(tuitionContinuityDiscountId);
  };

  //Acciones y respuesta(eliminar-actualizar)
  const actions = useActions({
    onSuccess: (action) => {
      addToast({
        icon: 'success',
        color: 'success',
        text:
          action === 'update'
            ? t('actions.update.success')
            : t('actions.delete.success'),
      });
    },
    onError: (action) => {
      addToast({
        icon: 'error',
        color: 'danger',
        text:
          action === 'update'
            ? t('actions.update.error')
            : t('actions.delete.error'),
      });
    },
  });

  const columns = useColumns({ onClickUpdate, onClickDelete });

  return (
    <>
      <Table
        data={data?.data ?? []}
        columns={columns}
        pagination={{
          totalItems: data?.total ?? 0,
          onChangePage: (page) => updateQuery({ ...query, page }),
          itemsPerPage: 10,
          totalPages: data?.total_pages ?? 0,
          currentPage: query.page,
        }}
      />
      <TuitionContinuityDiscountUpdateModal
        ref={updateModalRef}
        onConfirm={(row) => {
          confirmUpdateModalRef.current?.openModal(row);
        }}
      />
      <TuitionContinuityDiscountConfirmUpdateModal
        ref={confirmUpdateModalRef}
        onConfirm={async (row) => {
          await actions.updateRecord(row);
          refresh();
        }}
        onDecline={() => {}}
      />
      <TuitionContinuityDiscountDeleteModal
        ref={deleteModalRef}
        onConfirm={async (id) => {
          await actions.deleteRecord(id);
          refresh();
        }}
        onDecline={() => {}}
      />
    </>
  );
}

export default DiscountManagementTable;
