import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { AxiosResultDefaultError } from '../../api/request';
import { StudyPlanOffertResponse } from '../../api/requests/studyPlansOffer';
import { getAdmissionStatusList } from '../../views/AdmissionAndEnrollments/AdmissionManagement/api';
import { AdmissionManagementFindItem } from '../types';

export type AdmissionStatusListContextErrorCode = 'CONNECTION' | 'HTTP_ERROR';

type AdmissionStatusList = string;

export interface AdmissionStatusListContextState {
  readonly loading: boolean;
  readonly data: StudyPlanOffertResponse<AdmissionManagementFindItem>;
  readonly error?: AxiosResultDefaultError['code'];
  readonly query: { page: number; search?: string };
  readonly updateQuery: (query?: { page: number; search?: string }) => void;
}

const contextDefaultValues: AdmissionStatusListContextState = {
  loading: true,
  data: { data: [], total: 0, total_pages: 0 },
  error: undefined,
  query: { page: 1 },
  updateQuery: (_query) => {
    throw new Error('Update Query No implementado');
  },
};

export const AdmissionStatusListContext = createContext<AdmissionStatusListContextState>(
  contextDefaultValues,
);

export function useAdmissionStatusList() {
  return useContext(AdmissionStatusListContext);
}

const AdmissionStatusListProvider: FC<{
  status: AdmissionStatusList;
  fetchImmediately?: boolean;
  academicOfferStudyPlanVersionOffersId: number;
}> = ({
  status,
  fetchImmediately = false,
  children,
  academicOfferStudyPlanVersionOffersId,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<
    StudyPlanOffertResponse<AdmissionManagementFindItem>
  >({
    data: [],
    total: 0,
    total_pages: 0,
  });
  const [error, setError] = useState<
    AdmissionStatusListContextErrorCode | undefined
  >(undefined);
  const [query, setQuery] = useState<{ page: number; search?: string }>({
    page: 1,
  });
  const [initilized, setInitialized] = useState(false);

  const fetchData = useCallback(
    async ({ page, search }: { page: number; search?: string }) => {
      setLoading(true);
      const result = await getAdmissionStatusList(
        academicOfferStudyPlanVersionOffersId,
        { status, search },
        { page },
      );
      if (result.error) {
        setError(result.error.code);
      } else {
        setError(undefined);
        setData(result.data);
      }
      setLoading(false);
    },
    [academicOfferStudyPlanVersionOffersId, status],
  );

  useEffect(() => {
    if (fetchImmediately || initilized) {
      fetchData(query);
    }
    setInitialized(true);
    // Solo se hace fetchData si cambia fetchData (el id o el status) o la query
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, query]);

  const updateQuery = useCallback((q?: { page: number; search?: string }) => {
    setQuery(q ?? { page: 1 });
  }, []);

  return (
    <AdmissionStatusListContext.Provider
      value={{ loading, data, error: error, query, updateQuery }}
    >
      {children}
    </AdmissionStatusListContext.Provider>
  );
};

export default AdmissionStatusListProvider;
