import { Col } from 'reactstrap';
import { useCallback } from 'react';

import { EducationRecordFile } from '../../type';
import CreateOrUpdateModalFormFile, {
  MapedRequiredFile,
} from './CreateOrUpdateModalFormFile';

interface Props {
  openIteration: number;
  requiredFiles?: MapedRequiredFile[];
  uploadedFiles?: EducationRecordFile<File>[];
  appendUploadedFile?: (record: EducationRecordFile<File>) => void;
  removeUploadedFile?: (index: number) => void;
}

const CreateOrUpdateModalFormFiles = ({
  openIteration = 0,
  uploadedFiles = [],
  requiredFiles = [],
  appendUploadedFile,
  removeUploadedFile,
}: Props) => {
  const handleChangeFile = useCallback(
    (requiredFile: MapedRequiredFile, linkedFile?: File) => {
      const existsIndex = uploadedFiles.findIndex(
        (e) => e.code === requiredFile.code,
      );

      if (existsIndex >= 0) {
        !!removeUploadedFile && removeUploadedFile(existsIndex);
      }

      if (linkedFile) {
        !!appendUploadedFile &&
          appendUploadedFile({
            code: requiredFile.code,
            linkedFile,
          });
      }
    },
    [uploadedFiles, appendUploadedFile, removeUploadedFile],
  );

  return (
    <>
      <Col md={12} xs={12} className="pt-3">
        {requiredFiles.map((itemFile, i) => (
          <CreateOrUpdateModalFormFile
            key={i.toString()}
            data={itemFile}
            openIteration={openIteration}
            uploadedFiles={uploadedFiles}
            onChange={handleChangeFile}
          />
        ))}
      </Col>
    </>
  );
};

export default CreateOrUpdateModalFormFiles;
