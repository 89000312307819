export enum QualitativeAssessmentRangeType {
  Inclusive = 'inclusive',
  Exclusive = 'exclusive',
}

export interface QualitativeAssessmentRange {
  id: number;
  name: string;
  start: number;
  end: number;
  startType: QualitativeAssessmentRangeType;
  endType: QualitativeAssessmentRangeType;
}

export interface QualitativeAssessmentRangeResponse {
  records: QualitativeAssessmentRange[];
  sectionEnabled: boolean;
  minScore: number;
  maxScore: number;
}
