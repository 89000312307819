import request from '../../api/request';
import { PaginationRequestType } from '../../types/paginationRequestType';
import {
  DiscountManagementFilter,
  DiscountManagementListResponse,
  TuitionContinuityDiscountFormDataResponse,
} from './types';

export const DISCOUNT_ALLOCATIONS_MANAGEMENT = {
  FORM_DATA: '/maintainers-portal/tuition-continuity/discount-management/form',
  LIST: '/maintainers-portal/tuition-continuity/discount-management/discounts',
  DELETE: (tuitionContinuityDiscountId: number) =>
    `/maintainers-portal/tuition-continuity/discount-management/discounts/${tuitionContinuityDiscountId}`,
  UPDATE: (tuitionContinuityDiscountId: number) =>
    `/maintainers-portal/tuition-continuity/discount-management/discounts/${tuitionContinuityDiscountId}`,
};

export function getDiscountAllocationsManagementFormData() {
  return request<TuitionContinuityDiscountFormDataResponse>(
    DISCOUNT_ALLOCATIONS_MANAGEMENT.FORM_DATA,
  );
}

export function getDiscountAllocationsManagement(
  params: DiscountManagementFilter,
) {
  return request<PaginationRequestType<DiscountManagementListResponse>>(
    DISCOUNT_ALLOCATIONS_MANAGEMENT.LIST,
    { params: { ...params, items_per_page: params.items } },
  );
}

export function updateDiscount(params: any) {
  return request(DISCOUNT_ALLOCATIONS_MANAGEMENT.UPDATE(params.id), {
    method: 'PATCH',
    data: params,
  });
}

export function deleteDiscount(tuitionContinuityDiscountId: number) {
  return request(
    DISCOUNT_ALLOCATIONS_MANAGEMENT.DELETE(tuitionContinuityDiscountId),
    {
      method: 'delete',
    },
  );
}
