import { addToast, Button, Table } from '@octano/global-ui';
import { useRef, useCallback } from 'react';

import { SectionTitle } from '../../../../../../components/text';
import HeaderInfo from '../../parts/Header/HeaderInfo';
import { useTexts } from '../hooks/textsContext';
import useActions from '../hooks/useActions';
import useColumnActions from '../hooks/useColumnActions';
import useColumnsCertificates from '../hooks/useColumnsCertificates';
import useColumnsOthers from '../hooks/useColumnsOthers';
import { EducationalBackgroundType, EducationRecord } from '../type';
import { useEducationalBackgroundFormDataLoader } from './Loaders/EducationalBackgroundFormDataLoader';
import { useEducationalBackgroundLoader } from './Loaders/EducationalBackgroundLoader';
import ConfirmModal, { ConfirmModalRef } from './Modals/ConfirmModal';
import CreateOrUpdateModal, {
  CreateOrUpdateRef,
} from './Modals/CreateOrUpdateModal';

function EducationalBackgroundTable() {
  const texts = useTexts();

  const { data, loading, refresh } = useEducationalBackgroundLoader();
  const { data: dataForm } = useEducationalBackgroundFormDataLoader();

  const actions = useActions({
    onSuccess: refresh,
    onError: (_, messsage) => {
      addToast({
        icon: 'error',
        color: 'danger',
        text: messsage ?? 'Ha ocurrido un error',
      });
    },
  });

  //Mandamos data por referencia al modal
  const ConfirmModalRef = useRef<ConfirmModalRef | undefined>();
  const createOrUpdateModalRef = useRef<CreateOrUpdateRef | undefined>();

  const onClickCreate = useCallback((type: EducationalBackgroundType) => {
    createOrUpdateModalRef.current?.openModal(type);
  }, []);

  const onClickEdit = useCallback((row: EducationRecord) => {
    createOrUpdateModalRef.current?.openModal(row.type as any, row);
  }, []);

  const onClickRemove = useCallback((row: EducationRecord) => {
    ConfirmModalRef.current?.openModal(row);
  }, []);

  //desde la tabla solo se permite editar o eliminar una seccion
  const columns = useColumnActions({
    onClickEdit,
    onClickRemove,
  });

  const columnsOthers = useColumnsOthers(columns, dataForm?.defaultCountry);
  const columnsCertificates = useColumnsCertificates(columns);

  return (
    <>
      <header className="mb-3">
        <HeaderInfo />
        <div className="mt-3">
          <SectionTitle text={texts.title} />
          <p className="fs-16 text-light">{texts.subtitle}</p>
        </div>
      </header>

      <div className="mb-5">
        <div className="d-flex flex-wrap w-100 align-items-center justify-content-between">
          <h4 className={'fs-18 text-uppercase text-primary fw-700'}>
            {texts.table.academicDegrees.title}
          </h4>
          <Button
            outlined
            text={texts.table.academicDegrees.add}
            color="primary"
            icon="plus"
            size="md"
            className="mb-3"
            style={{ minWidth: '254px' }}
            onClick={() => {
              onClickCreate(EducationalBackgroundType.AcademicDegree);
            }}
          />
        </div>
        <Table
          columns={columnsOthers}
          data={data?.academicDegrees ?? []}
          isLoadingResults={loading}
          loadingView={{
            title: 'Cargando titulos y grados...',
            subtitle: 'Espere un momento',
          }}
        />
      </div>

      <div className="mb-5">
        <div className="d-flex flex-wrap w-100 align-items-center justify-content-between">
          <h4 className={'fs-18 text-uppercase text-primary fw-700'}>
            {texts.table.specialties.title}
          </h4>
          <Button
            outlined
            text={texts.table.specialties.add}
            color="primary"
            icon="plus"
            size="md"
            className="mb-3"
            style={{ minWidth: '254px' }}
            onClick={() => {
              onClickCreate(EducationalBackgroundType.Specialty);
            }}
          />
        </div>
        <Table
          columns={columnsOthers}
          data={data?.specialties ?? []}
          isLoadingResults={loading}
          loadingView={{
            title: 'Cargando especialidades...',
            subtitle: 'Espere un momento',
          }}
        />
      </div>

      <div className="mb-5">
        <div className="d-flex flex-wrap w-100 align-items-center justify-content-between">
          <h4 className={'fs-18 text-uppercase text-primary fw-700'}>
            {texts.table.certificates.title}
          </h4>
          <Button
            outlined
            text={texts.table.certificates.add}
            color="primary"
            icon="plus"
            size="md"
            className="mb-3"
            style={{ minWidth: '254px' }}
            onClick={() => {
              onClickCreate(EducationalBackgroundType.Certificate);
            }}
          />
        </div>
        <Table
          columns={columnsCertificates}
          data={data?.certificates ?? []}
          isLoadingResults={loading}
          loadingView={{
            title: 'Cargando certificados...',
            subtitle: 'Espere un momento',
          }}
        />{' '}
      </div>

      <CreateOrUpdateModal
        ref={createOrUpdateModalRef as React.Ref<CreateOrUpdateRef>}
        actions={actions}
      />
      <ConfirmModal
        onDecline={() => ConfirmModalRef.current?.closeModal()}
        onConfirm={(row) => {
          actions.deleteTitle(row.id);
        }}
        ref={ConfirmModalRef as React.Ref<ConfirmModalRef>}
      />
    </>
  );
}

export default EducationalBackgroundTable;
