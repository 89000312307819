import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../locales/translations';

interface Texts {
  title: string;
  subtitle: string;
  table: {
    gradingStructures: {
      title: string;
      columns: {
        name: string;
        structureType: string;
        updatedAt: string;
        actions: string;
      };
      add: string;
    };
  };
  box: {
    academicLevel: string;
  };
  form: {
    title: string;
    subtitle: string;
    structureTypeId: string;
    msg: {
      msgInfoFixed: string;
      msgInfoSemiFixed: string;
    };
  };
  info: {
    free: {
      title: string;
      body: string;
    };
  };
  structures: {
    'free': string;
    'fixed': string;
    'semi-fixed': string;
  };
  actions: {
    add: string;
    save: string;
    cancel: string;
    confirm: string;
    download: string;
    upload: string;
  };
}

interface TextsProviderProps {
  children: ReactNode;
}

const TextsContext = createContext<Texts | undefined>(undefined);

export const TextsProvider = ({ children }: TextsProviderProps) => {
  const { t } = useTranslation(
    TranslationsKeys.CONFIGURATION_GRADING_STRUCTURE,
  );

  const texts = useMemo(
    () => ({
      title: t(`title`),
      subtitle: t(`subtitle`),
      table: {
        gradingStructures: {
          title: t(`table.gradingStructures.title`),
          columns: {
            ...t<string, Texts['table']['gradingStructures']['columns']>(
              `table.gradingStructures.columns`,
              {
                returnObjects: true,
              },
            ),
          },
          add: t(`table.gradingStructures.add`),
        },
      },
      box: {
        academicLevel: t(`box.academicLevel`),
      },
      form: {
        ...t<string, Texts['form']>(`form`, {
          returnObjects: true,
        }),
      },
      structures: {
        'free': t(`structures.free`),
        'fixed': t(`structures.fixed`),
        'semi-fixed': t(`structures.semi-fixed`),
      },
      info: {
        free: {
          ...t<string, Texts['info']['free']>(`info.free`, {
            returnObjects: true,
          }),
        },
      },
      actions: {
        ...t<string, Texts['actions']>(`actions`, {
          returnObjects: true,
        }),
      },
    }),
    [t],
  );

  return (
    <TextsContext.Provider value={texts}>{children}</TextsContext.Provider>
  );
};

export const useTexts = () => {
  const context = useContext(TextsContext);
  if (!context) {
    throw new Error('useTexts must be used within a TextsProvider');
  }
  return context;
};
