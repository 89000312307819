import { useCallback, useMemo } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { PathsLayouts } from '../../config/routes';
import { PetitionStatus } from '../../types/studentPetitions';
import { isNumber } from '../../utils/math';
import BaseLoader from './parts/BaseLoader';
import StudentsPetitionsReceivedDetail from './Received/Received';
import { StudentsPetitionsDetail as StudentsPetitionsDetailType } from './Received/types/petitionDetail';
import StudentsPetitionsResolvedDetail from './Resolved/Resolved';

export default function StudentsPetitionsDetail() {
  const { petitionId: petitionIdStr } = useParams<{ petitionId: string }>();
  const petitionId = useMemo(() => {
    if (isNumber(petitionIdStr)) {
      return Number(petitionIdStr);
    }
    return null;
  }, [petitionIdStr]);

  const isResolved = useCallback((data: StudentsPetitionsDetailType) => {
    return [
      PetitionStatus.Resolved,
      PetitionStatus.Rejected,
      PetitionStatus.Approved,
    ].includes(data.status as PetitionStatus);
  }, []);

  if (!petitionId) {
    return <Redirect to={PathsLayouts.studentPetitions} />;
  }

  return (
    <BaseLoader petitionId={petitionId}>
      {(data) =>
        isResolved(data) ? (
          <StudentsPetitionsResolvedDetail />
        ) : (
          <StudentsPetitionsReceivedDetail />
        )
      }
    </BaseLoader>
  );
}
