import { addToast, Button, Modal, SelectOptionType } from '@octano/global-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Form, Row } from 'reactstrap';
import CourseModalForm from './CourseModalForm';
import CourseModalLoader from './CourseModalLoader';

interface CourseModalProps {
  semester: number;
  addCourse: Function;
  show: boolean;
  onClose: Function;
  setDisabledEditButton: (value: boolean) => void;
}
export interface CourseFormValues {
  type: SelectOptionType | null;
  name?: SelectOptionType | null;
  code?: string;
  credits: string;
  courseName?: string;
  courseCode?: string;
  courseCredits?: string;
}

// queda pendiente filtrar cursos por tipo de curso y no por generalTypeId
export const CourseModal = ({
  semester,
  addCourse,
  show,
  onClose,
  setDisabledEditButton,
}: CourseModalProps) => {
  const { t } = useTranslation();
  const prefix = 'studyPlans.curriculum.courseModal';

  const methods = useForm<CourseFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      type: null,
      name: null,
      code: '',
      credits: '',
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const { mentionId, curriculumId } = useParams<{
    mentionId?: string;
    curriculumId?: string;
  }>();

  const onSubmit = async (formValues: CourseFormValues) => {
    if (!formValues.type) {
      return;
    }
    const added = addCourse({
      id: formValues.name?.value,
      type: formValues.type.label,
      name: formValues.courseName,
      code: formValues.courseCode,
      credits: formValues.courseCredits || formValues.credits,
      shortening: formValues.name?.shortening,
      semester: semester,
    });
    if (added) {
      closeModal();
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.addedCourseMessage`, {
          type: 'Asignatura',
        }),
      });
      if (
        (mentionId && !isNaN(parseInt(mentionId))) ||
        (curriculumId && !isNaN(parseInt(curriculumId)))
      ) {
        setDisabledEditButton(false);
      }
    } else {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`${prefix}.errorCourseMessage`, {
          type: 'asignatura',
        }),
      });
    }
  };

  const closeModal = () => {
    reset();
    onClose();
  };

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        closeModal();
      }}
      size="lg"
    >
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)} className="course-modal">
          <CourseModalLoader>
            <CourseModalForm />
          </CourseModalLoader>
          <Row>
            <Col className="mt-4">
              <Row>
                <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
                  <Button
                    type="button"
                    text={t('common.actions.cancel')}
                    outlined
                    onClick={closeModal}
                    fullwidth
                  />
                </Col>
                <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
                  <Button
                    type="submit"
                    text={t('common.actions.add')}
                    loading={isSubmitting}
                    fullwidth
                    className="mb-3 mb-md-0"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Modal>
  );
};
