import { ColumnTable } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

const dictPrefix = 'studentCurrentAccount.tabs.paymentHistory.table';

export default function useColumns(): ColumnTable<any>[] {
  const { t } = useTranslation();

  return [
    {
      columnName: 'paymentDate',
      headerText: t(`${dictPrefix}.paymentDate`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'paymentType',
      headerText: t(`${dictPrefix}.paymentType`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'paymentChannel',
      headerText: t(`${dictPrefix}.paymentChannel`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'voucherId',
      headerText: t(`${dictPrefix}.voucherId`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'transactionCode',
      headerText: t(`${dictPrefix}.transactionCode`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'paidBy',
      headerText: t(`${dictPrefix}.paidBy`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
  ];
}
