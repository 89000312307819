import { Tooltip } from 'reactstrap';
import { useState } from 'react';
import { Icon } from '@octano/global-ui';

const SectionTitle = ({
  text,
  tooltipText,
}: {
  text: string;
  tooltipText?: string;
}) => {
  const id = Math.floor(Math.random() * 1000);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div className="d-flex">
      <p className="fs-20 text-uppercase text-primary fw-700 mb-0">{text}</p>
      {Boolean(tooltipText) ? (
        <>
          <span className="ml-2 mt-1" id={'Tooltip-' + id}>
            <Icon name="information" color="primary" size="20px" />
          </span>
          <Tooltip
            placement="right"
            isOpen={tooltipOpen}
            target={'Tooltip-' + id}
            toggle={toggle}
          >
            {tooltipText}
          </Tooltip>
        </>
      ) : null}
    </div>
  );
};

export default SectionTitle;
