import { ColumnTable, Table, TablePagination } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DisplayError from '../../components/info/DisplayError';
import SearchRut from '../../components/inputs/SearchRutValue';
import TableEmptyContent from '../../components/text/TableEmptyContent';
import { IdNotFound } from '../components/IdNotFound';
import { useAdmissionStatusList } from '../contexts/AdmissionStatusListContext';
import { getAdmissionColumnsStudent } from '../getAdmissionColumns';
import { AdmissionManagementFindItem } from '../types';

const ITEMS_PER_PAGE_DEFAULT = 10;

const prefix = 'admission';

export function AdmissionsListRetracted(): JSX.Element {
  const { t } = useTranslation();
  const { loading, data, error, query, updateQuery } = useAdmissionStatusList();
  const currentPage = query.page;
  const totalItems = data.total;
  const totalPages = data.total_pages;
  const searching = loading;
  const showPagination = data.total_pages > 1;

  const columns = useMemo<ColumnTable<AdmissionManagementFindItem>[]>(() => {
    return [
      ...getAdmissionColumnsStudent(t),
      {
        columnName: 'dateEnrolled',
        headerText: t(`common.forms.dateEnrolled`),
        width: '10%',
        cellFormat: ({ row }) =>
          row.posutlationDetailEnrolledDate
            ? dayjs(row.posutlationDetailEnrolledDate).format(
                'DD/MM/YYYY HH:mm',
              )
            : '',
      },
      {
        columnName: 'dateRetracted',
        headerText: t(`common.forms.dateRetracted`),
        width: '10%',
        cellFormat: ({ row }) =>
          row.posutlationDetailRetractedDate
            ? dayjs(row.posutlationDetailRetractedDate).format(
                'DD/MM/YYYY HH:mm',
              )
            : '',
      },
    ];
  }, [t]);

  const getColumnResponsive = useCallback((): ColumnTable<AdmissionManagementFindItem>[] => {
    let columnsRemove = ['date', 'email', 'phone', 'lastnames', 'dateEnrolled'];
    let limit = Math.round(window.innerWidth / 160);
    let nColumnsRemove = columns.length > limit ? columns.length - limit : 0;
    return columns.filter((column) => {
      if (
        columnsRemove.find((el) => el === column.columnName) &&
        nColumnsRemove !== 0
      ) {
        nColumnsRemove--;
        return false;
      } else return true;
    });
  }, [columns]);

  const [filterColumnsResponsive, setFilterColumnsResponsive] = useState<
    ColumnTable<AdmissionManagementFindItem>[]
  >([]);

  useEffect(() => {
    window.onresize = () => {
      let array = getColumnResponsive();
      setFilterColumnsResponsive([...array]);
    };
  }, [getColumnResponsive]);

  useEffect(() => {
    let array = getColumnResponsive();
    setFilterColumnsResponsive([...array]);
  }, [getColumnResponsive]);

  const TableEmpty = useMemo(() => {
    if (query.search) {
      return <IdNotFound />;
    } else {
      return (
        <TableEmptyContent
          title={t(`${prefix}.studentRetracted.messageNotRetracted`)}
          subtitle={''}
        />
      );
    }
  }, [query, t]);

  if (error) {
    return (
      <DisplayError
        insideCard
        textBody={error}
        retryAction={() => updateQuery()}
        loadingAction={loading}
      />
    );
  }
  return (
    <div className="mt-3 text-info">
      <SearchRut
        value={query.search}
        onChange={(search) => updateQuery({ page: 1, search })}
        searching={searching}
      />
      <Table
        columns={filterColumnsResponsive}
        data={data.data}
        noResultsText={TableEmpty}
        isLoadingResults={loading}
      />
      {showPagination && (
        <TablePagination
          pagination={{
            currentPage,
            itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
            onChangePage: (page) => {
              updateQuery({ page, search: query.search });
            },
            totalItems,
            totalPages,
          }}
        />
      )}
    </div>
  );
}
