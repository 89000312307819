import { Button, Modal, TextInput } from '@octano/global-ui';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import RemoteSearchSelect from '../../../../../components/form/RemoteSearchSelect';
import { useSearchCourse } from '../../../../../hooks/remoteSearchSelect/useSearchCourse';
import { TranslationsKeys } from '../../../../../locales/translations';
import { Prerequisite } from '../../../../../types/courseMaintainerTypes';
import { CourseFormFields } from '../../types';

type ModalAddPrerequisiteProps = {
  isOpen: boolean;
  toggle: () => void;
};

const ModalAddPrerequisite = ({
  isOpen,
  toggle,
}: ModalAddPrerequisiteProps) => {
  const { t } = useTranslation(TranslationsKeys.ACADEMIC_OFFER_COURSES_UPDATE);
  const searchCourse = useSearchCourse();
  const { setValue, getValues } = useFormContext<CourseFormFields>();
  const [prerequisite, setPrerequisite] = useState<Prerequisite>();
  const [error, setError] = useState<string>();

  const addPrerequisite = () => {
    if (prerequisite) {
      const newPrerequisites = getValues('prerequisites') || [];

      // Valida si la asignatura ya ha sido agregada como prerrequisito
      if (newPrerequisites.find((course) => course.id === prerequisite.id)) {
        setError(t(`prerequisiteErrorSelected`));
        return;
      }

      newPrerequisites.push(prerequisite);
      setValue('prerequisites', newPrerequisites);
      onClose();
    } else {
      setError(t(`prerequisiteErrorRequired`));
    }
  };

  const onClose = () => {
    setPrerequisite(undefined);
    setError(undefined);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg" unmountOnClose>
      <Row>
        <Col xs={12}>
          <p className="fs-22 text-dark text-center mb-4">
            {t(`modalPrerequisiteTitle`)}
          </p>
        </Col>
        <Col xs={12} className="pb-2">
          <RemoteSearchSelect
            name="coursePrerequisite"
            label={t(`modalPrerequisiteName`)}
            filterOptions={searchCourse}
            onChange={(selectedOption) => {
              if (selectedOption) {
                setPrerequisite({
                  id: selectedOption.id,
                  name: selectedOption.name,
                  shortening: selectedOption.shortening,
                  credits: selectedOption.credits,
                });
                setError(undefined);
              } else {
                setPrerequisite(undefined);
              }
            }}
            errorText={error}
          />
        </Col>
        <Col xs={6}>
          <TextInput
            name="shorteningPrerequisite"
            label={t(`modalPrerequisiteShortening`)}
            value={prerequisite?.shortening}
            disabled
          />
        </Col>
        <Col xs={6}>
          <TextInput
            name="creditsPrerequisite"
            label={t(`modalPrerequisiteCredits`)}
            value={prerequisite?.credits}
            disabled
          />
        </Col>
      </Row>
      <Row className="pt-5">
        <Col xs={12} lg={6} className="pb-2 order-2 order-lg-1">
          <Button
            type="button"
            outlined
            onClick={onClose}
            text={t(`modalPrerequisiteCancel`)}
            fullwidth
          />
        </Col>
        <Col xs={12} lg={6} className="pb-2 order-1 order-lg-2">
          <Button
            text={t(`modalPrerequisiteSave`)}
            onClick={addPrerequisite}
            fullwidth
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalAddPrerequisite;
