// Components
import { Tab, TabPanel, TabPanels, Tabs, TabsList } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Hooks
import useQuery from '../../hooks/useQuery';
import useUserState from '../../hooks/useUserState';
import { TranslationsKeys } from '../../locales/translations';
// Types
import { PermissionName } from '../../types/Auth';
import Academics from './academics/Academics';
import Finantial from './finantial/Finantial';
import FinantialOthers from './finantialOthers';
import GradingStructure from './GradingStructure';

enum TabName {
  Demographics = 'demographics',
  General = 'general',
  Academics = 'academics',
  Finantial = 'finantial',
  FinantialOthers = 'finantialOthers',
  HelpCenter = 'help-center',
  Installments = 'installments',
  GradingStructure = 'gradingStructure',
}

type TabRoute = {
  Component: () => JSX.Element;
  name: TabName;
  requiredPermissions: PermissionName[];
  allPermisionsRequired?: boolean;
};

// Constants
const TAB_ROUTES: TabRoute[] = [
  {
    Component: () => <Academics />,
    name: TabName.Academics,
    requiredPermissions: [PermissionName.SETTINGS],
  },
  {
    Component: () => <Finantial />,
    name: TabName.Finantial,
    requiredPermissions: [PermissionName.SETTINGS],
  },
  {
    Component: () => <FinantialOthers />,
    name: TabName.FinantialOthers,
    requiredPermissions: [PermissionName.SETTINGS],
  },
  {
    Component: () => <GradingStructure />,
    name: TabName.GradingStructure,
    requiredPermissions: [PermissionName.SETTINGS],
  },
];

export default function Configurations() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const params = useQuery();
  const { isAuthorizedTo } = useUserState();

  const { t } = useTranslation(TranslationsKeys.CONFIGURATION_TABS);

  const authorizedRoute = useMemo(() => {
    return TAB_ROUTES.filter(
      (route) =>
        !route.requiredPermissions?.length ||
        isAuthorizedTo(route.requiredPermissions, route.allPermisionsRequired),
    );
  }, [isAuthorizedTo]);

  const defaultTab = useMemo(() => {
    if (!authorizedRoute?.length) {
      return TabName.Academics;
    }
    return authorizedRoute[0].name;
  }, [authorizedRoute]);

  const initialTab = useMemo(() => {
    const queryTab = params.get('tab');
    if (!queryTab) return defaultTab;

    const isValidTab = authorizedRoute.some((ar) => ar.name === queryTab);
    return isValidTab ? queryTab : defaultTab;
  }, [params, defaultTab, authorizedRoute]);

  const handleTabSelection = (selectedTab: number | string) => {
    history.push({ pathname: path, search: `?tab=${selectedTab}` });
    return null;
  };

  return (
    <div className="mx-3">
      <Tabs defaultTab={initialTab} onSelection={handleTabSelection}>
        <TabsList>
          {authorizedRoute.map((route) => (
            <Tab id={route.name} key={route.name}>
              {t(`${route.name}`)}
            </Tab>
          ))}
        </TabsList>
        <TabPanels>
          {authorizedRoute.map(({ Component, ...route }) => (
            <TabPanel
              id={route.name}
              key={route.name}
              children={<Component />}
            />
          ))}
        </TabPanels>
      </Tabs>
    </div>
  );
}
