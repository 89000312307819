import { TeacherAccountData } from '../../types/Teacher';
import { IdentificationTypeResponse } from '../../views/TeacherManagement/TeacherActivation/types';
import { TEACHER_ACTIVATION } from '../endpoints';
import request from '../request';

/**
 * Busca un docente por tipo de identificacion
 * @param data Datos del docente a buscar
 * @returns 404 si no existe o el TeacherAccountData de ese docente en caso de existir
 */
export const searchTeacherByRut = (data: {
  identificationTypeId: string;
  countryId?: string;
  identification: string;
}) => {
  const { identificationTypeId, countryId, identification } = data;
  return request<TeacherAccountData>(TEACHER_ACTIVATION.SEARCH, {
    params: { identification, identificationTypeId, countryId },
  });
};

/**
 * Envia un email para activar cuenta docente y actualiza el correo.
 * Si la cuenta no existia, se crea. Si al crearla se detecta que
 * hay otra cuenta con el mismo RUT, se notifica el conflicto. Si se
 * pasa la bandera ignoreWarnings: true se sobrepasa cualquier error
 * @param data Datos del docente
 * @returns De haber algun conflicto retorna un array de mensajes (strings)
 */
export const sendTeacherAccountActivationLink = (values: {
  email: string;
  identification: string;
  identificationTypeId: string;
  countryId?: string | null;
  name: string;
  personalEmail: string;
  paternalLastName: string;
  maternalLastName: string;
  birthday: string;
  ignoreWarnings?: boolean;
}) => {
  const surpassErrors = values.ignoreWarnings ?? false;
  const data = { ...values, ignoreWarnings: surpassErrors };
  return request<{ message?: string[]; teacherId?: number }>(
    TEACHER_ACTIVATION.SEND_ACTIVATION_LINK,
    {
      data,
      method: 'POST',
    },
  );
};

/**
 * Envia un email para recuperar la contraseña y actualiza el correo del docente
 * @param data Email y rut del usuario
 * @returns
 */
export const sendTeacherPasswordRecoveryLink = (data: {
  email: string;
  identification: string;
  identificationTypeId: string;
  countryId?: string | null;
}) => {
  return request(TEACHER_ACTIVATION.SEND_PASSWORD_RECOVERY_LINK, {
    method: 'POST',
    data,
  });
};

/**
 * Coloca una cuenta de docente como inactiva
 * @param data Email y identification del usuario
 * @returns
 */
export const deactivateTeacherAccount = (data: {
  email: string;
  identification: string;
  identificationTypeId: string;
  countryId?: string | null;
}) => {
  return request(TEACHER_ACTIVATION.DEACTIVATE_TEACHER, {
    method: 'POST',
    data,
  });
};

export function getIdentificationTypeConfig() {
  return request<IdentificationTypeResponse>(
    TEACHER_ACTIVATION.IDENTIFICATION_TYPES,
  );
}
