import { Button, Modal } from '@octano/global-ui';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { TranslationsKeys } from '../../../../locales/translations';
import { DiscountManagementListResponse } from '../../types';
import { useTuitionContinuityDiscountManagementFilterLoader } from '../TuitionContinuityDiscountManagementFilterLoader';
import { TuitionContinuityDiscount } from './TuitionContinuityDiscountConfirmUpdateModal';
import TuitionContinuityDiscountUpdateForm, {
  TuitionContinuityDiscountUpdateFormData,
} from './TuitionContinuityDiscountUpdateForm';

interface Props {
  onConfirm: (row: TuitionContinuityDiscount) => void;
}

export interface TuitionContinuityDiscountUpdateModalRef {
  closeModal: () => void;
  openModal: (row: DiscountManagementListResponse) => void;
}

const prefix = (key: string) => `actions.${key}`;

const defaultForm = {
  id: undefined,
  type: null,
  discountName: undefined,
  discountCode: undefined,
  discountPercentage: undefined,
};

const TuitionContinuityDiscountUpdateModal = forwardRef<
  TuitionContinuityDiscountUpdateModalRef,
  Props
>(({ onConfirm }, ref) => {
  const { t } = useTranslation(TranslationsKeys.TC_DISCOUNT_MANAGEMENT);
  const { data } = useTuitionContinuityDiscountManagementFilterLoader();
  const forms = useForm<TuitionContinuityDiscountUpdateFormData>({
    defaultValues: defaultForm,
  });
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = forms;
  const [modal, setModal] = useState(false);

  const handleClose = useCallback(() => {
    setModal(false);
    reset(defaultForm);
  }, [reset]);

  const handleOpen = useCallback(
    (row: DiscountManagementListResponse) => {
      const discountType = data!.discountTypes.find(
        (x) => (x.id = row.discountType),
      )!;
      reset({
        id: row.discountId,
        discountName: row.discountName,
        discountCode: row.discountCode,
        discountPercentage: row.discountPercentage,
        type: {
          label: discountType.name,
          value: discountType.id,
        },
      });
      setModal(true);
    },
    [reset, data],
  );

  useImperativeHandle(ref, () => ({
    openModal: handleOpen,
    closeModal: handleClose,
  }));

  const onSubmit = useCallback(
    (values: TuitionContinuityDiscountUpdateFormData) => {
      onConfirm({
        id: values.id!,
        type: `${values.type!.value}`,
        discountCode: values.discountCode!,
        discountName: values.discountName!,
        discountPercentage: values.discountPercentage!,
      });
      handleClose();
    },
    [onConfirm, handleClose],
  );

  return (
    <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
      <Container fluid>
        <p className="text-dark fs-20 mt-3 mb-4 text-center">
          {t(prefix('update.title'))}
        </p>
        <p className="fs-16 mt-3 mb-4 mx-5 px-5 text-center">
          {t(prefix('update.description'))}
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...forms}>
            <Row>
              <TuitionContinuityDiscountUpdateForm />
              <Col xs={12} md={6}>
                <Button
                  outlined
                  text={t(prefix('update.cancel'))}
                  fullwidth
                  onClick={() => setModal(false)}
                  className="mb-3"
                />
              </Col>
              <Col xs={12} md={6}>
                <Button
                  type="submit"
                  text={t(prefix('update.confirm'))}
                  className="mb-3"
                  fullwidth
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
          </FormProvider>
        </form>
      </Container>
    </Modal>
  );
});

export default TuitionContinuityDiscountUpdateModal;
