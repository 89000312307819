import {
  StudentPetitionsExcelFilterResponse,
  StudentPetitionsFilterResponse,
  StudentPetitionsResponse,
} from '../../types/studentPetitions';
import { SearchParams } from '../../views/StudentsPetitionsList/types/Petitions';
import { STUDENT_PETITIONS } from '../endpoints';
import request from '../request';

/**
 * Obtiene el listado de las solicitudes de los estudiantes
 * @param items
 * @param page
 * @returns
 */
export function getStudentsPetitionsList(
  filter: { items: number; page: number } & SearchParams,
) {
  const URL = STUDENT_PETITIONS.GET_STUDENT_PETITIONS_LIST;
  const method = 'GET';
  const params = { ...filter, items_per_page: filter.items };
  return request<StudentPetitionsResponse>(URL, { method, params });
}

/**
 * Obtiene el listado de las solicitudes resueltas de los estudiantes
 * @param items
 * @param page
 * @returns
 */
export function getStudentsResolvedPetitionsList(
  filter: { items: number; page: number } & SearchParams,
) {
  const URL = STUDENT_PETITIONS.GET_STUDENT_RESOLVED_PETITIONS_LIST;
  const method = 'GET';
  const params = { ...filter, items_per_page: filter.items };
  return request<StudentPetitionsResponse>(URL, { method, params });
}

/**
 * Obtiene el listado de los filtros a mostrar para las solicitudes de los estudiantes
 * @returns
 */
export function studentsPetitionsListFilter() {
  const URL = STUDENT_PETITIONS.FILTER_STUDENT_PETITIONS_LIST;
  const method = 'GET';
  return request<StudentPetitionsFilterResponse>(URL, { method });
}

/**
 * Obtiene un archivo excel con la lista de las solicitudes de los estudiantes a partir de los filtros seleccionados
 * @returns
 */
export function downloadPetitionsListByFilter(filter: SearchParams) {
  const method = 'GET';
  return request(STUDENT_PETITIONS.DOWNLOAD_STUDENT_PETITIONS_LIST_BY_FILTER, {
    responseType: 'blob',
    method,
    params: filter,
  });
}
export function downloadPetitionsListByFilterResolved(filter: SearchParams) {
  const method = 'GET';
  return request(
    STUDENT_PETITIONS.DOWNLOAD_STUDENT_PETITIONS_LIST_BY_FILTER_RESOLVED,
    {
      responseType: 'blob',
      method,
      params: filter,
    },
  );
}

/**
 * Obtiene el listado de los filtros a mostrar para el reporte en excel de solicitudes de los estudiantes
 * @returns
 */
export function studentsPetitionsListExcelFilter() {
  const URL = STUDENT_PETITIONS.FILTER_STUDENT_PETITIONS_EXCEL_LIST;
  const method = 'GET';
  return request<StudentPetitionsExcelFilterResponse>(URL, { method });
}
