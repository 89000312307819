import { useCallback } from 'react';
import {
  addFile,
  addTitle,
  deleteTitle,
  purgeFiles,
  updateTitle,
} from '../api';
import { FormDataAction } from '../type';

export interface UseActions {
  createTitle: (teacherId: number, data: FormDataAction) => Promise<void>;
  updateTitle: (degreedId: number, data: FormDataAction) => Promise<void>;
  deleteTitle: (degreedId: number) => Promise<void>;
  updateFiles: (
    degreedId: number,
    files: FormDataAction['files'],
  ) => Promise<void>;
}
type ActionType = 'create' | 'update' | 'delete';

export default function useActions(props: {
  onSuccess: (action: ActionType) => void;
  onError: (action: ActionType, message?: string) => void;
}) {
  const { onSuccess, onError } = props;

  const updateFilesAction = useCallback(
    async (recordId: number, files: FormDataAction['files']) => {
      try {
        const filesToKeep = files?.map((e) => e.code) ?? [];
        const filesToUpload =
          files?.filter((e) => e.linkedFile?.lastModified !== -1) ?? [];
        await purgeFiles(recordId, filesToKeep);
        if (filesToUpload?.length) {
          await Promise.all(
            filesToUpload.map((fileItem) =>
              addFile(recordId, {
                code: fileItem.code,
                linkedFile: fileItem.linkedFile,
              }),
            ),
          );
        }
      } catch (error) {}
    },
    [],
  );

  const addTitleAction = useCallback(
    async (teacherId: number, { files = [], ...data }: FormDataAction) => {
      const result = await addTitle(teacherId, data);
      if (!result.error) {
        await updateFilesAction(result?.data?.id, files);
        return onSuccess('create');
      }
      onError('create');
    },
    [onSuccess, onError, updateFilesAction],
  );

  const updateTitleAction = useCallback(
    async (degreedId, { files = [], ...data }: FormDataAction) => {
      const result = await updateTitle(degreedId, data);
      if (!result.error) {
        await updateFilesAction(degreedId, files);
        return onSuccess('update');
      }
      onError('update');
    },
    [onSuccess, onError, updateFilesAction],
  );

  const deleteTitleAction = useCallback(
    async (sectionId: number) => {
      const result = await deleteTitle(sectionId);
      if (!result.error) {
        return onSuccess('delete');
      }
      onError('delete');
    },
    [onSuccess, onError],
  );

  return {
    createTitle: addTitleAction,
    updateTitle: updateTitleAction,
    deleteTitle: deleteTitleAction,
    updateFiles: updateFilesAction,
  };
}
