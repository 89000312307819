import { Select, SelectOptionType, TextInput } from '@octano/global-ui';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';
import { useValidations } from '../../../../hooks/useValidations';
import { TranslationsKeys } from '../../../../locales/translations';
import { useTuitionContinuityDiscountManagementFilterLoader } from '../TuitionContinuityDiscountManagementFilterLoader';

export interface TuitionContinuityDiscountUpdateFormData {
  id?: number;
  type: SelectOptionType | null;
  discountName?: string;
  discountCode?: string;
  discountPercentage?: number;
}

const prefix = (key: string) => `actions.update.${key}`;

const TuitionContinuityDiscountUpdateForm = () => {
  const { t } = useTranslation(TranslationsKeys.TC_DISCOUNT_MANAGEMENT);
  const { data } = useTuitionContinuityDiscountManagementFilterLoader();
  const { validateTextNotEmpty, msgValidations } = useValidations();

  const forms = useFormContext<TuitionContinuityDiscountUpdateFormData>();
  const { control, watch, setValue } = forms;

  const discountTypeOptions = useMemo(
    () =>
      data!.discountTypes.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    [data],
  );

  const discountCode = watch('discountCode');

  useEffect(() => {
    const discount = data?.discounts.find((x) => x.code === discountCode);
    if (discount) {
      setValue('discountName', discount.name);
      setValue('discountPercentage', discount.percentage);
    } else {
      setValue('discountName', undefined);
      setValue('discountPercentage', undefined);
    }
  }, [discountCode, data?.discounts, setValue]);

  return (
    <>
      <div className="d-none">
        <TextInput
          name="id"
          label=""
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </div>
      <Col xs={12}>
        <Select
          options={discountTypeOptions}
          label=""
          name="type"
          control={control}
        />
      </Col>
      <Col xs={12} md={6} className="mt-4">
        <TextInput
          name="discountCode"
          label={t(prefix('code'))}
          placeholder={t(prefix('code'))}
          control={control}
          rules={{
            validate: {
              require: validateTextNotEmpty,
              test: (value) => {
                if (!data?.discounts.some((x) => x.code === value))
                  return 'El codigo no existe';
                return undefined;
              },
            },
          }}
        />
      </Col>
      <Col xs={12} md={6} className="mt-4">
        <TextInput
          name="discountPercentage"
          label={t(prefix('percentaje'))}
          placeholder={t(prefix('percentaje'))}
          control={control}
          rules={{ required: msgValidations.required }}
          readOnly
        />
      </Col>

      <Col xs={12} className="my-4">
        <TextInput
          name="discountName"
          label={t(prefix('name'))}
          placeholder={t(prefix('name'))}
          control={control}
          rules={{ required: msgValidations.required }}
          readOnly
        />
      </Col>
    </>
  );
};

export default TuitionContinuityDiscountUpdateForm;
