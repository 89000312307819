import { PARAMETERS } from '../endpoints';
import request from '../request';

export const COUNTRY_CHILE_ID = 'cl';

/**
 * Obtiene listado de países
 */
export const getCountriesRequest = () => {
  return request<{ id: string; name: string; nationality: string }[]>(
    PARAMETERS.COUNTRIES,
  );
};

/**
 * Obtiene listado de países
 */
export const getIntitutionTypesRequest = () => {
  return request<{ data: { id: string; name: string; description: string }[] }>(
    PARAMETERS.INSTITUTION_TYPES,
  );
};

export const getCourseTypesRequest = () => {
  return request<{ id: string; name: string; generalTypeId: number }[]>(
    PARAMETERS.COURSE_TYPES,
  );
};
