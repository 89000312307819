import { ColumnTable, Icon } from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PathsLayouts } from '../../../../config/routes';
import { UncontrolledTooltip } from 'reactstrap';
import { CourseMaintainerListType } from '../../../../types/courseMaintainerTypes';
import { formatDate } from '../../../../utils/dates';
import BadgeState from '../parts/BadgeState';
import { TranslationsKeys } from '../../../../locales/translations';

export const useColumnsCoursesList = () => {
  const prefix = 'columns';
  const { t } = useTranslation(TranslationsKeys.ACADEMIC_OFFER_COURSES_LIST);
  const history = useHistory();

  const watchCourse = useCallback(
    (courseId: number) => {
      history.push(
        `${PathsLayouts.academicOffers}/curricular-subjects/watch/${courseId}`,
      );
    },
    [history],
  );

  const updateCourse = useCallback(
    (courseId: number) => {
      history.push(
        `${PathsLayouts.academicOffers}/curricular-subjects/update/${courseId}`,
      );
    },
    [history],
  );

  const openUrl = useCallback((url?: string) => {
    if (!url?.trim()) {
      return;
    }
    window.open(url?.trim(), '_blank');
  }, []);

  const columns: ColumnTable<CourseMaintainerListType>[] = useMemo(() => {
    return [
      {
        columnName: 'shortening',
        thClassName: 'text-center',
        tdClassName: 'text-center text-nowrap',
        headerText: t(`${prefix}.code`),
        cellFormat: ({ row }) => row.shortening,
      },
      {
        columnName: 'name',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.name`),
      },
      {
        columnName: 'school',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.school`),
        cellFormat: ({ row }) => row.school.name,
      },
      {
        columnName: 'program',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.studyPlan`),
        cellFormat: ({ row, index }) => {
          if (!row.semesterCourses.length) {
            return '';
          }
          if (row.semesterCourses.length === 1) {
            return row.semesterCourses[0].semester.curriculum?.studyPlanVersion
              ?.name;
          }
          return (
            <>
              <p>
                Varios programas
                <span className="ml-1" id={`tooltip-${index.toString()}`}>
                  <Icon name="eye" />
                </span>
              </p>
              <UncontrolledTooltip
                style={{
                  width: '300px',
                  paddingLeft: 0,
                  paddingRight: 30,
                }}
                placement="right"
                target={`tooltip-${index.toString()}`}
              >
                <ul>
                  {row.semesterCourses.map((semesterCourse) => (
                    <li>
                      {
                        semesterCourse.semester.curriculum?.studyPlanVersion
                          ?.name
                      }
                    </li>
                  ))}
                </ul>
              </UncontrolledTooltip>
            </>
          );
        },
      },
      {
        columnName: 'modality',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.modality`),
        cellFormat: ({ row }) => {
          return row.modality?.name;
        },
      },
      {
        columnName: 'credits',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.credits`),
        cellFormat: ({ row }) => row.credits,
      },
      {
        columnName: 'createdAt',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.createdAt`),
        cellFormat: ({ row }) => formatDate(row.createdAt, 'DD-MM-YYYY'),
      },
      {
        columnName: 'syllabus',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.syllabus`),
        cellFormat: ({ value }) => (
          <div
            className="d-flex d-flex justify-content-start align-items-center"
            style={{ gap: 5 }}
          >
            <BadgeState
              status={value?.url?.trim() ? 'loaded' : 'unloaded'}
              text={
                value?.url?.trim()
                  ? t(`${prefix}.syllabusLoaded`)
                  : t(`${prefix}.syllabusUnloaded`)
              }
            />
            <button
              type="button"
              className="btn"
              onClick={() => openUrl(value?.url)}
              disabled={!value?.url?.trim()}
            >
              <Icon
                name="download"
                color={value?.url?.trim() ? 'primary' : 'disabled'}
              />
            </button>
          </div>
        ),
      },
      {
        columnName: 'id',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common:actions.action`),
        cellFormat: ({ row }) => {
          return (
            <div className="d-flex d-flex justify-content-center">
              <div
                className="cursor-pointer mr-2"
                onClick={() => {
                  watchCourse(row.id);
                }}
              >
                <Icon size="15" name="eye" />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  updateCourse(row.id);
                }}
              >
                <Icon size="15" name="edit" />
              </div>
            </div>
          );
        },
      },
    ];
  }, [t, watchCourse, updateCourse, openUrl]);

  return columns;
};
