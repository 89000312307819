import { Button, ColumnTable, Table, TablePagination } from '@octano/global-ui';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DisplayError from '../../components/info/DisplayError';
import SearchRut from '../../components/inputs/SearchRutValue';
import TableEmptyContent from '../../components/text/TableEmptyContent';
import { useLayoutState } from '../../hooks/useLayoutState';
import { IdNotFound } from '../components/IdNotFound';
import { useAdmissionStatusList } from '../contexts/AdmissionStatusListContext';
import { DesistAdmissionModal } from '../inProcess/DesistAdmissionModal';
import { AdmissionManagementFindItem } from '../types';
import { MoveToInProcessModal } from './MoveToInProcessModal';

function WaitingList() {
  const { loading, data, error, query, updateQuery } = useAdmissionStatusList();
  const totalItems = data.total;
  const totalPages = data.total_pages;
  const currentPage = query.page;
  const showPagination = data.total_pages > 1;

  const [studentId, setStudentId] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [showMoveInProcess, setShowMoveInProcess] = useState<boolean>(false);
  const [showDesistAdmission, setShowDesistAdmission] = useState<boolean>(
    false,
  );

  const { showTabs } = useLayoutState();
  useEffect(() => {
    showTabs();
  }, [showTabs]);

  const { t } = useTranslation();
  const prefix = 'admission';

  const toDesist = (id: number, postulantName: string) => {
    setStudentId(id);
    setName(postulantName);
    setShowDesistAdmission(true);
  };

  const toInProcess = (id: number, postulantName: string) => {
    setStudentId(id);
    setName(postulantName);
    setShowMoveInProcess(true);
  };

  //Discounts columns
  const columns: ColumnTable<AdmissionManagementFindItem>[] = [
    {
      columnName: 'rut',
      headerText: t(`common.forms.rut`),
      width: '110px',
      cellFormat: (options) =>
        `${options.row.postulantIdentificationValue} (${options.row.postulantIdentificationType})`,
    },
    {
      columnName: 'postulantFistname',
      headerText: t(`common.forms.names`),
      width: '160px',
    },
    {
      columnName: 'postulantLastname',
      headerText: t(`common.forms.lastName`),
      width: '160px',
    },
    {
      columnName: 'postulantPhone',
      headerText: t(`common.forms.phone`),
      width: '150px',
    },
    {
      columnName: 'postulantEmail',
      headerText: t(`common.forms.email`),
      width: '150px',
    },
    {
      columnName: 'id',
      headerText: '',
      width: '20%',
      tdClassName: 'text-nowrap',
      cellFormat: (options) => (
        <div className="d-flex justify-content-center">
          <Button
            size="sm"
            outlined
            color="danger"
            style={{ marginLeft: '10px', minWidth: '100px' }}
            onClick={() =>
              toDesist(
                options.row.postulationDetailId,
                options.row.postulantName,
              )
            }
            text={t(`${prefix}.desist`)}
          />
          <Button
            text={t(`${prefix}.moveToProcess`)}
            size="sm"
            style={{ marginLeft: '10px' }}
            onClick={() =>
              toInProcess(
                options.row.postulationDetailId,
                options.row.postulantName,
              )
            }
          />
        </div>
      ),
    },
  ];

  const TableEmpty = useMemo(() => {
    if (query.search) {
      return <IdNotFound />;
    } else {
      return (
        <TableEmptyContent
          title={t(`${prefix}.studentWaitingList.emptyList`)}
          subtitle={''}
        />
      );
    }
  }, [query, t]);

  if (error) {
    return (
      <div className="mt-3">
        <DisplayError
          insideCard
          textBody={error}
          retryAction={() => updateQuery()}
        />
      </div>
    );
  }

  return (
    <div className="mt-3 text-info">
      <SearchRut
        value={query.search}
        onChange={(search) => updateQuery({ page: 1, search })}
        searching={loading}
      />
      <div>
        <Table
          columns={columns}
          data={data.data}
          noResultsText={TableEmpty}
          isLoadingResults={loading}
        />
        {showPagination && (
          <TablePagination
            pagination={{
              currentPage,
              itemsPerPage: 10,
              onChangePage: (page) => {
                updateQuery({ page, search: query.search });
              },
              totalItems,
              totalPages,
            }}
          />
        )}
      </div>
      <DesistAdmissionModal
        show={showDesistAdmission}
        toggleModal={() => {
          setShowDesistAdmission(false);
        }}
        reloadList={() => updateQuery()}
        id={studentId}
        name={name}
      />
      <MoveToInProcessModal
        show={showMoveInProcess}
        toggleModal={() => {
          setShowMoveInProcess(false);
        }}
        reloadList={() => updateQuery()}
        id={studentId}
        name={name}
      />
    </div>
  );
}

export default WaitingList;
