import { ReactElement, useCallback } from 'react';
import { AxiosResultDefaultError } from '../../../../api/request';
import { createFetchContext } from '../../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { finantialOthersFormDataRequest } from '../api';
import { FinantialOthersFormDataResponse } from '../type';

interface BaseLoaderProps {
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  FinantialOthersFormDataResponse,
  AxiosResultDefaultError
>();

export function useFinantialOthersLoader() {
  return useFetch();
}

export const FinantialOthersLoaderConsumer = FetchConsumer;

export default function FinantialOthersLoader({ children }: BaseLoaderProps) {
  const request = useCallback(async () => {
    return finantialOthersFormDataRequest();
  }, []);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ loading, data, error, refresh }) => {
          if (loading) {
            return <Loading insideCard />;
          } else if (error) {
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
              />
            );
          } else if (data) {
            return children;
          } else {
            return null;
          }
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
