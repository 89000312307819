import { ColumnTable, Icon, TextAreaInput } from '@octano/global-ui';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../locales/translations';
import { CourseFormContext } from '../providers/CourseProvider';
import { CourseFormMode } from '../types';

export const useColumnsLearningResults = ({
  onDelete,
  onEdit,
}: {
  onDelete: (index: number) => Promise<void>;
  onEdit: (index: number) => void;
}) => {
  const { t } = useTranslation(TranslationsKeys.ACADEMIC_OFFER_COURSES_UPDATE);
  const { mode } = useContext(CourseFormContext);

  const canEdit = useMemo(() => mode !== CourseFormMode.WATCH, [mode]);
  return useMemo<ColumnTable<any>[]>(() => {
    return [
      {
        columnName: 'code',
        thClassName: 'text-center',
        tdClassName: 'text-center font-weight-bold',
        headerText: t(`learningResultCode`),
      },
      {
        columnName: 'description',
        thClassName: 'text-center',
        tdClassName: 'text-center p-0 ',
        headerText: t(`learningResultDescription`),
        cellFormat: ({ row }) => {
          return (
            <div className="d-flex align-items-center" style={{ height: '10' }}>
              <TextAreaInput
                height="10"
                className="w-100"
                label=""
                value={row.description}
                name={'description'}
                disabled={true}
              />
            </div>
          );
        },
      },
      {
        columnName: 'actions',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: '15%',
        headerText: t(`learningResultAction`),
        cellFormat: ({ index }) => (
          <div className="d-flex  justify-content-center">
            {}
            <button
              type="button"
              className="btn btn-primary bg-transparent border-0"
              onClick={() => {
                if (canEdit) {
                  onEdit(index);
                }
              }}
            >
              <Icon
                size="15"
                name="edit"
                color={canEdit ? 'primary' : 'disabled'}
              />
            </button>
            <button
              type="button"
              className="btn btn-primary bg-transparent border-0"
              onClick={async () => {
                if (canEdit) {
                  await onDelete(index);
                }
              }}
            >
              <Icon
                size="15"
                name="trash"
                color={canEdit ? 'primary' : 'disabled'}
              />
            </button>
          </div>
        ),
      },
    ];
  }, [t, onDelete, onEdit, canEdit]);
};
