import { useContext, useState, useCallback } from 'react';
import { Button, Table, showDialogConfirm } from '@octano/global-ui';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormContext, useFieldArray } from 'react-hook-form';

import { CourseFormContext } from '../../providers/CourseProvider';
import { useColumnsCompatibilities } from '../../hooks/useColumnsCompatibilities';
import { SectionTitle } from '../../../../../components/text';
import { CourseFormFields, CourseFormMode } from '../../types';
import TableEmptyContent from '../../../../../components/text/TableEmptyContent';
import ModalAddCompatibility from '../modals/ModalAddCompatibility';
import { TranslationsKeys } from '../../../../../locales/translations';

const CourseCompatibilitiesSection = () => {
  const { t } = useTranslation(TranslationsKeys.ACADEMIC_OFFER_COURSES_UPDATE);
  const { control } = useFormContext<CourseFormFields>();

  const { mode } = useContext(CourseFormContext);

  const {
    fields: compatibilities = [],
    remove: removeCompatibility,
  } = useFieldArray({
    control,
    name: 'compatibilities',
  });

  const [
    modalCompatibilitiesOpen,
    setModalCompatibilitiesOpen,
  ] = useState<boolean>(false);

  const onDeleteCompatibility = useCallback(
    (index: number) => {
      showDialogConfirm({
        icon: {
          name: 'warning',
          color: 'warning',
        },
        title: t(`removeCompatibilityTitle`),
        subtitle: '',
        btnConfirm: {
          text: t(`common:actions.confirm`),
          onConfirm: () => removeCompatibility(index),
        },
        btnClose: {
          text: t(`common:actions.cancel`),
        },
      });
    },
    [removeCompatibility, t],
  );

  const { columns: columnsCompatibilities } = useColumnsCompatibilities({
    mode,
    onDeleteCompatibility,
  });

  return (
    <>
      <Row>
        <Col
          xs={12}
          className="d-flex justify-content-between align-items-center p-4"
        >
          <SectionTitle text={t(`compatibilities`)} />
          {mode !== CourseFormMode.WATCH && (
            <Button
              icon="plus"
              onClick={() => setModalCompatibilitiesOpen(true)}
              size="md"
              text={t(`addCompatibility`)}
            />
          )}
        </Col>
        <Col xs={12}>
          <Table
            columns={columnsCompatibilities}
            data={compatibilities}
            noResultsText={
              <TableEmptyContent
                title={t(`titleEmptyCompatibilities`)}
                subtitle={t(
                  `${
                    mode === CourseFormMode.WATCH
                      ? 'watchBodyEmptyCompatibilities'
                      : 'bodyEmptyCompatibilities'
                  }`,
                )}
              />
            }
          />
        </Col>
      </Row>

      <ModalAddCompatibility
        isOpen={modalCompatibilitiesOpen}
        toggle={() => setModalCompatibilitiesOpen(false)}
      />
    </>
  );
};

export default CourseCompatibilitiesSection;
