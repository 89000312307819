import { Button, showDialogConfirm, Table, addToast } from '@octano/global-ui';
import { useCallback, useContext, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { SectionTitle } from '../../../../../components/text';
import TableEmptyContent from '../../../../../components/text/TableEmptyContent';
import { TranslationsKeys } from '../../../../../locales/translations';
import { requetCheckLearningResultCanBeDeleted } from '../../api';
import { useColumnsLearningResults } from '../../hooks/useColumnsLearningResults';
import { CourseFormContext } from '../../providers/CourseProvider';
import { CourseFormFields, CourseFormMode } from '../../types';
import ModalAddCourseLearning, {
  ModalAddCourseLearningRef,
} from '../modals/ModalAddCourseLearning';

const LearningResultSection = () => {
  const { t } = useTranslation(TranslationsKeys.ACADEMIC_OFFER_COURSES_UPDATE);
  const CourseLearningModalRef = useRef<ModalAddCourseLearningRef>(null);

  const { control, watch } = useFormContext<CourseFormFields>();
  const { mode, courseId } = useContext(CourseFormContext);
  const learningResults = watch('learningResults');
  const { remove, append } = useFieldArray({
    control,
    name: 'learningResults',
  });

  const onDelete = useCallback(
    async (index: number) => {
      if (mode === CourseFormMode.WATCH) return;
      const learningResultId = learningResults[index]?.id;

      if (learningResultId && mode === CourseFormMode.UPDATE) {
        const { error } = await requetCheckLearningResultCanBeDeleted(
          courseId!,
          learningResultId,
        );
        if (error) {
          addToast({
            icon: 'error',
            color: 'danger',
            text: t(`removeLearningResultError`),
          });
          return;
        }
      }

      showDialogConfirm({
        icon: {
          name: 'warning',
          color: 'warning',
        },
        title: t(`removeLearningResultTitle`),
        subtitle: '',
        btnConfirm: {
          text: t(`removeLearningResultRemoveConfirm`),
          onConfirm: () => remove(index),
        },
        btnClose: {
          text: t(`removeLearningResultRemoveCancel`),
        },
      });
    },
    [remove, t, learningResults, mode, courseId],
  );

  const onEdit = useCallback((index: number) => {
    CourseLearningModalRef?.current?.openModal(index);
  }, []);

  const columns = useColumnsLearningResults({
    onDelete,
    onEdit,
  });

  return (
    <>
      <Row>
        <Col
          xs={12}
          className="d-flex justify-content-between align-items-center p-4"
        >
          <SectionTitle text={t(`learningResult`)} />
          {mode !== CourseFormMode.WATCH && (
            <Button
              icon="plus"
              onClick={() => CourseLearningModalRef?.current?.openModal()}
              size="md"
              text={t(`addLearningResult`)}
            />
          )}
        </Col>
        <Col xs={12}>
          <Table
            columns={columns}
            data={learningResults ?? []}
            noResultsText={
              <TableEmptyContent
                title={t(`titleEmptyCompatibilities`)}
                subtitle={t(
                  `${
                    mode === CourseFormMode.WATCH
                      ? 'watchBodyEmptyCompatibilities'
                      : 'bodyEmptyCompatibilities'
                  }`,
                )}
              />
            }
          />
        </Col>
      </Row>
      <ModalAddCourseLearning
        ref={CourseLearningModalRef}
        onConfirm={append}
        onDecline={() => null}
      />
    </>
  );
};

export default LearningResultSection;
