import { Button, Modal, SelectOptionType, addToast } from '@octano/global-ui';
import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useValidations } from '../../../../../../../hooks/useValidations';
import { useTexts } from '../../hooks/textsContext';
import { UseActions } from '../../hooks/useActions';
import {
  EducationalBackgroundType,
  EducationRecord,
  FormDataAction,
} from '../../type';
import { educationRecordToForm } from '../../utils';
import { useEducationalBackgroundFormDataLoader } from '../Loaders/EducationalBackgroundFormDataLoader';
import CreateOrUpdateModalForm, {
  ModalFormData,
  defaultValues,
} from './CreateOrUpdateModalForm';
import CreateOrUpdateModalFormFiles from './CreateOrUpdateModalFormFiles';
import { MapedRequiredFile } from './CreateOrUpdateModalFormFile';

export interface CreateOrUpdateRef {
  closeModal: () => void;
  openModal: (type: EducationalBackgroundType, row?: EducationRecord) => void;
}

interface Props {
  actions: UseActions;
}

const CreateOrUpdateModal = forwardRef<CreateOrUpdateRef, Props>(
  ({ actions }, ref) => {
    const { teacherId } = useParams<{ teacherId: string }>();
    const texts = useTexts();

    const forms = useForm<ModalFormData>({
      mode: 'onChange',
      defaultValues,
    });

    const {
      control,
      watch,
      reset,
      setError,
      handleSubmit,
      formState: { isSubmitting },
    } = forms;

    const {
      append: appendUploadedFile,
      remove: removeUploadedFile,
      fields: uploadedFiles,
    } = useFieldArray({
      control,
      name: 'files',
    });

    const { data } = useEducationalBackgroundFormDataLoader();
    const { msgValidations } = useValidations();
    const [modal, setModal] = useState(false);
    const [openIteration, setOpenIteration] = useState(0);

    const [originInstituteValue, countryValue, currentType] = watch([
      'originInstitute',
      'country',
      'type',
    ]);

    const originInstituteOther = useMemo<SelectOptionType>(
      () => ({
        label: texts.form.originInstituteOther,
        value: null as any,
      }),
      [texts],
    );

    const originInstitutes = useMemo<SelectOptionType[]>(() => {
      const items = data?.originInstitutes ?? [];
      return items.concat([originInstituteOther]);
    }, [data?.originInstitutes, originInstituteOther]);

    const recognizedOptions = useMemo<SelectOptionType[]>(
      () => [
        { label: texts.form.isRecognized, value: true as any },
        { label: texts.form.isNotRecognized, value: false as any },
      ],
      [texts],
    );

    const requiresRecognition = useMemo(
      () =>
        !!(
          data?.defaultCountry !== countryValue?.value &&
          countryValue?.value &&
          currentType !== EducationalBackgroundType.Certificate
        ),
      [countryValue?.value, data?.defaultCountry, currentType],
    );

    const requiresInstitute = useMemo(
      () =>
        (!countryValue?.value ||
          data?.defaultCountry === countryValue?.value) &&
        currentType !== EducationalBackgroundType.Certificate,
      [countryValue?.value, data?.defaultCountry, currentType],
    );

    const requiresCustomInstitute = useMemo(
      () =>
        !requiresInstitute ||
        originInstituteOther?.value === originInstituteValue?.value,
      [
        requiresInstitute,
        originInstituteValue?.value,
        originInstituteOther?.value,
      ],
    );

    const requiredFiles = useMemo<MapedRequiredFile[]>(
      () =>
        data?.requiredFiles?.map((e) => ({
          ...e,
          required: e.required.includes(currentType),
          visible: true,
        })) ?? [],
      [currentType, data?.requiredFiles],
    );

    const openModal = useCallback(
      (type: EducationalBackgroundType, row?: EducationRecord) => {
        setOpenIteration((prev) => prev + 1);
        reset({
          ...educationRecordToForm({
            type,
            row,
            originInstituteOther,
            recognizedOptions,
          }),
        });
        setModal(true);
      },
      [reset, originInstituteOther, recognizedOptions],
    );

    const closeModal = useCallback(() => {
      reset({ ...defaultValues });
      setModal(false);
    }, [reset]);

    useImperativeHandle(ref, () => ({
      openModal: openModal,
      closeModal: closeModal,
    }));

    const onSubmit = async (values: ModalFormData) => {
      let hasExtraErrors = false;
      if (requiresInstitute && !values.originInstitute) {
        setError('originInstitute', { message: msgValidations.required });
        hasExtraErrors = true;
      } else if (!requiresInstitute) {
        values.originInstitute = null;
      }
      if (requiresCustomInstitute && !values.originInstituteCustom?.trim()) {
        setError('originInstituteCustom', { message: msgValidations.required });
        hasExtraErrors = true;
      } else if (!requiresCustomInstitute) {
        values.originInstituteCustom = null;
      }
      if (
        requiresRecognition &&
        ![true, false].includes(values.isRecognized?.value as any)
      ) {
        setError('isRecognized', { message: msgValidations.required });
        hasExtraErrors = true;
      } else if (!requiresRecognition) {
        values.isRecognized = null;
      }

      if (hasExtraErrors) {
        return;
      }

      for (const itemFile of requiredFiles?.filter((e) => e.required)) {
        const uploadedFile = values?.files?.find(
          (e) => e?.code === itemFile.code,
        );
        if (!uploadedFile || uploadedFile?.linkedFile?.lastModified === -1) {
          addToast({
            text: texts.form.linkedFileNameReq(itemFile.name),
            color: 'danger',
            icon: 'error',
          });
          return;
        }
      }

      const data: FormDataAction = {
        name: values.name,
        originInstituteCustom: values.originInstituteCustom?.trim() ?? null,
        dateOfIssue: values.dateOfIssue,
        studyCarriedOut: values?.studyCarriedOut,
        description: values?.description,
        originInstituteId: values.originInstitute?.value?.toString() ?? null,
        countryId: values.country?.value ? values.country?.value : undefined,
        isRecognized: !!(values.isRecognized?.value ?? false),
        degreeLevelId: values.degreeLevel?.value
          ? Number(values.degreeLevel?.value)
          : undefined,
        type: values.type,
        files: values.files ?? [],
      };

      if (values.id) {
        await actions.updateTitle(Number(values.id), data);
      } else {
        await actions.createTitle(Number(teacherId), data);
      }
      setModal(false);
    };

    return (
      <Modal isOpen={modal} toggle={closeModal} size="lg">
        <Container fluid>
          <h1 className="text-dark fs-20 mt-3 mb-4 text-center">
            {texts.modal[currentType]}
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormProvider {...forms}>
              <Row>
                <CreateOrUpdateModalForm
                  modalType={currentType}
                  requiresInstitute={requiresInstitute}
                  originInstitutes={originInstitutes}
                  requiresCustomInstitute={requiresCustomInstitute}
                  recognizedOptions={recognizedOptions}
                  requiresRecognition={requiresRecognition}
                />

                {!!(requiredFiles?.length && modal) && (
                  <CreateOrUpdateModalFormFiles
                    key={openIteration}
                    openIteration={openIteration}
                    requiredFiles={requiredFiles}
                    uploadedFiles={uploadedFiles}
                    removeUploadedFile={removeUploadedFile}
                    appendUploadedFile={appendUploadedFile}
                  />
                )}
                <Col md={12} xs={12} className="pb-5" />
                <hr className="py-5" />
                <Col xs={12} md={6}>
                  <Button
                    outlined
                    text={texts.actions.cancel}
                    fullwidth
                    onClick={() => {
                      reset();
                      setModal(false);
                    }}
                    className="mb-3"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Button
                    type="submit"
                    text={
                      currentType === EducationalBackgroundType.Certificate
                        ? texts.actions.add
                        : texts.actions.save
                    }
                    className="mb-3"
                    fullwidth
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  />
                </Col>
              </Row>
            </FormProvider>
          </form>
        </Container>
      </Modal>
    );
  },
);

export default CreateOrUpdateModal;
