import { ColumnTable, Icon } from '@octano/global-ui';
import { useTexts } from './textsContext';

interface actionsColumns {
  onClickEdit: (row: any) => void;
  onClickRemove: (row: any) => void;
}

export default function useColumnActions({
  onClickEdit,
  onClickRemove,
}: actionsColumns): ColumnTable[] {
  const texts = useTexts();
  return [
    {
      columnName: 'Acciones',
      headerText: texts.table.academicDegrees.columns.actions,
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ row }) => {
        return (
          <div className="d-flex justify-content-end">
            <button className="btn" onClick={() => onClickEdit(row)}>
              <Icon color="primary" name="edit" />
            </button>

            <button className="btn" onClick={() => onClickRemove(row)}>
              <Icon name="trash" color="primary" />
            </button>
          </div>
        );
      },
    },
  ];
}
