// Components
import { CheckInput } from '@octano/global-ui';
// Hooks
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { TranslationsKeys } from '../../../../locales/translations';

// Utils
import { FinantialSettingsContext } from '../contexts/finantial-settings.context';
// Types
import { FinantialConfig } from '../interfaces/finantial-config.interface';

const prefix = 'tuitionPayment';

// Render
export default function TuitionPaymentSection() {
  const { t } = useTranslation(TranslationsKeys.CONFIGURATION_FINANTIAL);

  const finantialSettingsContext = useContext(FinantialSettingsContext);

  const { control } = useFormContext<FinantialConfig>();

  return (
    <Row className="mt-4">
      <Col xs={12}>
        <h2 className="text-primary fs-16 fw-600 text-uppercase mb-4">
          {t(`${prefix}.title`)}
        </h2>
      </Col>
      <Col sm={12}>
        <CheckInput
          control={control}
          disabled={finantialSettingsContext.disabled}
          name="chargeOnlineTuition"
          label={t(`${prefix}.inputs.chargeOnlineTuition`)}
        />
      </Col>
      <Col sm={12}>
        <CheckInput
          control={control}
          disabled={finantialSettingsContext.disabled}
          name="forceFirstInstallmentOnTuitionFree"
          label={t(`${prefix}.inputs.forceFirstInstallmentOnTuitionFree`)}
        />
      </Col>
    </Row>
  );
}
