import { useCallback, useContext } from 'react';

import {
  getCountriesRequest,
  getCourseTypesRequest,
  getIntitutionTypesRequest,
} from '../api/requests/parameters';
import { getRegionsAndCommunesRequest } from '../api/requests/regionsAndCommunes';
import { getRoles } from '../api/requests/roles';
import { ParametersContext } from '../providers/ParametersProvider';
import { ParametersActionType } from '../types/parametersTypes';
import { formatOptions } from '../utils/selectFormat';

/**
 * Provee el estado y el carga de los parametros (opciones de selects)
 * utilizados en los formularios
 */
export const useParameters = () => {
  const { state, dispatch } = useContext(ParametersContext);

  const loadRoles = useCallback(async () => {
    const { data } = await getRoles();
    if (data && data.length > 0) {
      const roles = data.map((row) => {
        return {
          value: row.id,
          label: row.name,
          description: row.description,
        };
      });
      dispatch({
        type: ParametersActionType.SET_ROLES,
        payload: roles,
      });
    }
  }, [dispatch]);

  const loadCountries = useCallback(async () => {
    const { data } = await getCountriesRequest();
    if (data && data.length > 0) {
      const nationalities = data.map((row) => {
        return { value: row.id, label: row.nationality };
      });
      dispatch({
        type: ParametersActionType.SET_COUNTRIES,
        payload: formatOptions(data),
      });
      dispatch({
        type: ParametersActionType.SET_NATIONALITIES,
        payload: nationalities,
      });
    }
  }, [dispatch]);

  const loadRegionsAndCommunes = useCallback(async () => {
    const { data } = await getRegionsAndCommunesRequest();
    if (data) {
      const regions = data.regions.map((row) => {
        return { value: row.id, label: row.name };
      });
      const communes = data.communes.map((row) => {
        return { value: row.id, label: row.name, regionId: row.regionId };
      });
      const cities = data.cities.map((row) => {
        return { value: row.id, label: row.name, regionId: row.regionId };
      });
      dispatch({
        type: ParametersActionType.SET_REGIONS,
        payload: regions,
      });
      dispatch({
        type: ParametersActionType.SET_COMMUNES,
        payload: communes,
      });
      dispatch({
        type: ParametersActionType.SET_CITIES,
        payload: cities,
      });
    }
  }, [dispatch]);

  const loadInstitutionTypes = useCallback(async () => {
    const { data } = await getIntitutionTypesRequest();
    if (data) {
      const institutionTypes = data.data.map((row) => {
        return { value: row.id, label: row.name };
      });
      dispatch({
        type: ParametersActionType.SET_INSTITUTION_TYPES,
        payload: institutionTypes,
      });
    }
  }, [dispatch]);

  const loadCourseTypes = useCallback(async () => {
    const { data } = await getCourseTypesRequest();
    if (data) {
      const courseTypes = data.map((row) => {
        return {
          value: row.id,
          label: row.name,
          generalTypeId: row.generalTypeId,
        };
      });
      dispatch({
        type: ParametersActionType.SET_COURSE_TYPES,
        payload: courseTypes,
      });
    }
  }, [dispatch]);

  return {
    ...state,
    loadRoles,
    loadCountries,
    loadRegionsAndCommunes,
    loadInstitutionTypes,
    loadCourseTypes,
  };
};
