import { addToast, Button } from '@octano/global-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';
import { TranslationsKeys } from '../../../../locales/translations';
import { finantialOthersUpdateRequest } from '../api';
import { useFinantialOthersState } from '../hooks/useFinantialOthersState';
import FinantialOtherCertificates from './FinantialOtherCertificates';
import FinantialOtherPetitions from './FinantialOtherPetitions';
import { useFinantialOthersLoader } from './FinantialOthersLoader';

function FinantialOthersContent() {
  const { t } = useTranslation(
    TranslationsKeys.CONFIGURATION_FIANANTIAL_OTHERS,
  );
  const { certificateTypesState, petitionTypesState } =
    useFinantialOthersState();
  const { refresh } = useFinantialOthersLoader();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    const { error } = await finantialOthersUpdateRequest({
      certificateTypes: certificateTypesState,
      petitionTypes: petitionTypesState,
    });
    if (!error) {
      addToast({
        icon: 'success',
        color: 'success',
        text: t('onSaveSuccess'),
      });
      refresh();
    } else
      addToast({
        icon: 'error',
        color: 'danger',
        text: t('onSaveError'),
      });
    setLoading(false);
  };

  return (
    <Card className="p-4">
      <Row>
        <Col sm={12}>
          <h1 className="text-primary fs-24 fw-600 text-uppercase">
            {t('title')}
          </h1>
          <p className="fs-14 text-medium mb-3">{t('description')}</p>
        </Col>
        <Col sm={12}>
          <FinantialOtherCertificates />
        </Col>
        <Col sm={12}>
          <FinantialOtherPetitions />
        </Col>
        <Col sm={12}>
          <Button
            type="submit"
            text={t('save')}
            size="lg"
            className="float-right mt-5"
            onClick={onSubmit}
            disabled={loading}
            loading={loading}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default FinantialOthersContent;
