import { FileInput } from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useTexts } from '../../hooks/textsContext';

import {
  EducationRecordFile,
  TeacherEducationalBackgroundSettingInterface,
} from '../../type';

export interface MapedRequiredFile
  extends Omit<
    TeacherEducationalBackgroundSettingInterface,
    'required' | 'visible'
  > {
  required: boolean;
  visible: boolean;
}
interface Props {
  data: MapedRequiredFile;
  openIteration: number;
  uploadedFiles?: EducationRecordFile<File>[];
  onChange?: (requiredFile: MapedRequiredFile, linkedFile?: File) => void;
}

const CreateOrUpdateModalFormFile = ({
  openIteration,
  data,
  uploadedFiles,
  onChange,
}: Props) => {
  const texts = useTexts();

  const value = useMemo(
    () => uploadedFiles?.find((f) => f?.code === data?.code),
    [data?.code, uploadedFiles],
  );

  const handleDownloadFile = useCallback(() => {
    if (!value?.linkedFile) {
      return;
    }
    if (value?.linkedFile?.lastModified === -1 && !value?.fileUrl) {
      return;
    }

    if (value?.linkedFile?.lastModified === -1) {
      const link = document.createElement('a');
      link.download = value?.linkedFile.name;
      link.href = value.fileUrl ?? '';
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return;
    } else {
      const url = URL.createObjectURL(value?.linkedFile);
      const a = document.createElement('a');
      a.href = url;
      a.download = value?.linkedFile.name;
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  }, [value?.linkedFile, value?.fileUrl]);

  return (
    <FileInput
      key={`teacherFile__${data.code}__${openIteration}`}
      name={`teacherFile__${data.code}__${openIteration}`}
      label={texts.form.linkedFileName(data.name, data.required)}
      maxFiles={1}
      btnText={texts.form.linkedFile}
      value={value?.linkedFile}
      onChange={(e) => !!onChange && onChange(data, e)}
      accept={
        'image/png, image/jpeg, application/pdf, application/xls, application/xlsx, application/docx'
      }
      onDownloadFile={handleDownloadFile}
    />
  );
};

export default CreateOrUpdateModalFormFile;
