export interface AdmissionsStudent {
  id: number;
  rut: string;
  names: string;
  lastnames: string;
  phone: string;
  email: string;
  dateEnrolled?: string;
  dateRetracted?: string;
}

export interface TableColumns {
  width?: string;
  bold?: boolean;
  headerText: string;
  columnName: string;
  [key: string]: any;
}

export interface Discount {
  id?: number;
  code: string;
  name: string;
  description: string | null;
  percentage: number;
}

export enum DiscountTypes {
  Tuition = 'tuition',
  Tariff = 'tariff',
}

export interface PostulationDetailDiscount {
  id?: number;
  discountName: string;
  discountCode: string;
  discountValue: number;
  amountWithDiscount: number;
  createdAt?: string;
  updatedAt?: string;
  postulationDetailId: number;
  type: DiscountTypes;
}

export interface AdmissionManagementFindItem {
  postulationDetailId: number;
  postulationId: number;
  postulantId: number;
  postulantIdentificationType: string;
  postulantIdentificationValue: string;
  postulantName: string;
  postulantFistname: string;
  postulantLastname: string;
  postulantPhone: string;
  postulantEmail: string;
  posutlationDetailDisounts: PostulationDetailDiscount[];
  posutlationDetailEnrolledDate: string | null;
  posutlationDetailRetractedDate: string | null;
}
