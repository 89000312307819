import { addToast, Button, ColumnTable } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUserState from '../../../hooks/useUserState';
import { PermissionName } from '../../../types/Auth';
import { getStatusStudyPlanChange } from '../../../views/AdmissionAndEnrollments/AdmissionManagement/api';
import { getAdmissionColumnsStudent } from '../../getAdmissionColumns';
import { AdmissionManagementFindItem } from '../../types';
import { useValidateRetracted } from '../hooks/useValidateRetracted';

export const useColumnsAdmissionListEnrolled = () => {
  const { t } = useTranslation();
  const [changeStudyPlan, setChangeStudyPlan] = useState<{
    detailId: number;
    activeProcess: number | null;
  }>();
  const { isAuthorizedTo } = useUserState();
  const isDisabled = !isAuthorizedTo([
    PermissionName.ADMISSIONS_AND_ENROLLMENTS_TUITION_PROCESS_CHANGE_STATUS,
  ]);

  const {
    validateRetracted,
    studentName,
    selectRetractedID,
    setSelectRetractedID,
    showRetractedModal,
    setShowRetractedModal,
  } = useValidateRetracted();

  const validateStudyPlanChange = useCallback(
    async (detailId: number) => {
      const { data, error } = await getStatusStudyPlanChange(detailId);
      if (data) {
        setChangeStudyPlan({
          detailId,
          activeProcess: data.studyPlanChangeDetail
            ? data.studyPlanChangeDetail.id
            : null,
        });
      }
      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t('common.displayError.title'),
        });
      }
    },
    [t],
  );

  const columns: ColumnTable<AdmissionManagementFindItem>[] = useMemo(() => {
    return [
      ...getAdmissionColumnsStudent(t),
      {
        columnName: 'dateEnrolled',
        headerText: t(`common.forms.dateEnrolled`),
        width: '10%',
        cellFormat: ({ row }) =>
          row.posutlationDetailEnrolledDate
            ? dayjs(row.posutlationDetailEnrolledDate).format(
                'DD/MM/YYYY HH:mm',
              )
            : '',
      },
      {
        columnName: 'action',
        headerText: '',
        width: '10%',
        cellFormat: ({ row }) => {
          return (
            <div className="d-flex justify-content-center">
              <Button
                text={t('admissions.retracted')}
                size="sm"
                outlined
                color="danger"
                onClick={() =>
                  isDisabled
                    ? () => {}
                    : validateRetracted(
                        row.postulationDetailId,
                        row.postulantFistname,
                        row.postulantLastname,
                      )
                }
                disabled={isDisabled}
              />
              <Button
                className="ml-2"
                text={t('admissions.changeStudyPlan')}
                size="sm"
                onClick={() =>
                  isDisabled
                    ? () => {}
                    : validateStudyPlanChange(row.postulationDetailId)
                }
                disabled={isDisabled}
              />
            </div>
          );
        },
      },
    ];
  }, [t, validateRetracted, validateStudyPlanChange, isDisabled]);

  return {
    columns,
    studentName,
    selectRetractedID,
    setSelectRetractedID,
    changeStudyPlan,
    setChangeStudyPlan,
    showRetractedModal,
    setShowRetractedModal,
  };
};
