import { ReactElement, useCallback } from 'react';
import { getIdentificationTypeConfig } from '../../../../api/requests/teacherActivation';
import { createFetchContext } from '../../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { IdentificationTypeResponse } from '../types';

interface IdentificationTypeLoaderProps {
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  IdentificationTypeResponse
>();

export function useIdentificationTypeLoader() {
  return useFetch();
}

export const BaseLoaderConsumer = FetchConsumer;

export default function IdentificationTypeLoader({
  children,
}: IdentificationTypeLoaderProps) {
  const request = useCallback(() => {
    return getIdentificationTypeConfig();
  }, []);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return (
              <div className="mx-3">
                <Loading insideCard />
              </div>
            );
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children;
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
