import { SelectOptionType } from '@octano/global-ui';
interface DegreeLevels {
  id: number;
  name: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

interface LinkedFile {
  id: number;
  name: string;
  extension: string;
  type: string;
  uploadedById: number;
  fileUrl?: string;
}
export interface EducationRecordFile<T = LinkedFile> {
  code: string;
  fileUrl?: string;
  linkedFile: T;
}

export interface EducationRecord {
  id: number;
  teacherId: number;
  name: string;
  dateOfIssue: string;
  description: string;
  originInstituteCustom: string | null;
  isRecognized: boolean;
  originInstituteId: string | null;
  originInstitute?: { name: string; id: string };
  studyCarriedOut: string;
  country: SelectOptionType;
  degreeLevel: DegreeLevels;
  files: EducationRecordFile[];
  type: string;
}

export interface EducationalBackgroundResponse {
  academicDegrees: EducationRecord[];
  specialties: EducationRecord[];
  certificates: EducationRecord[];
}

export interface TeacherEducationalBackgroundSettingInterface {
  code: string;
  name: string;
  required: EducationalBackgroundType[];
  visible: EducationalBackgroundType[];
}
export interface EducationalBackgroundFormDataResponse {
  defaultCountry?: string;
  requiredFiles: TeacherEducationalBackgroundSettingInterface[];
  degreeLevels: SelectOptionType[];
  specialtyLevels: SelectOptionType[];
  countries: SelectOptionType[];
  originInstitutes: SelectOptionType[];
}

export enum EducationalBackgroundType {
  AcademicDegree = 'grados',
  Specialty = 'especialidades',
  Certificate = 'certificados',
}

export interface FormDataAction {
  name: string;
  originInstituteCustom: string | null;
  originInstituteId: string | null;
  dateOfIssue: Date;
  studyCarriedOut: string;
  description: string;
  countryId: string | number | undefined;
  isRecognized: boolean;
  degreeLevelId: number | undefined;
  files: EducationRecordFile<File>[];
  type: string;
}
