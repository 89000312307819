import { PaginationRequestType } from '../../types/paginationRequestType';

/**
 * TO DO:
 * - Revisar bien los Tipos Buscar si hay redundanciaen otros archivos
 *   y sumplificarlos
 */

export enum DiscountTypes {
  Tuition = 'tuition',
  Tariff = 'tariff',
}

interface Contact {
  cellPhone: string;
  email: string;
}

export const DiscountTexts = {
  [DiscountTypes.Tariff]: 'Arancel',
  [DiscountTypes.Tuition]: 'Matrícula',
} as const;

interface PostulationDetailDiscount {
  id: number;
  discountCode: string;
  discountName: string;
  discountValue: number;
  type: DiscountTypes;
}

interface PostulationDetail {
  id: number;
  admissionId: number;
  status: string;
  postulationId: number;
  enrolledDate: string | null;
  retractedDate: string | null;
  createdAt: string;
  postulationDetailDiscount: PostulationDetailDiscount[];
}

interface Postulation {
  id: number;
  details: PostulationDetail[];
}

interface Passport {
  number: string;
  countryId: string;
}

export interface Postulant {
  id: number;
  rut: string | null;
  names: string;
  paternalLastName: string;
  maternalLastName: string;
  paternalPreference: boolean;
  fullName: string;
  email: string;
  birthday: string;
  sexId: number | null;
  maritalStatusId: number | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  contact: Contact;
  passports: Passport[];
  postulations: Postulation[];
  lastPassport?: Passport;
}

export type StudyPlanOffertResponse<T = Postulant> = PaginationRequestType<T>;

export type StudyPlanOffertOnlyOneResponse = {
  data: Omit<Postulant, 'id'>;
};

export type Discount = {
  postulationDetailId: number;
  discountCode: string;
  discountName: string;
  discountValue: number;
  id: number;
  createdAt: string;
  updatedAt: string;
};

export interface StudyPlansOfferDocumentationReview {
  StudyPlanVersionOffer_id: number;
  StudyPlanVersion_name: string;
  AcademicOfferStudyPlanVersionOffers_id: number;
  Admissions_id: number;
  Campus_name: string;
  Schedule_name: string;
  enrolled: number;
  notReviewed: number;
  waiting: number;
  approved: number;
  approvedPercentage: number;
  StudyPlan_name: string;
}

export interface AcademicOfferStudyPlansOfferDocumentationByStatus {
  Postulant_id: number;
  Postulant_rut?: string;
  Postulant_names: string;
  Postulant_paternalLastName: string;
  Postulant_maternalLastName: string;
  Postulant_paternalPreference: boolean;
  Postulant_birthday: string;
  Contact_cellPhone: string;
  Contact_email: string;
  PostulantPassport_number?: string;
  PostulantPassport_countryId?: string;
  Details_id: number;
}

export interface SearchStudyPlansOfferDocumentationByStatus
  extends AcademicOfferStudyPlansOfferDocumentationByStatus {
  tab: DocumentationReviewStatus;
}

export interface StudyPlansOfferCampusSchedule {
  StudyPlanVersionOffer_id: number;
  StudyPlanVersion_name: string;
  Campus_name: string;
  Schedule_name: string;
  StudyPlan_name: string;
}

export interface PostulantDiscounts {
  id?: number;
  discountName: string;
  discountCode: string;
  discountValue: number;
  amountWithDiscount: number;
  type: DiscountTypes;
  postulationDetailId: number;
}

export enum DocumentationReviewStatus {
  NOT_REVIEWED = 'sin_revision',
  WAITING = 'en_espera',
  APPROVED = 'aprobado',
}
