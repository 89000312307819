import { Table } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalConfirmation } from '../../../../../../../components/modals/Confirmation';
import { useCreateEvaluationFormContext } from '../../context/CreateEvaluationFormContext';
import useSubEvaluationGradeColumns from '../../hooks/useSubEvaluationColumns';
import TotalPercentageFooter from '../TotalPercentageFooter';
import {
  EvaluationType,
  PartialEvaluation,
  PartialEvaluationSubevaluations,
} from '../../types';
import sumPercentage from '../../utils/sumPercentage';
import { TranslationsKeys } from '../../../../../../../locales/translations';

export interface SubevaluationsTableProps {
  partialEvaluation: PartialEvaluationSubevaluations;
  partialEvaluationIndex: number;
}

const MIN_SUBEVALUATIONS_LENGTH = 2;

export default function SubEvaluationsTable(props: SubevaluationsTableProps) {
  const { partialEvaluation, partialEvaluationIndex } = props;
  const { t } = useTranslation(
    TranslationsKeys.CONFIGURATION_GRADING_STRUCTURE,
  );

  const {
    gradeEntry: { testGrades: partialEvaluations },
    onChangePartialEvaluationGradeEntry,
    handleError: { partialEvaluationGradeEntry: error },
  } = useCreateEvaluationFormContext();

  // Callbacks
  const onChange = useCallback(() => {
    onChangePartialEvaluationGradeEntry([...partialEvaluations]);
  }, [onChangePartialEvaluationGradeEntry, partialEvaluations]);

  // Crud operations - Evaluations
  const deletePartialEvaluationByIndex = useCallback(
    (row: { canItBeRemoved: boolean }, data?: PartialEvaluation[]) => {
      return () => {
        if (!data) {
          return;
        }
        // if (row.canItBeRemoved) {
        if (data?.length === MIN_SUBEVALUATIONS_LENGTH) {
          setModalConfirm(true);
        } else {
          const index = data.findIndex((g) => g === row);
          if (index >= 0) {
            data.splice(index, 1);
            partialEvaluation.grades = data;
            onChange();
          }
        }
        // }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partialEvaluation, onChange],
  );
  const changeToPartialEvaluation = useCallback(() => {
    const { grades, ...data } = partialEvaluation;
    partialEvaluations[partialEvaluationIndex] = {
      ...data,
      type: EvaluationType.Normal,
    };
    onChange();
  }, [partialEvaluation, partialEvaluations, partialEvaluationIndex, onChange]);

  // Modal
  const [modalConfirm, setModalConfirm] = useState(false);
  const onConfirm = () => {
    changeToPartialEvaluation();
    setModalConfirm(false);
  };
  const onClose = () => {
    setModalConfirm(false);
  };

  // Hooks
  const columnsSubEvaluations = useSubEvaluationGradeColumns({
    onChange,
    error,
    partialEvaluation,
    partialEvaluationIndex,
    deletePartialEvaluationByIndex: (row, data) => {
      return deletePartialEvaluationByIndex(row, data);
    },
  });

  const percentage = sumPercentage(partialEvaluation.grades);
  const percentageValid = percentage === 100;

  return (
    <>
      <Table
        columns={columnsSubEvaluations}
        data={partialEvaluation.grades ?? []}
        striped={false}
        notResponsive
        noDefaultHead
        noDefaultFoot
        footComponent={({ columns, data }) => (
          <TotalPercentageFooter
            dataLength={data.length}
            columnsLength={columns.length}
            error={error}
            percentageValid={percentageValid}
            percentage={percentage}
            isSecondaryFooter
          />
        )}
      />
      {error && !percentageValid && (
        <p className="text-danger mt-2">
          {t('form.msg.msgErrorTables.totalErrorOfTable')}
        </p>
      )}
      {error && partialEvaluation.grades.length < MIN_SUBEVALUATIONS_LENGTH && (
        <p className="text-danger mt-2">
          {t('form.msg.msgErrorTables.totalCountOfTableSubevaluations')}
        </p>
      )}
      <ModalConfirmation
        open={modalConfirm}
        title={'¿Deseas convertir a evaluación simple?'}
        subtitle={
          'Si eliminas estas subevaluación, la evaluación pasará a ser una evaluación simple.'
        }
        onConfirm={onConfirm}
        onClose={onClose}
      />
    </>
  );
}
