import React, { useContext, useState } from 'react';
import { useFinantialOthersLoader } from '../parts/FinantialOthersLoader';
import { CertificateType, PetitionType } from '../type';

interface IFinantialOthersStateContext {
  certificateTypesState: CertificateType[];
  setCertificateTypesState: React.Dispatch<
    React.SetStateAction<CertificateType[]>
  >;
  petitionTypesState: PetitionType[];
  setPetitionTypesState: React.Dispatch<React.SetStateAction<PetitionType[]>>;
}

const FinantialOthersStateContext = React.createContext<
  IFinantialOthersStateContext | undefined
>(undefined);

export const FinantialOtherStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data } = useFinantialOthersLoader();

  const [certificateTypesState, setCertificateTypesState] = useState<
    CertificateType[]
  >(data?.certificateTypes ?? []);
  const [petitionTypesState, setPetitionTypesState] = useState<PetitionType[]>(
    data?.petitionTypes ?? [],
  );

  return (
    <FinantialOthersStateContext.Provider
      value={{
        certificateTypesState,
        setCertificateTypesState,
        petitionTypesState,
        setPetitionTypesState,
      }}
    >
      {children}
    </FinantialOthersStateContext.Provider>
  );
};

export const useFinantialOthersState = () => {
  const context = useContext(FinantialOthersStateContext);
  if (!context) {
    throw new Error('usePermissions must be used within a PermissionProvider');
  }
  return context;
};
