import { ColumnTable } from '@octano/global-ui';
import { formatDate } from '../../../../../../utils/dates';
import { useTexts } from './textsContext';

export default function useColumnsOthers(
  actionsColumns: ColumnTable[],
  defaultCountry?: string,
): ColumnTable[] {
  const texts = useTexts();
  return [
    {
      columnName: 'degreeLevel',
      headerText: texts.table.academicDegrees.columns.degreeLevel,
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (options) => options.row?.degreeLevel?.name,
    },
    {
      columnName: 'studyCarriedOut',
      headerText: texts.table.academicDegrees.columns.studyCarriedOut,
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'name',
      headerText: texts.table.academicDegrees.columns.name,
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'description',
      headerText: texts.table.academicDegrees.columns.description,
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'country',
      headerText: texts.table.academicDegrees.columns.country,
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (options) => options.row?.country?.name,
    },
    {
      columnName: 'originInstituteCustom',
      headerText: texts.table.academicDegrees.columns.originInstitute,
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ value, row }) =>
        value?.trim() ?? row?.originInstitute?.name?.trim() ?? '-',
    },
    {
      columnName: 'isRecognized',
      headerText: texts.table.academicDegrees.columns.isRecognizedLabel,
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ value, row }) =>
        defaultCountry !== row?.countryId
          ? value
            ? texts.form.isRecognized
            : texts.form.isNotRecognized
          : texts.form.isNotNeededRecognized,
    },
    {
      columnName: 'dateOfIssue',
      headerText: texts.table.academicDegrees.columns.dateOfIssue,
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (options) =>
        formatDate(options.row?.dateOfIssue, 'DD/MM/YYYY'),
    },

    ...actionsColumns,
  ];
}
