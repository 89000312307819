import { SelectOptionType } from '@octano/global-ui';
import { CoursesFeaturesOptions } from '../../Configurations/academics/interfaces/courses-features.interface';

type Base = {
  id: number;
  name: string;
};

export enum CourseFormMode {
  WATCH = 'WATCH',
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
}

export type CourseFormData = Partial<{
  schools: SelectOptionType[];
  academicLevels: SelectOptionType[];
  activities: SelectOptionType[];
  modalities: SelectOptionType[];
  studyPlanVersions: SelectOptionType[];
  courseTypes: SelectOptionType[];
  replaceCourseGradeOptions: SelectOptionType[];
  coursesFeatures?: CoursesFeaturesOptions | null;
}>;

export type CourseSyllabus = {
  id: number;
  name: string;
  extension: string;
  url?: string;
};

export type CourseAttendance = {
  minPercentage: number;
  activityTypeId: number;
};

export type CourseAssistanceRequirements = CourseAttendance & {
  id: number;
  courseId: number;
  createdAt: string;
  updatedAt: string;
};

export type Course = {
  id: number;
  shortening: string;
  code: string;
  name: string;
  credits: number;
  schoolId: number;
  academicLevelId: number;
  modalityId: number | null;
  replaceCourseGradeAllowed: boolean;
  replaceEvaluationGradeAllowed: boolean;
  semesterCourses?: any[];
  sections?: any[];
  types: Base[];
  assistanceRequirements: CourseAssistanceRequirements[];
  compatibilities: CourseCompatibility[];
  dependsOn: CoursePrerequisite[];
  syllabus: CourseSyllabus | null;
  createdAt: string;
  updatedAt: string;
  courseLearningResults: CourseLearningResult[];
};

export type CourseCompatibility = {
  id: number;
  shortening: string;
  name: string;
  credits: number;
};

export type CoursePrerequisite = {
  id: number;
  shortening: string;
  name: string;
  credits: number;
};

export type CourseLearningResult = {
  id?: number;
  code?: string;
  description?: string;
  indexOnTable?: number;
};

export type CourseFormFields = {
  // CourseDetailsSection
  shortening: Course['shortening'];
  code: Course['code'];
  name: Course['name'];
  credits: string;
  school: SelectOptionType | null | undefined;
  academicLevel: SelectOptionType | null | undefined;
  modality: SelectOptionType | null | undefined;
  replaceCourseGradeAllowed: SelectOptionType | null | undefined;
  replaceEvaluationGradeAllowed: SelectOptionType | null | undefined;
  // CourseTypeSection
  courseTypes: number[];
  // CourseActivityTypeSection
  attendance: CourseAttendance[];
  // CourseSyllabusSection
  syllabusFile: File | null;
  // CourseCompatibilitiesSection
  compatibilities: CourseCompatibility[];
  // CoursePrerequisitesSection
  prerequisites: CoursePrerequisite[];
  // CourseFooterSection
  learningResults: CourseLearningResult[];
};

export enum RetakeAllowedCodes {
  Enabled = 'enabled',
  Disabled = 'disabled',
}
