import { Card, Col, Row } from 'reactstrap';
import { Button, addToast } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useCallback, useRef, useState } from 'react';

import NotesScaleSection from './parts/NotesScaleSection';
import TimeVariableSection from './parts/TimeVariableSection';
import CoursesFeatures from './parts/CoursesFeatures';
import QualitativeAssessmentRangeSection from './parts/QualitativeAssessmentRangeSection';
import { CommonSectionMethods } from './interfaces/common-section';
import { TranslationsKeys } from '../../../locales/translations';

export default function Academics() {
  const { t } = useTranslation(TranslationsKeys.CONFIGURATION_ACADEMIC);

  const notesScaleSectionRef = useRef<CommonSectionMethods>(null);
  const qualitativeAssessmentRangeSectionRef =
    useRef<CommonSectionMethods>(null);
  const timeVariableSectionRef = useRef<CommonSectionMethods>(null);
  const coursesFeaturesRef = useRef<CommonSectionMethods>(null);

  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = useCallback(async () => {
    if (saving) {
      return;
    }

    try {
      setSaving(true);
      await Promise.all([
        notesScaleSectionRef?.current?.validate(),
        qualitativeAssessmentRangeSectionRef?.current?.validate(),
        timeVariableSectionRef?.current?.validate(),
        coursesFeaturesRef?.current?.validate(),
      ]);
      await Promise.all([
        notesScaleSectionRef?.current?.save(),
        qualitativeAssessmentRangeSectionRef?.current?.save(),
        timeVariableSectionRef?.current?.save(),
        coursesFeaturesRef?.current?.save(),
      ]);
      addToast({
        text: t(`savedChanges`),
        color: 'success',
        icon: 'success',
      });
      setSaving(false);
    } catch (_e) {
      setSaving(false);
    }
  }, [saving, t]);

  return (
    <Card className="p-4">
      <Row>
        <Col lg={6} md={6} sm={12}>
          <NotesScaleSection ref={notesScaleSectionRef} />
          <QualitativeAssessmentRangeSection
            ref={qualitativeAssessmentRangeSectionRef}
          />
        </Col>
        <Col lg={6} md={6} sm={12}>
          <TimeVariableSection ref={timeVariableSectionRef} />
          <CoursesFeatures ref={coursesFeaturesRef} />
        </Col>
        <Col sm={12}>
          <Button
            type="button"
            text={t(`common:actions.save`)}
            size="md"
            loading={saving}
            className="float-right mt-5"
            onClick={handleSave}
          />
        </Col>
      </Row>
    </Card>
  );
}
