import {
  Button,
  Modal,
  OutlinedSelect,
  OutlinedSelectOptionType,
  useMobile,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { Period } from '../../../api/requests/MantainerPortal';
import {
  getStudyPlanVersionOFPortal,
  GetStudyPlanVersionOFPortalResponse,
  StudyPlanVersionSelect,
} from '../../../api/requests/reports';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { getFormData } from '../api';
import LoadingInputsModal from './LoadingInputsModal';
import NoResultsModal from './NoResultsModal';

interface Props {
  isOpen: boolean;
  modalTitle: string;
  onConfirm?: (studyPlanVersionId: number, periodId: number) => void;
  onCancel?: () => void;
}

export default function SelectStudyPlanVersionAndPeriodModal({
  isOpen,
  modalTitle,
  onConfirm = () => null,
  onCancel = () => null,
}: Props) {
  const prefix = 'reports.learningResult';

  const isMobile = useMobile();
  const { t } = useTranslation();

  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();

  const [studyPlanVersions, setStudyPlanVersions] = useState<
    GetStudyPlanVersionOFPortalResponse['studyPlanVersionList']
  >([]);
  const [periods, setPeriods] = useState<Period[]>([]);

  const [currentPeriod, setCurrentPeriod] = useState<Period>();
  const [
    currentStudyPlanVersion,
    setCurrentStudyPlanVersion,
  ] = useState<StudyPlanVersionSelect>();

  const getPeriods = useCallback(async () => {
    const { data, error } = await getFormData();
    if (error) {
      setErrorLoading(error.code);
      throw new Error(error.code);
    } else if (data) {
      setPeriods(data.periods);
      setCurrentPeriod(data.currentPeriod);
    }
  }, [setErrorLoading]);

  const getStudyPlanVersions = useCallback(async () => {
    const { data, error } = await getStudyPlanVersionOFPortal();
    if (error) {
      setErrorLoading(error.code);
      throw new Error(error.code);
    } else if (data) {
      setStudyPlanVersions(data.studyPlanVersionList);
    }
  }, [setErrorLoading]);

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      await Promise.all([getStudyPlanVersions(), getPeriods()]);
      setErrorLoading(undefined);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [getStudyPlanVersions, setErrorLoading, setLoading, getPeriods]);

  const changeStudyPlanVersionSelected = useCallback(
    (option: OutlinedSelectOptionType) => {
      const studyPlanVersion = studyPlanVersions.find(
        ({ id }) => option.value === id,
      ) as StudyPlanVersionSelect;
      setCurrentStudyPlanVersion(studyPlanVersion);
    },
    [studyPlanVersions],
  );

  const changePeriod = useCallback(
    (option: OutlinedSelectOptionType) => {
      const period = periods.find(({ id }) => option.value === id) as Period;
      setCurrentPeriod(period);
    },
    [periods],
  );

  useEffect(() => {
    if (isOpen) getData();
  }, [getData, isOpen]);

  const optionsStudyPlanVersions = useMemo<OutlinedSelectOptionType[]>(() => {
    return studyPlanVersions.map(({ name, id }) => {
      return {
        label: name,
        value: id,
      };
    });
  }, [studyPlanVersions]);

  const studyPlanVersionSelected = useMemo(() => {
    return optionsStudyPlanVersions.find(
      ({ value }) => currentStudyPlanVersion?.id === value,
    );
  }, [optionsStudyPlanVersions, currentStudyPlanVersion]);

  const optionsPeriods = useMemo<OutlinedSelectOptionType[]>(() => {
    return periods.map(({ name, id }) => {
      return {
        label: name,
        value: id,
      };
    });
  }, [periods]);

  const periodSelected = useMemo(() => {
    return optionsPeriods.find(({ value }) => currentPeriod?.id === value);
  }, [optionsPeriods, currentPeriod]);

  if (loading && isOpen) {
    return <LoadingInputsModal isOpen />;
  }

  if (errorLoading && isOpen) {
    return (
      <NoResultsModal
        isOpen
        onConfirm={onCancel}
        texts={{
          title: t(`${prefix}.noResults.title`),
          description: t(`${prefix}.noResults.description`),
        }}
      />
    );
  }

  return (
    <Modal isOpen={isOpen} toggle={() => null}>
      <div>
        <h1 className="text-dark text-center fw-600 fs-20">{modalTitle}</h1>
        <p className=" text-center fs-16 lh-30 mt-4 mb-5">
          {t(`${prefix}.datesConfig.subtitle`)}
        </p>
        <Row>
          <Col xs={12}>
            <OutlinedSelect
              name="periodId"
              label={t(`${prefix}.form.period`)}
              value={periodSelected}
              options={optionsPeriods}
              isClearable={false}
              onChange={changePeriod}
            />
          </Col>
          <Col xs={12}>
            <OutlinedSelect
              name="studyPlanVersionId"
              label={t(`${prefix}.form.studyPlanVersion`)}
              value={studyPlanVersionSelected}
              options={optionsStudyPlanVersions}
              isClearable={false}
              onChange={changeStudyPlanVersionSelected}
            />
          </Col>
        </Row>
        <div
          className="d-flex flex-wrap justify-content-between mt-5"
          style={{ gap: '1rem' }}
        >
          <Button
            text={t(`common:actions.cancel`)}
            outlined
            style={{ width: isMobile ? '100%' : 197 }}
            onClick={onCancel}
          />
          <Button
            text={t(`common:actions.download`)}
            style={{ width: isMobile ? '100%' : 197 }}
            disabled={
              !studyPlanVersionSelected?.value || !periodSelected?.value
            }
            onClick={() =>
              onConfirm(
                studyPlanVersionSelected!.value as number,
                periodSelected!.value as number,
              )
            }
          />
        </div>
      </div>
    </Modal>
  );
}
