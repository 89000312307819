import { FinantialOtherStateProvider } from './hooks/useFinantialOthersState';
import FinantialOthersContent from './parts/FinantialOthersContent';
import FinantialOthersLoader from './parts/FinantialOthersLoader';

// Render
export default function FinantialOthers() {
  return (
    <FinantialOthersLoader>
      <FinantialOtherStateProvider>
        <FinantialOthersContent />
      </FinantialOtherStateProvider>
    </FinantialOthersLoader>
  );
}
