export interface StudentPetitionsRow {
  folio: number;
  createdAt: Date;
  studyPlanVersionName: string;
  typeId: number;
  typeName: string;
  status: PetitionStatus;
  paymentStatus: PetitionPaymentStatus | null;
  paymentRequired: boolean;
  title: string;
  studentName?: string;
  studentRun?: string;
  campus?: string;
}

export enum PetitionStatus {
  Received = 'recibida',
  InProcess = 'en_proceso',
  Waiting = 'en_espera',
  Resolved = 'resuelta',
  Approved = 'aprobada',
  Rejected = 'rechazada',
}

export enum PetitionPaymentStatus {
  Paid = 'paid',
  Pending = 'pending',
}

export interface StudentPetitionsResponse {
  data: StudentPetitionsRow[];
  total_pages: number;
  total: number;
}

interface StudentPetitionsFilter {
  status: string[];
  statusResolved: string[];
  studyPlans: { id: number; name: string }[];
  campuses: { id: number; name: string }[];
  petitionTypes: { id: number; name: string }[];
}

export type StudentPetitionsFilterResponse = StudentPetitionsFilter;

interface StudentPetitionsExcelFilter extends StudentPetitionsFilter {
  petitionTypes: { id: number; name: string }[];
}

export interface StudentPetitionsExcelFilterResponse {
  data: StudentPetitionsExcelFilter;
}
