import {
  Button,
  OutlinedSelect,
  SelectOptionType,
  TextOutlinedInput,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { useParameters } from '../../../../hooks/useParameters';
import { useValidations } from '../../../../hooks/useValidations';
import { TranslationsKeys } from '../../../../locales/translations';
import { IdentificationDocumentCode } from '../../../../utils/enums/identification-document-code.enum';
import { useIdentificationTypeLoader } from '../loaders/IdentificationTypeLoader';

type FormValues = {
  identificationType: SelectOptionType | null;
  identification: string;
  country?: SelectOptionType | null;
};

type onFormValues = {
  identificationTypeId: string;
  identification: string;
  countryId?: string;
};

interface Props {
  handleSearch: (formValues: onFormValues) => void;
  handleReset: () => void;
}

const DEFAULT_VALUES = {
  identificationType: null,
  identification: '',
  country: null,
};

export default function Search({
  handleSearch = () => null,
  handleReset = () => null,
}: Props) {
  const prefix = 'searchBar';
  const { t } = useTranslation(
    TranslationsKeys.TEACHER_MANAGMENT_TEACHER_ACTIVATION,
  );
  const { validateRut } = useValidations();
  const { handleSubmit, control, formState, watch, setValue, reset } =
    useForm<FormValues>({
      defaultValues: {
        ...DEFAULT_VALUES,
      },
      mode: 'onChange',
    });
  const { countryOptions } = useParameters();
  const { data } = useIdentificationTypeLoader();
  const identificationType = watch('identificationType');
  const identification = watch('identification');

  useEffect(() => {
    const newValue = identification
      ?.replace('.', '')
      .replace('-', '')
      .toUpperCase();
    setValue('identification', newValue);
  }, [setValue, identification]);

  useEffect(() => {
    if (identificationType?.value !== IdentificationDocumentCode.Passport) {
      setValue('country', null);
    }
  }, [setValue, identificationType]);

  const dynamicValidate = useCallback(
    (value: string | SelectOptionType | null | undefined) => {
      // Convert the value to a string if it is not a string
      let data = value ?? undefined;
      if (data && typeof data !== 'string') {
        data = String(data.value);
      }

      if (identificationType?.value === IdentificationDocumentCode.Passport) {
        return undefined;
      }
      if (identificationType?.value === IdentificationDocumentCode.CL_RUT) {
        return validateRut(data);
      }
      if (identificationType?.rules && data) {
        const regexStr = identificationType.rules;
        const regex = new RegExp(regexStr.slice(1, -1));
        return regex.test(data) ? undefined : 'Formato inválido';
      }

      return undefined;
    },
    [identificationType, validateRut],
  );

  const identificationTypeSelect = useMemo(() => {
    return (data?.identificationTypes ?? []).map((identificationType) => ({
      value: identificationType.id,
      label: identificationType.name,
      rules: identificationType.rules,
    }));
  }, [data]);

  const onSubmit = useCallback(
    (values: FormValues) => {
      handleSearch({
        identificationTypeId: values?.identificationType?.value
          ? String(values?.identificationType?.value)
          : '',
        countryId: values?.country?.value
          ? String(values?.country?.value)
          : undefined,
        identification: values?.identification,
      });
    },
    [handleSearch],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col className="pb-3" xs={12} lg={3} xl={2}>
          <OutlinedSelect
            label={t(`${prefix}.identificationtype`)}
            name="identificationType"
            options={identificationTypeSelect}
            isSearchable={false}
            control={control}
            rules={{ required: 'Campo requerido' }}
          />
        </Col>

        {identificationType?.value === IdentificationDocumentCode.Passport && (
          <Col className="pl-3" xs={12} lg={2} xl={2}>
            <OutlinedSelect
              label={t(`${prefix}.country`)}
              name="country"
              isSearchable={true}
              placeholder={t(`${prefix}.inputCountry`)}
              control={control}
              options={countryOptions}
              rules={{ required: 'Campo requerido' }}
              shouldUnregister
            />
          </Col>
        )}

        <Col className="pl-3" xs={12} lg={4} xl={2}>
          <TextOutlinedInput
            label={t(`${prefix}.identification`)}
            name="identification"
            control={control}
            rules={{
              validate: {
                require: (value) => dynamicValidate(value),
              },
            }}
            shouldUnregister
          />
        </Col>

        <Col xs={12} lg={2} xl={2}>
          <div className="d-flex justify-content-around pt-4">
            <Button
              type="submit"
              text={t(`common:actions.search`)}
              size="md"
              fullwidth
              disabled={
                Object.keys(formState.errors).length > 0 || !identification
              }
            />
            <Button
              type="button"
              text={t(`common:actions.clean`)}
              className="mt-0 ml-1"
              outlined
              onClick={() => {
                reset({ ...DEFAULT_VALUES });
                handleReset();
              }}
              size="md"
              fullwidth
            />
          </div>
        </Col>
      </Row>
    </form>
  );
}
