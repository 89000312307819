import request from '../../../api/request';
import { DebtDetailResponse } from './types';

const CURRENT_ACCOUNT = {
  DEBT_DETAIL: (studentId: string | number, debtId: string | number) =>
    `maintainers-portal/current-accounts/students/${studentId}/debts/${debtId}`,
};

export function getDebtDetail(
  studentId: string | number,
  debtId: string | number,
) {
  return request<DebtDetailResponse>(
    CURRENT_ACCOUNT.DEBT_DETAIL(studentId, debtId),
  );
}
