import { addToast, Select, SelectOptionType } from '@octano/global-ui';
import {
  forwardRef,
  Ref,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { updateCoursesFeaturesConfig } from '../../../../api/requests/settings';
import { TranslationsKeys } from '../../../../locales/translations';
import { useCoursesFeaturesConfig } from '../hooks/useCoursesFeaturesConfig';
import { CommonSectionMethods } from '../interfaces/common-section';

enum EnableOptionCodes {
  Enabled = 'enabled',
  Disabled = 'disabled',
}

type CoursesFeaturesProps = {
  prefix?: string;
};

interface FormFields {
  replaceCourseGradeAllowed: SelectOptionType;
  replaceEvaluationGradeAllowed: SelectOptionType;
  workingDaysToResumeEvaluation: SelectOptionType | null;
}

const CoursesFeatures = (
  { prefix = 'coursesFeatures' }: CoursesFeaturesProps,
  ref: Ref<CommonSectionMethods>,
) => {
  const { t } = useTranslation(TranslationsKeys.CONFIGURATION_ACADEMIC);
  const { isLoading, config, refetch } = useCoursesFeaturesConfig();

  const [isUpdating, setIsUpdating] = useState(false);
  const { control, handleSubmit, watch, reset } = useForm<FormFields>();
  const replaceEvaluationGradeAllowed = watch('replaceEvaluationGradeAllowed');
  const retakeOptions: SelectOptionType[] = useMemo(
    () => [
      {
        label: t(
          `${prefix}.replaceCourseGradeAllowed.${EnableOptionCodes.Disabled}`,
        ),
        value: 0,
      },
      {
        label: t(
          `${prefix}.replaceCourseGradeAllowed.${EnableOptionCodes.Enabled}`,
        ),
        value: 1,
      },
    ],
    [prefix, t],
  );

  const workingDaysToResumeEvaluationOptions = useMemo(
    () =>
      Array.from({ length: 4 }).map((_, i) => {
        const number = i + 3;
        return {
          label: `${number} ${t(
            `${prefix}.workingDaysToResumeEvaluation.days`,
          )}`,
          value: number,
        };
      }),
    [prefix, t],
  );

  const updateSettings = useCallback(
    async (values: FormFields) => {
      setIsUpdating(true);

      const {
        replaceCourseGradeAllowed,
        replaceEvaluationGradeAllowed,
        workingDaysToResumeEvaluation,
      } = values;

      const { error: updateError } = await updateCoursesFeaturesConfig({
        replaceCourseGradeAllowed: Boolean(replaceCourseGradeAllowed.value),
        replaceEvaluationGradeAllowed: Boolean(
          replaceEvaluationGradeAllowed.value,
        ),
        workingDaysToResumeEvaluation: replaceEvaluationGradeAllowed.value
          ? Number(workingDaysToResumeEvaluation?.value)
          : null,
      });

      if (updateError) {
        addToast({
          icon: 'error',
          text: t(`${prefix}.errorSavingChanges`),
          color: 'danger',
        });
      } else {
        await refetch();
      }

      setIsUpdating(false);
    },
    [refetch, prefix, t],
  );

  useImperativeHandle(ref, () => ({
    save: handleSubmit(updateSettings),
    validate: async () => {},
  }));

  useEffect(() => {
    if (config) {
      reset({
        replaceCourseGradeAllowed:
          retakeOptions.find(
            (opt) => Boolean(opt.value) === config.replaceCourseGradeAllowed,
          ) ?? retakeOptions[0],
        replaceEvaluationGradeAllowed:
          retakeOptions.find(
            (opt) =>
              Boolean(opt.value) === config.replaceEvaluationGradeAllowed,
          ) ?? retakeOptions[0],
        workingDaysToResumeEvaluation:
          workingDaysToResumeEvaluationOptions.find(
            (opt) => opt.value === config.workingDaysToResumeEvaluation,
          ) ?? null,
      });
    }
  }, [workingDaysToResumeEvaluationOptions, retakeOptions, config, reset]);

  return (
    <form>
      <h2 className="text-primary fs-18 fw-600 text-uppercase mb-3 mt-5">
        {t(`${prefix}.title`)}
      </h2>
      <Select
        control={control}
        name="replaceCourseGradeAllowed"
        label={t(`${prefix}.replaceCourseGradeAllowed.label`)}
        options={retakeOptions}
        isClearable={false}
        disabled={isLoading || isUpdating}
      />
      <Select
        control={control}
        name="replaceEvaluationGradeAllowed"
        label={t(`${prefix}.replaceEvaluationGradeAllowed.label`)}
        options={retakeOptions}
        isClearable={false}
        disabled={isLoading || isUpdating}
      />
      {Boolean(replaceEvaluationGradeAllowed?.value) && (
        <Select
          control={control}
          name="workingDaysToResumeEvaluation"
          label={t(`${prefix}.workingDaysToResumeEvaluation.label`)}
          options={workingDaysToResumeEvaluationOptions}
          isClearable={false}
          disabled={isLoading || isUpdating}
        />
      )}
    </form>
  );
};

export default forwardRef(CoursesFeatures);
