import { useParams } from 'react-router';
import DebtDetailCard from './components/DebtDetailCard';
import DebtDetailLoader from './components/DebtDetailLoader';
import DebtDetailTable from './components/DebtDetailTable';

interface RouteParams {
  studentId: string;
  debtId: string;
}

function DebtDetail() {
  const { studentId, debtId } = useParams<RouteParams>();
  return (
    <DebtDetailLoader data={{ studentId, debtId }}>
      <DebtDetailCard />
      <DebtDetailTable />
    </DebtDetailLoader>
  );
}

export default DebtDetail;
