import { addToast } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Col, Row } from 'reactstrap';
import GoBackToListButton from '../../../../../components/buttons/GoBackToListButton';
import DynamicForm from '../../../../../components/DynamicForm/DynamicForm';
import { ButtonData } from '../../../../../components/DynamicForm/parts/RenderSections';
import { TranslationsKeys } from '../../../../../locales/translations';
import useActions from './hooks/useActions';
import PersonalBackgroundContact from './parts/DynamicFormSections/PersonalBackgroundContact';
import PersonalBackgroundHealthSystem from './parts/DynamicFormSections/PersonalBackgroundHealthSystem';
import PersonalBackgroundInformation from './parts/DynamicFormSections/PersonalBackgroundInformation';
import PersonalBackgroundInformationReformada from './parts/DynamicFormSections/PersonalBackgroundInformationReformada';
import PersonalBackgroundFormDataLoader from './parts/Loaders/PersonalBackgroundFormDataLoader';

function PersonalBackground() {
  const { t } = useTranslation(
    TranslationsKeys.TEACHER_MANAGMENT_TEACHER_FILE_PERSONAL_BACKGROUND,
  );
  const { teacherId } = useParams<{ teacherId: string }>();
  const actions = useActions({
    onSuccess: () => {
      addToast({
        icon: 'success',
        color: 'success',
        text: t('saveSuccess'),
      });
    },
    onError: (_, message) => {
      addToast({
        icon: 'error',
        color: 'danger',
        text: message ?? t('saveFailed'),
      });
    },
  });
  const history = useHistory();

  const sectionComponents = {
    information: <PersonalBackgroundInformation />,
    informationReformada: <PersonalBackgroundInformationReformada />,
    contactData: <PersonalBackgroundContact />,
    healthSystem: <PersonalBackgroundHealthSystem />,
  };

  const buttons: ButtonData[] = [
    {
      type: 'button',
      text: t('common:actions.back'),
      onClick: () => history.goBack(),
      disabledInSubmitting: true,
      fullwidth: true,
      outlined: true,
    },

    {
      type: 'submit',
      text: t('common:actions.save'),
      disabledInLoading: true,
      disabledInSubmitting: true,
      fullwidth: true,
    },
  ];

  return (
    <PersonalBackgroundFormDataLoader teacherId={Number(teacherId)}>
      {({ defaultValues, data }) => {
        return (
          <div className="m-4">
            <Row>
              <Col className="mb-4">
                <GoBackToListButton />
              </Col>
            </Row>
            <DynamicForm
              dynamicFormId={3}
              defaultValues={defaultValues}
              sectionComponents={sectionComponents}
              options={{
                healthInstitution: data.healthInstitutions.map((e) => ({
                  label: e.name,
                  value: e.id,
                })),
                healthCareContributorIdType: data.identificationTypes.map(
                  (e) => ({
                    label: e.name,
                    value: e.id,
                  }),
                ),
                department: data.regions.map((e) => ({
                  label: e.name,
                  value: e.id,
                })),
                city: data.cities.map((e) => ({
                  label: e.name,
                  value: e.id,
                })),
                municipality: data.communes.map((e) => ({
                  label: e.name,
                  value: e.id,
                })),
                areaType: data.areaTypes.map((e) => ({
                  label: e.name,
                  value: e.id,
                })),
                countries: data.countries.map((e) => ({
                  label: e.name,
                  value: e.id,
                })),
              }}
              formButtons={buttons}
              onSubmit={async (values) =>
                actions.saveDynamicForm(parseInt(teacherId), values)
              }
            />
          </div>
        );
      }}
    </PersonalBackgroundFormDataLoader>
  );
}

export default PersonalBackground;
