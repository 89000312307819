import { ColumnTable, Icon } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../locales/translations';

import { Prerequisite } from '../../../../types/courseMaintainerTypes';
import { CourseFormMode } from '../types';

export const useColumnsPrerequisites = ({
  mode,
  onDeletePrerequisite,
}: {
  mode: CourseFormMode;
  onDeletePrerequisite: (index: number) => void;
}) => {
  const { t } = useTranslation(TranslationsKeys.ACADEMIC_OFFER_COURSES_UPDATE);

  const columns: ColumnTable<Prerequisite>[] = useMemo(() => {
    return [
      {
        columnName: 'shortening',
        width: '30%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`prerequisiteShortening`),
      },
      {
        columnName: 'name',
        width: '40%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`prerequisiteName`),
      },
      {
        columnName: 'credits',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`prerequisiteCredits`),
      },
      {
        columnName: 'id',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`prerequisiteDelete`),
        cellFormat: ({ index }) => (
          <div
            className={mode !== CourseFormMode.WATCH ? 'cursor-pointer' : ''}
            onClick={() => {
              if (mode !== CourseFormMode.WATCH) onDeletePrerequisite(index);
            }}
          >
            <Icon
              size="15"
              name="trash"
              color={mode === CourseFormMode.WATCH ? 'disabled' : 'primary'}
            />
          </div>
        ),
      },
    ];
  }, [t, onDeletePrerequisite, mode]);

  return { columns };
};
