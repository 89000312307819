import {
  CellFormatOptions,
  ColumnTable,
  DateInput,
  Icon,
  TextInput,
  TextOutlinedInput,
} from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../../../locales/translations';
import { PartialEvaluation, PartialEvaluationSubevaluations } from '../types';
import { isBeforeLimitDate } from '../utils/validEvaluation';

export interface PartialEvaluationGradeColumnsProps {
  onChange: () => void;
  error?: boolean;
  partialEvaluation: PartialEvaluationSubevaluations;
  partialEvaluationIndex: number;
  deletePartialEvaluationByIndex: (
    row: { canItBeRemoved: boolean },
    data?: PartialEvaluation[],
  ) => any;
}

export default function useSubEvaluationGradeColumns({
  onChange,
  error,
  partialEvaluation,
  partialEvaluationIndex,
  deletePartialEvaluationByIndex,
}: PartialEvaluationGradeColumnsProps) {
  const { t } = useTranslation(
    TranslationsKeys.CONFIGURATION_GRADING_STRUCTURE,
  );

  // Callbacks
  const onNameChange = useCallback(
    (row: { name?: string }) => {
      return (e: any) => {
        row.name = e.target.value;
        onChange();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partialEvaluation, onChange],
  );

  const onDateChange = useCallback(
    (row: { date?: string | Date | null }) => {
      return (value: any) => {
        if (value instanceof Date) {
          row.date = dayjs(value).format('YYYY-MM-DD');
        }
        row.date = value;
        onChange();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partialEvaluation, onChange],
  );

  const onWeightingChange = useCallback(
    (row: { percentage?: string }) => {
      return (e: any) => {
        let value = e.target.value;
        if (
          Number(value) <= 100 &&
          Number(value) >= 0 &&
          value.search(/\D/) === -1
        ) {
          row.percentage = value;
          onChange();
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partialEvaluation, onChange],
  );

  const isExisingtNameDuplicate = useCallback(
    (index: number, name: string) => {
      let errorText = partialEvaluation.grades?.find((grade, key) => {
        if (key !== index) {
          if (!grade.name) {
            return false;
          }
          return grade.name === name;
        }
        return false;
      });
      if (errorText) {
        return `*${t('form.partialEvaluationGradeEntry.duplicationName')}`;
      }
      return undefined;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partialEvaluation.grades, t],
  );

  const columns = useMemo<ColumnTable[]>(() => {
    return [
      {
        columnName: 'evaluation',
        headerText: t('form.partialEvaluationGradeEntry.columns.evaluation'),
        width: '50%',
        thClassName: 'text-center',
        cellFormat: ({ row, index }: CellFormatOptions<PartialEvaluation>) => {
          let errorText = error && !row.name ? t('form.require') : undefined;
          let limitOfChar =
            row.name && row.name.length > 30
              ? t('form.partialEvaluationGradeEntry.charLimit')
              : undefined;
          return (
            <TextInput
              key={index}
              name={`evaluation-${partialEvaluationIndex}-subevaluation-name-${
                index as number
              }`}
              label={t(
                'form.partialEvaluationGradeEntry.columns.placeholder.nameEvaluation',
              )}
              type="text"
              value={row.name}
              errorText={
                isExisingtNameDuplicate(index as number, row.name as string) ||
                limitOfChar ||
                errorText
              }
              onChange={onNameChange(row)}
            />
          );
        },
      },
      {
        columnName: 'date',
        headerText: t('form.partialEvaluationGradeEntry.columns.date'),
        width: '40%',
        thClassName: 'text-center',
        cellFormat: (options: CellFormatOptions<PartialEvaluation>) => {
          const parentEvaluation = partialEvaluation;
          const errorText =
            error && !options.row.date ? t('form.require') : undefined;
          const errorLimitDate =
            error &&
            parentEvaluation?.hasLimitDate &&
            !isBeforeLimitDate(parentEvaluation, options.row)
              ? t('form.notValidDate')
              : undefined;

          return (
            <DateInput
              name={`evaluation-${partialEvaluationIndex}-subevaluation-date-${
                options.index as number
              }`}
              label={t(
                'form.partialEvaluationGradeEntry.columns.placeholder.surrencerDate',
              )}
              value={options.row.date}
              onChange={onDateChange(options.row)}
              shouldUnregister={true}
              errorText={errorText ?? errorLimitDate}
            />
          );
        },
      },
      {
        columnName: 'percentage',
        headerText: t('form.partialEvaluationGradeEntry.columns.percentage'),
        width: '10%',
        thClassName: 'text-center',
        tdClassName: 'expander-height-input',
        cellFormat: (options: CellFormatOptions<PartialEvaluation>) => {
          let errorText =
            error && !options.row.percentage ? t('form.require') : undefined;
          let value =
            options.row.percentage === '0' ? '' : options.row.percentage;
          return (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ gap: '8px' }}
            >
              <div style={{ maxWidth: '100px' }}>
                <TextOutlinedInput
                  type="text"
                  name={`evaluation-${partialEvaluationIndex}-subevaluation-percentage-${
                    options.index as number
                  }`}
                  placeholder="—%"
                  value={value}
                  onChange={onWeightingChange(options.row)}
                  errorText={errorText}
                />
              </div>
              <div
                style={{ cursor: 'pointer' }}
                onClick={deletePartialEvaluationByIndex(
                  options.row,
                  options.data,
                )}
              >
                <Icon name="trash" className="mb-3" />
              </div>
            </div>
          );
        },
      },
    ];
  }, [
    t,
    partialEvaluation,
    partialEvaluationIndex,
    onNameChange,
    onDateChange,
    onWeightingChange,
    isExisingtNameDuplicate,
    deletePartialEvaluationByIndex,
    error,
  ]);

  return columns;
}
