import request from '../../api/request';
import type { ReportFormData, ReportAuditOfGradesFormData } from './type';

export const REPORT = {
  FORM_DATA: '/report/form',
  FORM_AUDIT_OF_GRADES_DATA: '/report/audit-of-grades/form',
};

export function getFormData() {
  return request<ReportFormData>(REPORT.FORM_DATA, {
    method: 'get',
  });
}

export function getAuditOfGradesFormData() {
  return request<ReportAuditOfGradesFormData>(REPORT.FORM_AUDIT_OF_GRADES_DATA);
}
