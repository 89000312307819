import { AdmissionManagementFindItem } from '../../../admission/types';
import request from '../../../api/request';
import {
  StudyPlansAdmissionDetail,
  StudyPlansAdmissionDetailForm,
} from '../../../api/requests/studyPlans';
import {
  StudyPlanOffertOnlyOneResponse,
  StudyPlanOffertResponse,
} from '../../../api/requests/studyPlansOffer';
import { PaginationRequestType } from '../../../types/paginationRequestType';

const ADMISSION_MANAGEMENT = {
  //studyplan version offer
  STATUS_LIST: (id: number) =>
    `/maintainers-portal/admission-management/study-plan-version-offers/${id}/status`,
  STATUS_LIST_V2: (id: number) =>
    `/maintainers-portal/admission-management/study-plan-version-offers/${id}/status/v2`,
  POSTULANTSPENDING:
    '/maintainers-portal/admission-management/study-plan-version-offers/postulants-pending',
  SEARCH_POSTULANT_PENDING:
    '/maintainers-portal/admission-management/study-plan-version-offers/postulants-pending-search',
  GET_POSTULANT: (id: number) =>
    `/maintainers-portal/admission-management/study-plan-version-offers/${id}/postulant`, //no se usa,
  //postulants
  DELETE_POSTULANT: (id: number | string) =>
    '/maintainers-portal/admission-management/postulants/:id',
  //postulation detail
  GET_STATUS_STUDY_PLAN_CHANGE: (postulationDetailId: number) =>
    `/maintainers-portal/admission-management/postulation-details/${postulationDetailId}/check-study-plan-change`,
  TO_IN_PROCESS: (postulationDetailId: number) =>
    `/maintainers-portal/admission-management/postulation-details/${postulationDetailId}/status-in-process`,
  TO_STATUS_DESISTED: (postulationDetailId: number) =>
    `/maintainers-portal/admission-management/postulation-details/${postulationDetailId}/status-desisted`,
  TO_STATUS_RETRACTED: (postulationDetailId: number) =>
    `/maintainers-portal/admission-management/postulation-details/${postulationDetailId}/status-retracted`,
  //
  STUDY_PLAN_ADMISSION_DETAIL_FORM:
    '/maintainers-portal/admission-management/form',
  STUDY_PLAN_ADMISSION_DETAIL: '/maintainers-portal/admission-management',
};

/**
 * Eliminar postulante de lista pendientes
 */
export const deletePostulant = (id: number) => {
  return request(ADMISSION_MANAGEMENT.DELETE_POSTULANT(id), {
    method: 'DELETE',
  });
};

/**
 * Busqueda de postulantes pendientes
 */
export const searchPostulantPending = (
  { search }: { search?: string },
  { page }: { page: number },
) => {
  const params: Record<string, string | number> = {
    items_per_page: 10,
    page: page - 1,
  };
  if (search) {
    params['search'] = search;
  }
  return request<StudyPlanOffertResponse>(
    ADMISSION_MANAGEMENT.SEARCH_POSTULANT_PENDING,
    { params },
  );
};

export const getPostulansPendingRequest = () => {
  return request<{ data: number }>(ADMISSION_MANAGEMENT.POSTULANTSPENDING, {
    method: 'GET',
  });
};

/**
 * obtiene la lista de admisión en espera
 */
export const getAdmissionStatusList = (
  academicOfferStudyPlanVersionOffersId: number,
  { search, status }: { search?: string; status: any },
  { page }: { page: number },
) => {
  const url = ADMISSION_MANAGEMENT.STATUS_LIST_V2(
    academicOfferStudyPlanVersionOffersId,
  );
  const params: Record<string, string | number> = { status, page: page - 1 };
  if (search) {
    params['search'] = search;
  }
  return request<StudyPlanOffertResponse<AdmissionManagementFindItem>>(url, {
    params,
  });
};

export const getAdmissionDetailsForm = () => {
  const url = ADMISSION_MANAGEMENT.STUDY_PLAN_ADMISSION_DETAIL_FORM;
  return request<StudyPlansAdmissionDetailForm>(url, {
    method: 'get',
  });
};

export const getAdmissionDetail = ({
  periodId,
  campusId,
  schoolId,
  items_per_page,
  page,
}: {
  periodId: number;
  campusId?: number;
  schoolId?: number;
  items_per_page: number;
  page: number;
}) => {
  return request<PaginationRequestType<StudyPlansAdmissionDetail>>(
    ADMISSION_MANAGEMENT.STUDY_PLAN_ADMISSION_DETAIL,
    {
      method: 'GET',
      params: {
        periodId,
        campusId,
        schoolId,
        items_per_page,
        page: page - 1,
      },
    },
  );
};

/**
 * NO SE USA
 * obtiene un postulante de admisión en espera.
 */
export const getAdmissionStatusPostulant = (
  academicOfferStudyPlanVersionOffersId: number,
  params: { postulantId: number; status: any },
) => {
  return request<StudyPlanOffertOnlyOneResponse>(
    ADMISSION_MANAGEMENT.GET_POSTULANT(academicOfferStudyPlanVersionOffersId),
    { params },
  );
};

export const getStatusStudyPlanChange = (postulationDetailId: number) => {
  return request<{
    status: string;
    studyPlanChangeDetail: {
      admissionId: number;
      amount: number;
      enrolledDate: string | null;
      id: number;
      isPaid: boolean;
      mustPay: boolean;
      position: number | null;
      postulationId: number;
      retractedDate: string | null;
      score: number | null;
      status: string;
    };
  }>(ADMISSION_MANAGEMENT.GET_STATUS_STUDY_PLAN_CHANGE(postulationDetailId));
};

export const changeToInProcess = (postulationDetailId: number) => {
  return request(ADMISSION_MANAGEMENT.TO_IN_PROCESS(postulationDetailId), {
    method: 'PUT',
  });
};

/**
 * Cambiar postulante a desistido
 */
export const changeStatusToDesist = (postulationDetailId: number) => {
  return request(ADMISSION_MANAGEMENT.TO_STATUS_DESISTED(postulationDetailId), {
    method: 'PUT',
  });
};

export const changeStatusToRetracted = (
  postulationDetailId: number | { id: number },
  data: { showWarning: boolean },
) => {
  let id = 0;
  if (typeof postulationDetailId === 'number') {
    id = postulationDetailId;
  } else {
    id = postulationDetailId.id;
  }

  return request<StudyPlanOffertResponse>(
    ADMISSION_MANAGEMENT.TO_STATUS_RETRACTED(id),
    { method: 'PUT', data },
  );
};
