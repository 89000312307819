import { Button, Table, TablePagination } from '@octano/global-ui';
import { ColumnTable } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import DisplayError from '../../components/info/DisplayError';
import SearchRut from '../../components/inputs/SearchRutValue';
import TableEmptyContent from '../../components/text/TableEmptyContent';
import { useLayoutState } from '../../hooks/useLayoutState';
import { IdNotFound } from '../components/IdNotFound';
import { useAdmissionStatusList } from '../contexts/AdmissionStatusListContext';
import { AdmissionManagementFindItem } from '../types';
import { DesistAdmissionModal } from './DesistAdmissionModal';
import { InProcessDiscountModal } from './InProcessDiscountModal';
import DiscountField from './parts/DiscountField';
import { DiscountsLoader } from './parts/DiscountsLoader';
import { PostulantDiscountsLoader } from './parts/PostulantDiscountsLoader';

function InProcessList() {
  const history = useHistory();
  const {
    loading: loadingList,
    data,
    error: errorList,
    query,
    updateQuery,
  } = useAdmissionStatusList();

  const totalItems = data.total;
  const totalPages = data.total_pages;
  const currentPage = query.page;
  const [studentId, setStudentId] = useState<number>(0);
  const [
    postulationItem,
    setPostulationItem,
  ] = useState<AdmissionManagementFindItem>();
  const [name, setName] = useState<string>('');
  const showPagination = data.total_pages > 1;

  const [showAssignDiscount, setShowAssignDiscount] = useState<boolean>(false);
  const [showDesistAdmission, setShowDesistAdmission] = useState<boolean>(
    false,
  );

  const { showTabs } = useLayoutState();

  useEffect(() => {
    showTabs();
  }, [showTabs]);

  const { t } = useTranslation();
  const prefix = 'admission';

  const toSetDiscount = (item: AdmissionManagementFindItem) => {
    setPostulationItem(item);
    setShowAssignDiscount(true);
  };

  //Discounts columns
  const columns: ColumnTable<AdmissionManagementFindItem>[] = [
    {
      columnName: 'rut',
      headerText: t(`common.forms.rut`),
      width: '110px',
      cellFormat: (options) =>
        `${options.row.postulantIdentificationValue} (${options.row.postulantIdentificationType})`,
    },
    {
      columnName: 'postulantFistname',
      headerText: t(`common.forms.names`),
      width: '160px',
    },
    {
      columnName: 'postulantLastname',
      headerText: t(`common.forms.lastName`),
      width: '160px',
    },
    {
      columnName: 'postulantPhone',
      headerText: t(`common.forms.phone`),
      width: '150px',
    },
    {
      columnName: 'postulantEmail',
      headerText: t(`common.forms.email`),
      width: '150px',
    },

    {
      columnName: 'Discount_discountValue',
      headerText: t(`common.forms.discount`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (options) => {
        return <DiscountField options={options} />;
      },
    },
    {
      columnName: 'Details_postulationId',
      headerText: '',
      width: '20%',
      cellFormat: (options) => (
        <div className="d-flex justify-content-end">
          <Button
            text={t(`${prefix}.giveDiscount`)}
            size="sm"
            onClick={() => toSetDiscount(options.row)}
          />
          <Button
            size="sm"
            outlined
            color="danger"
            style={{ marginLeft: '10px', minWidth: '100px' }}
            onClick={() =>
              changeToDesist(
                options.row.postulationDetailId,
                options.row.postulantName,
              )
            }
            text={t(`${prefix}.desist`)}
          />
          <Button
            text={t(`${prefix}.goToEnrollement`)}
            size="sm"
            style={{ marginLeft: '10px' }}
            onClick={() => {
              history.push(
                `/tuition-process?id=${options.row.postulantIdentificationValue}`,
              );
            }}
          />
        </div>
      ),
    },
  ];

  /**
   * Abrir modal para desistir proceso
   */
  const changeToDesist = (id: number, postulantName: string) => {
    setStudentId(id);
    setName(postulantName);
    setShowDesistAdmission(true);
  };

  const TableEmpty = useMemo(() => {
    if (query.search) {
      return <IdNotFound />;
    } else
      return (
        <TableEmptyContent
          title={t(`${prefix}.studentInProcess.emptyList`)}
          subtitle={''}
        />
      );
  }, [query, t]);

  if (errorList) {
    return (
      <DisplayError
        insideCard
        textBody={errorList}
        retryAction={() => {
          updateQuery();
        }}
      />
    );
  }
  return (
    <div className="mt-3 text-info">
      <SearchRut
        value={query.search}
        onChange={(search) => updateQuery({ page: 1, search })}
        searching={loadingList}
      />

      <Table
        columns={columns}
        data={data.data}
        noResultsText={TableEmpty}
        isLoadingResults={loadingList}
      />

      {showPagination && (
        <TablePagination
          pagination={{
            currentPage,
            itemsPerPage: 10,
            onChangePage: (page) => {
              updateQuery({ page, search: query.search });
            },
            totalItems,
            totalPages,
          }}
        />
      )}

      {postulationItem?.postulationDetailId ? (
        <DiscountsLoader>
          <PostulantDiscountsLoader
            postulationDetailId={postulationItem?.postulationDetailId}
          >
            <InProcessDiscountModal
              show={showAssignDiscount}
              toggleModal={() => {
                setShowAssignDiscount(false);
              }}
              postulationDetailId={postulationItem?.postulationDetailId}
            />
          </PostulantDiscountsLoader>
        </DiscountsLoader>
      ) : null}

      <DesistAdmissionModal
        show={showDesistAdmission}
        toggleModal={() => {
          setShowDesistAdmission(false);
        }}
        reloadList={() => updateQuery()}
        id={studentId}
        name={name}
      />
    </div>
  );
}

export default InProcessList;
