import {
  Button,
  CheckInput,
  DateInput,
  FileInputControlled,
  Modal,
  TextInput,
} from '@octano/global-ui';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useValidations } from '../../../../../../../hooks/useValidations';
import { useTexts } from '../../hooks/textsContext';
import { UseActions } from '../../hooks/useActions';

export interface CreateOrUpdateRef {
  closeModal: () => void;
  openModal: (row?: any) => void;
}

interface Props {
  actions: UseActions;
}

interface FormData {
  id: number | null;
  position: string;
  areaSpecialty: string;
  institution: string;
  immediateBossName: string;
  immediateBossPhoneNumber: string;
  startDate: Date;
  endDate: Date | null;
  endDateF: Date | null;
  isCurrent: boolean;
  linkedFile: File & { url: string; name: string; extension: string };
}

const CreateOrUpdateModal = forwardRef<CreateOrUpdateRef, Props>(
  ({ actions }, ref) => {
    const { teacherId } = useParams<{ teacherId: string }>();
    const texts = useTexts();

    const forms = useForm<FormData>({
      mode: 'onChange',
    });
    const {
      control,
      watch,
      reset,
      handleSubmit,
      formState: { isSubmitting },
    } = forms;

    const isCurrent = watch('isCurrent');
    const { msgValidations } = useValidations();
    const [modal, setModal] = useState(false);
    const [formData, setFormData] = useState<FormData | null>(null);

    useImperativeHandle(ref, () => ({
      openModal(row) {
        setFormData(row ?? null);
        setModal(true);
      },
      closeModal() {
        setModal(false);
      },
    }));

    useEffect(() => {
      if (formData) {
        reset({
          id: formData.id,
          position: formData.position,
          areaSpecialty: formData?.areaSpecialty,
          institution: formData.institution,
          immediateBossName: formData.immediateBossName,
          immediateBossPhoneNumber: formData.immediateBossPhoneNumber,
          startDate: formData.startDate,
          endDate: formData.endDate,
          isCurrent: formData.isCurrent,
          linkedFile: formData?.linkedFile,
        });
      } else {
        reset({
          id: null,
          position: '',
          areaSpecialty: '',
          institution: '',
          immediateBossName: '',
          immediateBossPhoneNumber: '',
          startDate: '',
          endDate: null,
          isCurrent: false,
          linkedFile: '',
        });
      }
    }, [formData, reset]);

    const onSubmit = async (values: FormData) => {
      const data = {
        position: values.position,
        areaSpecialty: values.areaSpecialty,
        institution: values.institution,
        immediateBossName: values.immediateBossName,
        immediateBossPhoneNumber: values.immediateBossPhoneNumber,
        startDate: values.startDate,
        endDate: values?.endDate,
        isCurrent: values.isCurrent,
        doc: values?.linkedFile,
      };

      if (formData) {
        await actions.updateTitle(Number(formData.id), data);
      } else {
        await actions.createTitle(Number(teacherId), data);
      }
      reset();
      setModal(false);
    };

    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
        <Container fluid>
          <h1 className="text-dark fs-20 mt-3 mb-4 text-center">
            {texts.modal.title}
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={12} xs={12}>
                <TextInput
                  name="position"
                  label={texts.form.position}
                  control={control}
                  rules={{ required: msgValidations.required }}
                />
              </Col>
              <Col md={6} xs={12}>
                <TextInput
                  name="areaSpecialty"
                  label={texts.form.areaSpecialty}
                  control={control}
                  rules={{ required: msgValidations.required }}
                />
              </Col>
              <Col md={6} xs={12}>
                <TextInput
                  name="institution"
                  label={texts.form.institution}
                  control={control}
                  rules={{ required: msgValidations.required }}
                />
              </Col>
              <Col md={6} xs={12}>
                <TextInput
                  name="immediateBossName"
                  label={texts.form.immediateBossName}
                  control={control}
                />
              </Col>
              <Col md={6} xs={12}>
                <TextInput
                  name="immediateBossPhoneNumber"
                  label={texts.form.immediateBossPhoneNumber}
                  control={control}
                />
              </Col>
              <Col md={6} xs={12}>
                <DateInput
                  name="startDate"
                  label={texts.form.startDate}
                  control={control}
                  rules={{ required: msgValidations.required }}
                />
              </Col>
              <Col md={6} xs={12}>
                {isCurrent ? (
                  <DateInput
                    name="endDateF"
                    label={texts.form.endDate}
                    control={control}
                  />
                ) : (
                  <DateInput
                    name="endDate"
                    label={texts.form.endDate}
                    control={control}
                    disabled={isCurrent}
                    rules={{ required: msgValidations.required }}
                  />
                )}
                <CheckInput
                  name="isCurrent"
                  control={control}
                  label={texts.form.isCurrent}
                />
              </Col>
              <Col md={12} xs={12} className="pb-5">
                <FileInputControlled
                  name="linkedFile"
                  label={texts.form.linkedFile}
                  accept="image/png, image/jpeg, application/pdf"
                  btnText={texts.actions.upload}
                  control={control}
                />
              </Col>
              <hr className="py-5" />
              <Col xs={12} md={6}>
                <Button
                  outlined
                  text={texts.actions.cancel}
                  fullwidth
                  onClick={() => {
                    reset();
                    setModal(false);
                  }}
                  className="mb-3"
                />
              </Col>
              <Col xs={12} md={6}>
                <Button
                  type="submit"
                  text={texts.actions.save}
                  className="mb-3"
                  fullwidth
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
          </form>
        </Container>
      </Modal>
    );
  },
);

export default CreateOrUpdateModal;
