import { ReactElement, useCallback } from 'react';
import { createFetchContext } from '../../../../../../components/contexts/FetchContextFactory';
import { requestCourses } from '../../../api/study-plan-curriculums';

export interface BaseLoaderProps {
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  { courseTypeId: number },
  {
    id: number;
    name: string;
    code: string;
    shortening: string;
    credits: number;
    types: { id: number; name: string; generalTypeId: number }[];
  }[]
>();

export function useCourseModalLoader() {
  return useFetch();
}

export const CourseModalLoaderConsumer = FetchConsumer;

export default function CourseModalLoader({ children }: BaseLoaderProps) {
  const request = useCallback((query: { courseTypeId: number }) => {
    return requestCourses(query.courseTypeId);
  }, []);

  return (
    <FetchProvider
      request={request}
      defaultQuery={{ courseTypeId: 0 }}
      fetchImmediately={false}
    >
      {children}
    </FetchProvider>
  );
}
