import { TariffPaymentMethodSettingValue } from '../../../../types/tariffTypes';

export const hasError = ({
  maxTariffDownPaymentRate,
}: TariffPaymentMethodSettingValue): string | null => {
  if (maxTariffDownPaymentRate < 0) {
    return `toasts.minTariffDownPaymentError`;
  }
  if (maxTariffDownPaymentRate > 1) {
    return `toasts.maxTariffDownPaymentError`;
  }
  return null;
};
