import request from '../../../../../api/request';
import { TeacherInfoResponse } from '../parts/type';
import {
  EducationalBackgroundFormDataResponse,
  EducationalBackgroundResponse,
  FormDataAction,
} from './type';

const EDUCATIONAL_BACKGROUND = {
  GET_TEACHER_INFO: (teacherId: string | number) =>
    `maintainers-portal/teacher-management/teacher-file/${teacherId}`,
  FORM_DATA: () =>
    `maintainers-portal/management-tools/teacher-file-educational-background/form`,
  GET_TITLES: (teacherId: string | number) =>
    `maintainers-portal/management-tools/teacher-file-educational-background/${teacherId}`,
  ADD_TITLE: (teacherId: string | number) =>
    `maintainers-portal/management-tools/teacher-file-educational-background/${teacherId}/degrees`,
  UPDATE_TITLE: (educationalBackgroundId: string | number) =>
    `maintainers-portal/management-tools/teacher-file-educational-background/${educationalBackgroundId}`,
  UPDATE_FILE: (educationalBackgroundId: string | number) =>
    `maintainers-portal/management-tools/teacher-file-educational-background/${educationalBackgroundId}/file`,
  PURGE_FILES: (educationalBackgroundId: string | number) =>
    `maintainers-portal/management-tools/teacher-file-educational-background/${educationalBackgroundId}/file-purge`,
  DELETE_TITLE: (educationalBackgroundId: string | number) =>
    `maintainers-portal/management-tools/teacher-file-educational-background/${educationalBackgroundId}`,
};

export async function getTeacherInfo(teacherId: number) {
  return request<TeacherInfoResponse>(
    EDUCATIONAL_BACKGROUND.GET_TEACHER_INFO(teacherId),
    {
      method: 'GET',
    },
  );
}

export async function getFormData() {
  return request<EducationalBackgroundFormDataResponse>(
    EDUCATIONAL_BACKGROUND.FORM_DATA(),
    {
      method: 'GET',
    },
  );
}

export async function getTitles(teacherId: number) {
  return request<EducationalBackgroundResponse>(
    EDUCATIONAL_BACKGROUND.GET_TITLES(teacherId),
    {
      method: 'GET',
    },
  );
}

export async function purgeFiles(id: number, filesToKeep: string[]) {
  return request(EDUCATIONAL_BACKGROUND.PURGE_FILES(id), {
    method: 'post',
    data: {
      filesToKeep,
    },
  });
}

export async function addFile(
  id: number,
  data: { linkedFile: File; code: string },
) {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]: [string, any]) => {
    if (value instanceof File) {
      formData.append(key, value, value.name);
    } else if (value) {
      formData.append(key, value);
    }
  });
  return request(EDUCATIONAL_BACKGROUND.UPDATE_FILE(id), {
    method: 'post',
    data: formData,
  });
}

export async function addTitle(
  teacherId: number,
  data: Omit<FormDataAction, 'files'>,
) {
  return request(EDUCATIONAL_BACKGROUND.ADD_TITLE(teacherId), {
    method: 'POST',
    data,
  });
}

export async function updateTitle(
  id: number,
  data: Omit<FormDataAction, 'files'>,
) {
  return request(EDUCATIONAL_BACKGROUND.UPDATE_TITLE(id), {
    method: 'PUT',
    data,
  });
}

export async function deleteTitle(educationalBackgroundId: number) {
  return request(EDUCATIONAL_BACKGROUND.DELETE_TITLE(educationalBackgroundId), {
    method: 'DELETE',
  });
}
