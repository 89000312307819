import { Table } from '@octano/global-ui';
import { Card, FormText } from 'reactstrap';
import Loading from '../../../../components/info/Loading';
import useColumns from '../hooks/useColumns';
import { useDebtDetailLoader } from './DebtDetailLoader';
import { useTranslation } from 'react-i18next';

const dictPrefix = 'studentCurrentAccount.tabs.currentAccount.detail';

function DebtDetailTable() {
  const { data, loading } = useDebtDetailLoader();
  const { t } = useTranslation();

  const columns = useColumns();

  if (loading || !data) {
    return (
      <div className="d-flex justify-content-center">
        <Loading />
      </div>
    );
  }

  return (
    <Card className="p-4 mx-3">
      <FormText color={'primary'} className="font-weight-bold pb-3 fs-20">
        {t(`${dictPrefix}.tableTitle`)}
      </FormText>

      <Table
        columns={columns}
        data={data.paidDebts}
        isLoadingResults={loading}
      />
    </Card>
  );
}

export default DebtDetailTable;
