import { SelectOptionType } from '@octano/global-ui';

export interface onSearch {
  rut?: string;
  country?: string;
  passport?: string;
}

export interface SearchAdminAccount {
  Account_id: number;
  ACCOUNT_RUN: string | null;
  Account_name: string;
  Account_paternalLastName: string;
  Account_maternalLastName: string;
  Account_birthday: string;
  Account_phone: string | null;
  Account_email: string | null;
  Account_personalEmail: string | null;
  ACCOUNT_PASSPORTNUMBER: string | null;
  Account_teacherId: string | null;
  Account_studentId: string | null;
  Account_isActive: boolean;
  Country_id: string | null;
  Country_name: string | null;
  Nationality_id: string | null;
  Nationality_name: string | null;
  Role_id: string | null;
  Role_name: string | null;
}

export interface AdminAccountForm {
  id?: number;
  rut: string;
  passportNumber: string;
  passportCountry?: SelectOptionType;
  names: string;
  lastnamePather: string;
  lastnameMother: string;
  birthdate?: Date;
  nationality?: SelectOptionType;
  phone: string;
  personalEmail: string;
  email: string;
  role?: SelectOptionType;
  teacherId?: string | null;
  studentId?: string | null;
  isActive: boolean;
}

export interface CreateAdminAccountBody {
  type: 'rut' | 'passport';
  name: string;
  run?: string;
  passportNumber?: string;
  countryId?: string | number;
  nationalityId?: string | number;
  paternalLastName: string;
  maternalLastName: string;
  nationality?: string | number;
  birthday: string;
  personalEmail: string;
  phone: string;
  email: string;
  rolesId?: string | number;
}

export type UpdateAdminAccountBody = Pick<
  CreateAdminAccountBody,
  | 'name'
  | 'paternalLastName'
  | 'maternalLastName'
  | 'birthday'
  | 'nationalityId'
  | 'personalEmail'
  | 'email'
  | 'phone'
  | 'rolesId'
> & { id?: number };

export enum PermissionType {
  Student = 'Alumno',
  Teacher = 'Docente',
}
