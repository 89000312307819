const REPORT_TYPES = [
  'STUDENTS_WITHOUT_EMAIL',
  'LESSONS_TEACHER',
  'SECTIONS_GRADES',
  'STUDENT_SECTION_ENROLLED',
  'ACADEMIC',
  'LEARNING_RESULTS',
] as const;
export type ReportType = (typeof REPORT_TYPES)[number];

export interface Report {
  id: number;
  name: string;
  description: string;
  downloadUrl: string;
  type: ReportType;
  renderSide: ExcelRenderSideEnum;
}

export interface ReportsResponse {
  data: Report[];
  total_pages: number;
  total: number;
}

export enum ExcelRenderSideEnum {
  Server = 'back',
  Client = 'front',
}
