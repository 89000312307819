import { Icon, IconColorType, IconNameType } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PetitionPaymentStatus } from '../../../types/studentPetitions';

const dictPrefix = 'studentPetitions.common.paymentStatus';

export default function IconOptionPetitionsPayment({
  statusPayment,
}: {
  statusPayment: PetitionPaymentStatus;
}) {
  const { t } = useTranslation();

  const data = useMemo<{ color: IconColorType; icon: IconNameType }>(() => {
    const statusColor: Record<PetitionPaymentStatus, string> = {
      [PetitionPaymentStatus.Pending]: 'info',
      [PetitionPaymentStatus.Paid]: 'success',
    };
    const statusIcon: Record<PetitionPaymentStatus, string> = {
      [PetitionPaymentStatus.Pending]: 'warning',
      [PetitionPaymentStatus.Paid]: 'success',
    };
    return {
      color: statusColor[statusPayment] as IconColorType,
      icon: statusIcon[statusPayment] as IconNameType,
    };
  }, [statusPayment]);

  return (
    <>
      <div>
        <Icon
          className={'icon-select'}
          color={data.color}
          name={data.icon}
          size="15px"
        />
        <span>{t(`${dictPrefix}.${statusPayment}`)}</span>
      </div>
    </>
  );
}
