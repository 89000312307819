import { ReactElement, useCallback } from 'react';
import { AxiosResultDefaultError } from '../../../../../../../api/request';
import { AuthenticationError } from '../../../../../../../api/requests/tuitionProcess';
import { createFetchContext } from '../../../../../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../../../../../components/info/DisplayError';
import Loading from '../../../../../../../components/info/Loading';
import { getFormData } from '../../api';
import { FieldValues } from 'react-hook-form';
import { PersonalBackgroundFormDataResponse } from '../../type';
import { useParameters } from '../../../../../../../hooks/useParameters';
import { getSelectedOption } from '../../../../../../../utils/selectFormat';
interface BaseLoaderProps {
  teacherId: number;
  children: ({
    defaultValues,
    data,
  }: {
    defaultValues: FieldValues;
    data: PersonalBackgroundFormDataResponse;
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  PersonalBackgroundFormDataResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export function usePersonalBackgroundFormDataLoader() {
  return useFetch();
}

export const personalBackgroundFormDataLoaderConsumer = FetchConsumer;

export default function PersonalBackgroundFormDataLoader({
  teacherId,
  children,
}: BaseLoaderProps) {
  const request = useCallback(async () => {
    return getFormData(teacherId);
  }, [teacherId]);

  const { countryOptions } = useParameters();

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ loading, data, error, refresh }) => {
          if (loading) {
            return <Loading insideCard />;
          } else if (error) {
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
              />
            );
          } else if (data) {
            return children({
              defaultValues: {
                ...data?.responses,
                passportCountry: data?.responses?.passportCountry
                  ? getSelectedOption(
                      data?.responses?.passportCountry?.id,
                      countryOptions,
                    )
                  : undefined,
              },
              data,
            });
          } else {
            return null;
          }
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
