import { SelectOptionType } from '@octano/global-ui';
import dayjs from 'dayjs';
import {
  ModalFormData,
  defaultValues,
} from './parts/Modals/CreateOrUpdateModalForm';
import { EducationalBackgroundType, EducationRecord } from './type';

const optionFormat = (option: { id: number; name: string }) => ({
  label: option.name,
  value: option.id,
});

const optionFormatOriginInstitute = (option: { id: number; name: string }) => {
  const item = optionFormat(option);
  return {
    ...item,
    label: `${item.label} (${item.value})`,
  };
};

export const educationRecordToForm = ({
  type,
  row,
  originInstituteOther,
  recognizedOptions = [],
}: {
  type: EducationalBackgroundType;
  row?: EducationRecord;
  originInstituteOther: SelectOptionType;
  recognizedOptions: SelectOptionType[];
}): Partial<ModalFormData> =>
  row
    ? {
        type,
        id: row?.id ?? null,
        name: row?.name?.trim() ?? '',
        studyCarriedOut: row?.studyCarriedOut?.trim() ?? '',
        isRecognized:
          recognizedOptions.find(
            (e) => row?.isRecognized === (e.value as any),
          ) ?? null,
        originInstituteCustom: row?.originInstituteCustom?.trim() ?? '',
        description: row?.description?.trim() ?? '',
        dateOfIssue: dayjs(row.dateOfIssue).toDate(),
        country: row.country ? optionFormat(row.country as any) : null,
        degreeLevel: row.degreeLevel
          ? optionFormat(row.degreeLevel as any)
          : null,
        originInstitute: row.originInstitute
          ? optionFormatOriginInstitute(row.originInstitute as any)
          : originInstituteOther,
        files:
          row.files?.map((e) => ({
            code: e.code,
            fileUrl: e.fileUrl,
            linkedFile: new File(
              [] as any,
              `${e?.linkedFile?.name}.${e?.linkedFile?.extension}`,
              { lastModified: -1 },
            ),
          })) ?? [],
      }
    : { ...defaultValues, type };
