import { ColumnTable } from '@octano/global-ui';

import { AdmissionManagementFindItem } from './types';

export function getAdmissionColumnsStudent(
  t: (t: string) => string,
): ColumnTable<AdmissionManagementFindItem>[] {
  return [
    {
      columnName: 'postulantIdentificationValue',
      headerText: t(`common.forms.rut`),
      width: '10%',
      cellFormat: (options) =>
        `${options.row.postulantIdentificationValue} (${options.row.postulantIdentificationType})`,
    },
    {
      columnName: 'postulantFistname',
      headerText: t(`common.forms.names`),
      width: '10%',
    },
    {
      columnName: 'postulantLastname',
      headerText: t(`common.forms.lastName`),
      width: '10%',
    },
    {
      columnName: 'postulantPhone',
      headerText: t(`common.forms.phone`),
      width: '10%',
    },
    {
      columnName: 'postulantEmail',
      headerText: t(`common.forms.email`),
      width: '10%',
    },
  ];
}
