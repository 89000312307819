import {
  Button,
  PaginationType,
  SelectOptionType,
  Table,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';

import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import { PathsLayouts } from '../../../config/routes';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { TranslationsKeys } from '../../../locales/translations';
import { CourseMaintainerListType } from '../../../types/courseMaintainerTypes';
import { getSchoolListRequest, requestCoursesMaintainerList } from './api';
import SearchFilters, { SearchFiltersType } from './parts/SearchFilters';
import { useColumnsCoursesList } from './hooks/useColumnsCoursesList';

const ITEMS_PER_PAGE = 10;

const CoursesMaintainer = () => {
  const { t } = useTranslation(TranslationsKeys.ACADEMIC_OFFER_COURSES_LIST);
  const history = useHistory();
  const [dataList, setDataList] = useState<CourseMaintainerListType[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastFilters, setLastFilters] = useState<{
    modality: SelectOptionType;
    studyPlanVersion: SelectOptionType;
    faculty: SelectOptionType;
    searchText: string;
  }>();
  const [schools, setSchools] = useState<SelectOptionType[]>([]);
  const [modalities, setModalities] = useState<SelectOptionType[]>([]);
  const [studyPlanVersions, setStudyPlanVersions] = useState<
    SelectOptionType[]
  >([]);

  const columns = useColumnsCoursesList();
  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();
  const [searching, setSearching] = useState<boolean>(true);

  const getSchools = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getSchoolListRequest();
    if (data) {
      setSchools(
        data.schools.map((item) => ({ value: item.id, label: item.name })),
      );
      setModalities(
        data.modalities.map((item) => ({ value: item.id, label: item.name })),
      );
      setStudyPlanVersions(
        data.studyPlanVersions.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      );
    } else if (error) {
      setErrorLoading(error.code);
    }
    setLoading(false);
  }, [setErrorLoading, setSchools, setLoading]);

  useEffect(() => {
    getSchools();
  }, [getSchools]);

  const getData = useCallback(
    async (page: number) => {
      setSearching(true);

      const requestFilters = {
        schoolId: lastFilters?.faculty?.value,
        modalityId: lastFilters?.modality?.value,
        studyPlanVersionId: lastFilters?.studyPlanVersion?.value,
        search_text: lastFilters?.searchText,
      };

      const { data, error } = await requestCoursesMaintainerList({
        items_per_page: ITEMS_PER_PAGE,
        page: page - 1,
        ...requestFilters,
      });

      if (data?.data) {
        setDataList(data.data);
        setPagination({
          totalItems: data.total,
          itemsPerPage: ITEMS_PER_PAGE,
          totalPages: data.total_pages,
          currentPage: page,
          onChangePage: (pageNumber: number) => setCurrentPage(pageNumber),
        });
      }
      if (error) {
        setErrorLoading(error.code);
      }
      setSearching(false);
    },
    [setSearching, setErrorLoading, lastFilters],
  );

  useEffect(() => {
    getData(currentPage);
  }, [currentPage, getData]);

  const isSearch = useMemo(() => {
    return Boolean(lastFilters?.faculty) || lastFilters?.searchText !== '';
  }, [lastFilters]);

  if (loading) {
    return (
      <div className="mx-3">
        <Loading insideCard />
      </div>
    );
  }
  if (errorLoading) {
    return (
      <div className="mx-3">
        <DisplayError
          insideCard
          textBody={errorLoading}
          retryAction={() => {
            setErrorLoading(undefined);
            getSchools();
          }}
          loadingAction={loading}
        />
      </div>
    );
  }
  return (
    <Card className="p-4 mt-2 mx-3">
      <Row>
        <Col xs={12}>
          <SearchFilters
            schools={schools}
            studyPlanVersions={studyPlanVersions}
            modalities={modalities}
            onSearch={(filters: SearchFiltersType) => {
              setLastFilters({
                faculty: filters.schools,
                searchText: filters.nameOrCode || '',
                modality: filters.modalities,
                studyPlanVersion: filters.studyPlanVersions,
              });
              setCurrentPage(1);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="pb-3">
          <Button
            icon="plus"
            size="md"
            text={t('btnNewCourse')}
            onClick={() => {
              history.push(
                history.push(
                  `${PathsLayouts.academicOffers}/curricular-subjects/create`,
                ),
              );
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Table<CourseMaintainerListType>
            columns={columns}
            data={dataList}
            pagination={pagination}
            isLoadingResults={searching}
            noResultsText={
              <TableEmptyContent
                title={t(isSearch ? 'noResultsSearchTitle' : 'noResultsTitle')}
                subtitle={t(isSearch ? 'noResultsSearchBody' : 'noResultsBody')}
              />
            }
          />
        </Col>
      </Row>
    </Card>
  );
};

export default CoursesMaintainer;
