import { ColumnTable, Icon } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../locales/translations';
import { QualitativeAssessmentRange } from '../interfaces/qualitative-assessment-range.interface';

const prefix = 'qualitativeAssessmentRange.table';

export type QualitativeAssessmentRangeRow = Omit<
  QualitativeAssessmentRange,
  'id'
> & {
  id?: number;
};

type UseQualitativeAssessmentRangeColumnsProps = {
  onRemove: (index: number) => void;
};

export const useQualitativeAssessmentRangeColumns = ({
  onRemove,
}: UseQualitativeAssessmentRangeColumnsProps) => {
  const { t } = useTranslation(TranslationsKeys.CONFIGURATION_ACADEMIC);

  const columns = useMemo<ColumnTable<QualitativeAssessmentRangeRow>[]>(
    () => [
      {
        columnName: 'name',
        thClassName: 'text-center',
        tdClassName: 'text-center text-nowrap',
        headerText: t(`${prefix}.name`),
      },
      {
        columnName: 'range',
        thClassName: 'text-center',
        tdClassName: 'text-center text-nowrap',
        headerText: t(`${prefix}.range`),
        width: 100,
        cellFormat({ row, index, data }) {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ gap: 5 }}
            >
              <span className="fs-20 text-success">[</span>
              <span
                className="border d-flex justify-content-center text-dark fs-16 align-items-center"
                style={{ width: 42, height: 42 }}
              >
                {row.start}
              </span>
              <span
                className="border d-flex justify-content-center text-dark fs-16 align-items-center"
                style={{ width: 42, height: 42 }}
              >
                {row.end}
              </span>
              {index === (data?.length ?? 0) - 1 ? (
                <span className="fs-20 text-success">]</span>
              ) : (
                <span className="fs-20 text-danger">)</span>
              )}
            </div>
          );
        },
      },
      {
        columnName: 'action',
        thClassName: 'text-center',
        tdClassName: 'text-center text-nowrap',
        headerText: '',
        width: 90,
        cellFormat({ index, data }) {
          return index === (data?.length ?? 0) - 1 ? (
            <span className="mb-3" onClick={() => onRemove(index)}>
              <Icon
                className={`cursor-pointer`}
                name="trash"
                key="eye"
                size="18px"
              />
            </span>
          ) : (
            <></>
          );
        },
      },
    ],
    [t, onRemove],
  );

  return columns;
};
