import { TextInput, DateInput } from '@octano/global-ui';
import { Col, Row } from 'reactstrap';

export interface SummaryPetitionProps {
  petitionTitle: string;
  petitionType: string;
  studyPlan: string;
  petitionDate: string;
  evaluationDate: string | undefined;
  section: { id: number; name: string } | undefined;
  texts: {
    petitionTitle: string;
    petitionType: string;
    studyPlan: string;
    createdAt: string;
    evaluationDate: string;
    sectionName: string;
  };
}

export default function StudentPetitionSummary({
  texts,
  ...data
}: SummaryPetitionProps) {
  return (
    <>
      <Row>
        <Col xs={12} sm={6} md={4} xl="">
          <TextInput
            name="petitionTitle"
            label={texts.petitionTitle}
            value={data.petitionTitle}
            disabled
          />
        </Col>
        <Col xs={12} sm={6} md={4}>
          <TextInput
            name="petitionType"
            label={texts.petitionType}
            value={data.petitionType}
            disabled
          />
        </Col>
        <Col xs={12} sm={6} md={4} xl="">
          <TextInput
            name="studyPlan"
            label={texts.studyPlan}
            value={data.studyPlan}
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6} md={4}>
          <DateInput
            name="createdAt"
            label={texts.createdAt}
            value={data.petitionDate}
            disabled
          />
        </Col>
        {data.evaluationDate && (
          <Col xs={12} sm={6} md={4}>
            <DateInput
              name="evaluationDate"
              label={texts.evaluationDate}
              value={data.evaluationDate}
              disabled
            />
          </Col>
        )}
        {data.section && (
          <Col xs={12} sm={6} md={4}>
            <TextInput
              name="section"
              label={texts.sectionName}
              value={data.section.name}
              disabled
            />
          </Col>
        )}
      </Row>
    </>
  );
}
