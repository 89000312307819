export interface TeacherAccountData {
  birthday: string;
  name: string;
  paternalLastName: string;
  maternalLastName: string;
  email: string;
  personalEmail?: string;
  status: TeacherAccountStatus;
  lastLogin: string | null;
  run: string;
  identification: string;
}

export enum TeacherAccountStatus {
  ACTIVE = 'active',
  INACTIVE = 'no active',
}
