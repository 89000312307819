import React, { useEffect, useCallback } from 'react';
import { Row, Col, Input } from 'reactstrap';
import { CardTitle } from '../../../../../../components/text';
import { Select, TextInput } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useValidations } from '../../../../../../hooks/useValidations';
import { useParameters } from '../../../../../../hooks/useParameters';
import { useCourseModalLoader } from './CourseModalLoader';
const prefix = 'studyPlans.curriculum.courseModal';

function CourseModalForm() {
  const { control, watch, setValue } = useFormContext();
  const { msgValidations } = useValidations();
  const { courseTypeOptions } = useParameters();
  const { data: obligatoryCourses = [], updateQuery } = useCourseModalLoader();
  const [typeValue, nameValue] = watch(['type', 'name']);
  const { t } = useTranslation();

  const resetFields = useCallback(() => {
    setValue('code', '');
    setValue('courseCode', '');
    setValue('credits', '');
    setValue('courseCredits', '');
    setValue('name', null);
    setValue('courseName', '');
  }, [setValue]);

  const fillFields = useCallback(
    (id: any) => {
      const row = obligatoryCourses.find(
        (course) => course.id === parseInt(id?.value || -1),
      );

      if (row) {
        setValue('code', row?.shortening);
        setValue('courseCode', row?.code);
        setValue('credits', row?.credits.toString());
        setValue('courseCredits', row?.credits.toString());
        setValue('courseName', row?.name);
      } else {
        resetFields();
      }
    },
    [resetFields, setValue, obligatoryCourses],
  );

  useEffect(() => {
    fillFields(nameValue);
  }, [nameValue, fillFields]);

  useEffect(() => {
    if (typeof typeValue?.value === 'number') {
      updateQuery({ courseTypeId: typeValue?.value });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeValue]);

  return (
    <Row>
      <Col>
        <div className="text-center">
          <CardTitle
            children={t(`${prefix}.title`, {
              type: 'Asignatura',
            })}
          />
        </div>
        <Row className="mt-4 mt-md-5">
          <Col xs={12}>
            <Select
              name="type"
              label={t(`${prefix}.type`)}
              options={courseTypeOptions}
              control={control}
              rules={{ required: msgValidations.required }}
            />
          </Col>
        </Row>
        {/* //antes preguntaba si el value era actividad o requerido */}
        {typeValue?.generalTypeId === 1 && (
          <Row>
            <Col xs={12} md>
              <Select
                name="name"
                label={t(`${prefix}.name`, {
                  type: 'Asignatura',
                })}
                options={obligatoryCourses.map((course) => ({
                  label: course.name,
                  value: course.id,
                  shortening: course.shortening,
                }))}
                control={control}
                rules={{ required: msgValidations.required }}
              />
              <Input type="hidden" name="courseName" control={control} />
            </Col>
          </Row>
        )}
        <Row>
          {/* //antes preguntaba si el value era actividad o requerido */}
          {typeValue?.generalTypeId === 1 && (
            <Col xs={12}>
              <TextInput
                name="code"
                label={t(`${prefix}.code`)}
                control={control}
                disabled
                rules={{
                  required: msgValidations.required,
                }}
              />
              <Input type="hidden" name="courseCode" control={control} />
            </Col>
          )}
          <Col xs={12}>
            <TextInput
              name="credits"
              label={t(`${prefix}.credits`)}
              control={control}
              disabled={typeValue?.generalTypeId === 1}
              rules={{
                required: msgValidations.required,
                maxLength: {
                  value: 50,
                  message: t('common.validations.maxLength', {
                    length: 50,
                  }),
                },
                pattern: {
                  value: /^[0-9]*$/,
                  message: t('common.validations.invalidIntNumeric'),
                },
              }}
            />
            <Input type="hidden" name="courseCredits" control={control} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default CourseModalForm;
