import { useCallback, ReactNode } from 'react';
import { AxiosResultDefaultError } from '../../../../api/request';
import { AuthenticationError } from '../../../../api/requests/tuitionProcess';
import { createFetchContext } from '../../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { getDebtDetail } from '../api';
import { DebtDetailResponse } from '../types';

interface DebtDetailLoaderProps {
  data: {
    debtId: number | string;
    studentId: number | string;
  };
  children: ReactNode;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  DebtDetailResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export function useDebtDetailLoader() {
  return useFetch();
}

export const DebtDetailLoaderConsumer = FetchConsumer;

export default function DebtDetailLoader({
  children,
  data,
}: DebtDetailLoaderProps) {
  const request = useCallback(async () => {
    const { studentId, debtId } = data;

    return getDebtDetail(studentId, debtId);
  }, [data]);

  return (
    <FetchProvider
      request={request}
      defaultQuery={undefined}
      fetchImmediately={true}
    >
      <FetchConsumer>
        {({ loading, data, error, refresh }) => {
          if (loading) {
            return <Loading insideCard />;
          } else if (error) {
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
              />
            );
          } else if (data) {
            return children;
          } else {
            return null;
          }
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
