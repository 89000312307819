import { useCallback, useEffect, useRef, useState } from 'react';

import { getQualitativeAssessmentRangeConfig } from '../../../../api/requests/settings';
import { QualitativeAssessmentRangeResponse } from '../interfaces/qualitative-assessment-range.interface';

export const useQualitativeAssessmentRangeConfig = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isRefetching, setIsRefetching] = useState(false);
  const alreadyFetched = useRef(false);

  const [config, setConfig] = useState<QualitativeAssessmentRangeResponse>();
  const [error, setError] = useState(false);

  const fetchConfig = useCallback(async () => {
    if (alreadyFetched.current) setIsRefetching(true);
    else alreadyFetched.current = true;

    const { data, error: fetchError } =
      await getQualitativeAssessmentRangeConfig();

    if (fetchError) {
      setError(true);
    } else {
      setConfig(data ?? undefined);
    }

    setIsLoading(false);
    setIsRefetching(false);
  }, [alreadyFetched]);

  useEffect(() => {
    if (!alreadyFetched.current) {
      fetchConfig();
    }
  }, [fetchConfig]);

  return {
    isLoading,
    config,
    error,
    refetch: fetchConfig,
    isRefetching,
  };
};
