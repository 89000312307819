import { Button, Table, TablePagination, ColumnTable } from '@octano/global-ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DisplayError from '../../components/info/DisplayError';
import SearchRut from '../../components/inputs/SearchRutValue';
import { ModalConfirmation } from '../../components/modals/Confirmation';
import ConfirmationModal from '../../components/modals/ConfirmationModal';
import TableEmptyContent from '../../components/text/TableEmptyContent';
import { showToast } from '../../utils/toast';
import { changeToInProcess } from '../../views/AdmissionAndEnrollments/AdmissionManagement/api';
import { IdNotFound } from '../components/IdNotFound';
import { useAdmissionStatusList } from '../contexts/AdmissionStatusListContext';
import { getAdmissionColumnsStudent } from '../getAdmissionColumns';
import { AdmissionManagementFindItem } from '../types';

const ITEMS_PER_PAGE_DEFAULT = 10;

const prefix = 'admission';

export default function AdmissionsListDropped(): JSX.Element {
  const { loading, data, error, query, updateQuery } = useAdmissionStatusList();
  const showPagination = data.total_pages > 1;

  const { t } = useTranslation();

  const currentPage = query.page;
  const totalItems = data.total;
  const totalPages = data.total_pages;
  const searching = loading;

  const [duplicatedProcess, setDuplicatedProcess] = useState<{
    campus: string;
    schedule: string;
    studyPlan: string;
    status: string;
  } | null>(null);

  const [selectMoveProcessID, setSelectMoveProcessID] = useState<number>(-1);

  const [modalConfirm, setModalConfirm] = useState<{
    open: boolean;
    student?: string;
  }>({
    open: false,
    student: '',
  });

  const columnsListStudentDropped = useMemo<
    ColumnTable<AdmissionManagementFindItem>[]
  >(() => {
    return [
      ...getAdmissionColumnsStudent(t),
      {
        columnName: 'action',
        headerText: '',
        width: '10%',
        tdClassName: 'text-nowrap text-center',
        cellFormat: (options) => {
          return (
            <Button
              text={t('admission.moveToProcess')}
              size="sm"
              color="primary"
              onClick={() => {
                setSelectMoveProcessID(options.row.postulationDetailId);
                setModalConfirm({
                  open: true,
                  student: options.row.postulantName,
                });
              }}
            />
          );
        },
      },
    ];
  }, [t]);

  function onClose(): void {
    setModalConfirm({ open: false });
  }

  async function onConfirm(): Promise<void> {
    let responseChanging = await changeToInProcess(selectMoveProcessID);
    if (responseChanging.error) {
      const errorData = responseChanging.error.data;
      if (errorData.code === 'duplicated_postulation' && errorData.data) {
        setDuplicatedProcess({
          campus: errorData.Campus_name,
          schedule: errorData.Schedule_name,
          studyPlan: errorData.StudyPlanVersion_name,
          status: t(
            `common.statusTuitionProcess.${errorData.PostulationDetails_status}`,
          ),
        });
      } else {
        showToast(Boolean(error), t);
      }
    } else {
      updateQuery({ ...query });
    }
  }

  const TableEmpty = useMemo(() => {
    if (query.search) {
      return <IdNotFound />;
    } else {
      return (
        <TableEmptyContent
          title={t(`${prefix}.studentDropped.messageNotDropped`)}
          subtitle={''}
        />
      );
    }
  }, [query, t]);

  if (error) {
    return (
      <DisplayError
        insideCard
        textBody={error}
        retryAction={() => updateQuery()}
        loadingAction={loading}
      />
    );
  }
  return (
    <div className="mt-3 text-info">
      <SearchRut
        value={query.search}
        onChange={(search) => {
          updateQuery({ page: 1, search });
        }}
        searching={searching}
      />
      <Table
        columns={columnsListStudentDropped}
        data={data.data}
        noResultsText={TableEmpty}
        isLoadingResults={loading}
      />

      {showPagination && (
        <TablePagination
          pagination={{
            currentPage,
            itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
            onChangePage: (page) => {
              updateQuery({ page, search: query.search });
            },
            totalItems,
            totalPages,
          }}
        />
      )}
      <ModalConfirmation
        open={modalConfirm.open}
        title={t('admission.modalConfirmation.titleStudentDropped')}
        onClose={onClose}
        onConfirm={onConfirm}
        columns={{
          title: t('admission.modalConfirmation.student'),
          li: <li>{modalConfirm.student || ''}</li>,
        }}
      />

      {/* MODAL PARA INFORMAR QUE ESTÁ ACTUALMENTE EN OTRO PROCESO */}
      <ConfirmationModal
        iconName="warning"
        isOpen={duplicatedProcess !== null}
        toggle={() => setDuplicatedProcess(null)}
        title={t(`${prefix}.modalDuplicatedProcess.title`)}
        body={t(`${prefix}.modalDuplicatedProcess.body`)}
        primaryBtn={{
          text: t(`common.actions.understood`),
          action: () => setDuplicatedProcess(null),
        }}
        boxDetail={{
          title: t(`${prefix}.modalDuplicatedProcess.offer`),
          body: [
            <span>
              {duplicatedProcess?.studyPlan} {duplicatedProcess?.campus}{' '}
              {duplicatedProcess?.schedule} <br />
              <b>
                {t(`${prefix}.modalDuplicatedProcess.status`)}:{' '}
                {duplicatedProcess?.status}
              </b>
            </span>,
          ],
        }}
      />
    </div>
  );
}
