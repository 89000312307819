import {
  Button,
  Modal,
  OutlinedSelect,
  OutlinedSelectOptionType,
  useMobile,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { baseToSelectOption } from '../../academicOffers/courses/utils';
import { getAuditOfGradesFormData } from '../api';
import { AuditOfGradesResultFilters } from '../type';
import LoadingInputsModal from './LoadingInputsModal';
import NoResultsModal from './NoResultsModal';

type FormFields = AuditOfGradesResultFilters<OutlinedSelectOptionType | null>;

const defaultValues: FormFields = {
  period: null,
  course: null,
  section: null,
};

interface Props {
  isOpen: boolean;
  modalTitle: string;
  onConfirm?: (periodId: number, courseId: number, sectionId: number) => void;
  onCancel?: () => void;
}

export default function SelectPeriodCourseSectionModal({
  isOpen,
  modalTitle,
  onConfirm = () => null,
  onCancel = () => null,
}: Props) {
  const prefix = 'reports.auditOfGrades';
  const { t } = useTranslation();
  const { control, reset, watch, setValue } = useForm<FormFields>({
    defaultValues,
  });
  const selectedPeriod = watch('period');
  const selectedCourse = watch('course');
  const selectedSection = watch('section');

  const isMobile = useMobile();
  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();

  const [periods, setPeriods] = useState<{ id: number; name: string }[]>([]);
  const [courses, setCourses] = useState<{ id: number; name: string }[]>([]);
  const [sections, setSections] = useState<
    {
      id: number;
      name: string;
      period: {
        id: number;
      };
      course: { id: number };
    }[]
  >([]);

  const getInputs = useCallback(async () => {
    const { data, error } = await getAuditOfGradesFormData();
    if (error) {
      setErrorLoading(error.code);
      throw new Error(error.code);
    } else if (data) {
      setPeriods(data.periods);
      setCourses(data.courses);
      setSections(data.sections);
    }
  }, [setErrorLoading]);

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      await Promise.all([getInputs()]);
      setErrorLoading(undefined);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [getInputs, setErrorLoading, setLoading]);

  useEffect(() => {
    reset();
    if (isOpen) getData();
  }, [getData, isOpen, reset]);

  const optionsPeriods = useMemo<OutlinedSelectOptionType[]>(() => {
    return baseToSelectOption(periods);
  }, [periods]);

  const optionsCourses = useMemo<OutlinedSelectOptionType[]>(() => {
    return baseToSelectOption(courses);
  }, [courses]);

  const optionsSections = useMemo<OutlinedSelectOptionType[]>(() => {
    const filteredSections = sections.filter(
      (section) =>
        section.period.id === selectedPeriod?.value &&
        section.course.id === selectedCourse?.value,
    );
    return baseToSelectOption(filteredSections);
  }, [selectedPeriod, selectedCourse, sections]);

  useEffect(() => {
    setValue('section', null);
  }, [optionsSections, setValue]);

  const isDisabledSubmit = useMemo(() => {
    return (
      !selectedPeriod?.value ||
      !selectedCourse?.value ||
      !selectedSection?.value
    );
  }, [selectedPeriod, selectedCourse, selectedSection]);

  if (loading && isOpen) {
    return <LoadingInputsModal isOpen />;
  }

  if (errorLoading && isOpen) {
    return (
      <NoResultsModal
        isOpen
        onConfirm={onCancel}
        texts={{
          title: t(`${prefix}.noResults.title`),
          description: t(`${prefix}.noResults.description`),
        }}
      />
    );
  }

  return (
    <Modal isOpen={isOpen} toggle={() => null}>
      <div>
        <h1 className="text-dark text-center fw-600 fs-20">{modalTitle}</h1>
        <p className=" text-center fs-16 lh-30 mt-4 mb-5">
          {t(`${prefix}.datesConfig.subtitle`)}
        </p>
        <Row>
          <Col xs={12}>
            <OutlinedSelect
              name="period"
              label={t(`${prefix}.form.period`)}
              control={control}
              options={optionsPeriods}
              isClearable={false}
            />
          </Col>
          <Col xs={12}>
            <OutlinedSelect
              name="course"
              label={t(`${prefix}.form.course`)}
              control={control}
              options={optionsCourses}
              isClearable={false}
            />
          </Col>
          <Col xs={12}>
            <OutlinedSelect
              name="section"
              label={t(`${prefix}.form.section`)}
              control={control}
              options={optionsSections}
              isClearable={false}
              disabled={!selectedPeriod?.value || !selectedCourse?.value}
            />
          </Col>
        </Row>
        <div
          className="d-flex flex-wrap justify-content-between mt-5"
          style={{ gap: '1rem' }}
        >
          <Button
            text={t(`common:actions.cancel`)}
            outlined
            style={{ width: isMobile ? '100%' : 197 }}
            onClick={onCancel}
          />
          <Button
            text={t(`common:actions.download`)}
            style={{ width: isMobile ? '100%' : 197 }}
            disabled={isDisabledSubmit}
            onClick={() =>
              onConfirm(
                selectedPeriod!.value as number,
                selectedCourse!.value as number,
                selectedSection!.value as number,
              )
            }
          />
        </div>
      </div>
    </Modal>
  );
}
