import { DetailBox, Icon } from '@octano/global-ui';
import { Card, Col, Row, CardBody, FormText } from 'reactstrap';
import GoBackToListButton from '../../../../components/buttons/GoBackToListButton';
import { useDebtDetailLoader } from './DebtDetailLoader';
import Loading from '../../../../components/info/Loading';
import Tooltip from '../../../../components/tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

const formatter = (value: number) =>
  `$${value.toLocaleString('es-CL', {
    style: 'decimal',
    currency: 'CLP',
  })}`;

const dictPrefix = 'studentCurrentAccount.tabs.currentAccount.detail';

function DebtDetailCard() {
  const { data, loading } = useDebtDetailLoader();
  const { t } = useTranslation();

  if (loading || !data) {
    return (
      <div className="d-flex justify-content-center">
        <Loading />
      </div>
    );
  }

  const {
    studentName,
    rutOrPassport,
    concept,
    numberOfInstallment,
    expirationDate,
    daysLate,
    interestRate,
    capitalAmount,
    interestAmount,
    totalAmount,
    paidAmount,
    outstandingDebt,
  } = data;

  const CardItem = ({
    title,
    content,
    paddingTop = '13px',
    paddingRight = '20px',
    contentSize = '16px',
    contentColor = '',
    tooltip = '',
    minContentWidth = '80px',
  }: {
    title: string;
    content: string | number;
    paddingTop?: string;
    paddingRight?: string;
    contentSize?: string;
    contentColor?: string;
    tooltip?: string;
    minContentWidth?: string;
  }) => {
    return (
      <div className="d-flex flex-column" style={{ paddingTop, paddingRight }}>
        <p className="text-muted m-0" style={{ fontSize: '14px' }}>
          {title}
        </p>
        <FormText
          className="font-weight-bold m-0"
          style={{
            fontSize: contentSize,
            display: 'flex',
            gap: '10px',
            maxWidth: '250px',
            minWidth: minContentWidth,
          }}
          color={contentColor}
        >
          {content}
          {tooltip && (
            <Tooltip id={`info_tooltip`} placement="right" text={tooltip}>
              <Icon name="information" size={14.71} />
            </Tooltip>
          )}
        </FormText>
      </div>
    );
  };

  return (
    <Card className="p-4 mx-3">
      <Row>
        <Col className="mb-4" xs={12}>
          <div className="d-flex flex-wrap w-100 align-items-start justify-content-center">
            <GoBackToListButton text={'volver al listado'} />
            <div className="flex-fill px-2" />
            <Col>
              <DetailBox
                columns={[
                  { title: 'Alumno', body: studentName },
                  { title: 'Id', body: rutOrPassport },
                ]}
              />
            </Col>
          </div>
        </Col>
        <Col>
          <Card className="border border-primary p-2" style={{ width: '80%' }}>
            <div className="px-3 pt-3">
              <p className="fs-20 font-weight-bold m-0">
                {t(`${dictPrefix}.cardTitle`)}
              </p>
            </div>
            <CardBody>
              <div className="d-flex flex-row flex-wrap gap-4">
                <CardItem
                  title="Deuda pendiente"
                  content={formatter(outstandingDebt)}
                  paddingRight="50px"
                  contentSize="30px"
                  paddingTop="0"
                  contentColor="primary"
                  minContentWidth="120px"
                />
                <CardItem
                  title="Concepto"
                  content={concept}
                  contentSize={'14px'}
                  minContentWidth="250px"
                />
                <CardItem title="N° cuota" content={numberOfInstallment} />
                <CardItem
                  title="Monto capital"
                  content={formatter(capitalAmount)}
                />
                <CardItem
                  title="Monto total"
                  content={formatter(totalAmount)}
                />
                <CardItem
                  title="Monto interés"
                  content={formatter(interestAmount)}
                />

                <div className="w-100 pb-4"></div>

                <CardItem
                  title="Pagado a la fecha"
                  content={formatter(paidAmount)}
                  paddingRight="50px"
                  contentSize="30px"
                  paddingTop="0"
                  contentColor="primary"
                  minContentWidth="120px"
                />
                <CardItem
                  title="Tasa de interés"
                  content={interestRate + '%'}
                  minContentWidth="250px"
                />
                <CardItem
                  title="Fecha de vcto."
                  content={expirationDate.replaceAll('-', '/')}
                />
                <CardItem title="Días de mora" content={daysLate + ' días'} />
                <CardItem
                  title="Codeudor"
                  content="Nombre Codeudor"
                  tooltip={'Detalle de acuerdo con Codeudor'}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Card>
  );
}

export default DebtDetailCard;
