import { addToast, Button } from '@octano/global-ui';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import { updatePeriodRequest } from '../../../api/requests/periods';
import GoBackButton from '../../../components/buttons/GoBackButton';
import { useLayoutState } from '../../../hooks/useLayoutState';
import { PeriodRecordLoader } from './PeriodRecordLoader';
import PeriodsForm from './PeriodsForm';
import { PeriodsTypeLoader } from './PeriodsTypeLoader';

const PeriodRecordEdit = () => {
  const { hideTabs, hideErrorModal } = useLayoutState();
  useEffect(() => {
    hideTabs();
    hideErrorModal();
  }, [hideTabs, hideErrorModal]);

  let history = useHistory();
  const goBack = useCallback(() => {
    history.push(`/maintainers/periods`);
  }, [history]);

  const prefix = 'maintainers.periodsForm';
  const { t } = useTranslation();

  const { id: periodId } = useParams<{ id?: string }>();

  const onSubmit = useCallback(
    async (values) => {
      if (!periodId) {
        return;
      }
      const response = await updatePeriodRequest(periodId, values);
      if (!response.error) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.editSuccess`),
        });
        goBack();
      } else {
        return response.error.code;
      }
    },
    [periodId, goBack, t],
  );

  if (!periodId) {
    return <Redirect to="/maintainers/periods/record" />;
  }

  return (
    <Container>
      <Card className="p-3 p-md-4">
        <div className="mb-4">
          <GoBackButton onClick={goBack} />
        </div>
        <PeriodsTypeLoader>
          {({ data }) => (
            <PeriodRecordLoader periodId={periodId}>
              {(period) => {
                const defaultValues = {
                  periodType: {
                    label: period.periodType.name,
                    value: period.periodType.id,
                  },
                  code: period.code,
                  name: period.name,
                  description: period.description ?? '',
                  startDate: period.startDate,
                  endDate: period.endDate,
                  creditMin: period.creditMin,
                  creditMax: period.creditMax,
                  academicYear: period.academicYear,
                };
                return (
                  <PeriodsForm
                    periodsType={data}
                    defaultAcademicCalendars={period.academicCalendars}
                    defaultValues={defaultValues}
                    onSubmit={onSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Row className="pt-5">
                        <Col xs={12} md={4} className="ml-auto">
                          <div>
                            <Button
                              type="submit"
                              text={t(`${prefix}.editPeriod`)}
                              loading={isSubmitting}
                              fullwidth
                            />
                          </div>
                        </Col>
                      </Row>
                    )}
                  </PeriodsForm>
                );
              }}
            </PeriodRecordLoader>
          )}
        </PeriodsTypeLoader>
      </Card>
    </Container>
  );
};

export default PeriodRecordEdit;
