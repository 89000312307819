// Components
import { CheckInput, TextInput } from '@octano/global-ui';
// Hooks
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { TranslationsKeys } from '../../../../locales/translations';

import { formatToOnlyPositiveScaledIntegers } from '../../../../utils/currency';
// Utils
import { FinantialSettingsContext } from '../contexts/finantial-settings.context';
// Types
import { FinantialConfig } from '../interfaces/finantial-config.interface';

const prefix = 'tariffDownPayment';

// Render
export default function TariffDownPaymentSection() {
  const { t } = useTranslation(TranslationsKeys.CONFIGURATION_FINANTIAL);

  const finantialSettingsContext = useContext(FinantialSettingsContext);

  const { control, watch } = useFormContext<FinantialConfig>();

  const [hasTariffDownPayment] = watch(['hasTariffDownPayment']);

  return (
    <Row className="mt-4">
      <Col xs={12}>
        <h2 className="text-primary fs-16 fw-600 text-uppercase">
          {t(`${prefix}.title`)}
        </h2>
      </Col>
      <Col sm={12} md={6}>
        <CheckInput
          name="hasTariffDownPayment"
          label={t(`${prefix}.inputs.hasTariffDownPayment`)}
          control={control}
          disabled={finantialSettingsContext.disabled}
        />
        {!!hasTariffDownPayment && (
          <TextInput
            control={control}
            name="maxTariffDownPaymentRate"
            label={t(`${prefix}.inputs.maxTariffDownPaymentRate`)}
            disabled={finantialSettingsContext.disabled}
            formatter={formatToOnlyPositiveScaledIntegers}
          />
        )}
      </Col>
    </Row>
  );
}
