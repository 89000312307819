import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Card } from 'reactstrap';

import { searchTeacherByRut } from '../../../api/requests/teacherActivation';
import DisplayInfo from '../../../components/info/DisplayInfo';
import Loading from '../../../components/info/Loading';
import { PathsLayouts } from '../../../config/routes';
import { TranslationsKeys } from '../../../locales/translations';
import {
  TeacherAccountData,
  TeacherAccountStatus,
} from '../../../types/Teacher';
import AccountDetailBox from './parts/AccountDetailBox';
import ConfirmModal, { ConfirmModalRef } from './parts/ConfirmModal';
import { DisplayInfoUser } from './parts/DisplayInfoUser/DisplayInfoUser';
import Search from './parts/Search';
import TeacherDataForm from './parts/TeacherDataForm';

export default function TeacherCreate() {
  const { t } = useTranslation(
    TranslationsKeys.TEACHER_MANAGMENT_TEACHER_ACTIVATION,
  );
  const history = useHistory();

  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [error, setError] = useState<'NOT-FOUND' | 'UNKNOWN'>();
  const [teacherData, setTeacherData] = useState<TeacherAccountData>();

  const [lastSearchedIdentification, setLastSearchedIdentification] = useState(
    '',
  );
  const [
    lastSearchedIdentificationTypeId,
    setLastSearchedIdentificationTypeId,
  ] = useState<string>('');
  const [lastSearchedCountryId, setLastSearchedCountryId] = useState<
    string | undefined
  >();

  const haventSearchYet = !isLoadingSearch && !error && !teacherData;

  const ConfirmModalRef = useRef<ConfirmModalRef | undefined>();

  // eslint-disable-next-line
  const openModal = (row: any) => {
    ConfirmModalRef.current?.openModal(row);
  };
  const handleConfirm = () => {
    history.push(`${PathsLayouts.teacherActivation}/personal-background`);
  };

  const handleDecline = () => {
    history.push(PathsLayouts.teacherActivation);
  };

  const handleSearch = async ({
    identificationTypeId,
    identification,
    countryId,
  }: {
    identificationTypeId: string;
    identification: string;
    countryId?: string;
  }) => {
    setLastSearchedIdentificationTypeId(identificationTypeId);
    setLastSearchedIdentification(identification);
    setLastSearchedCountryId(countryId);
    setIsLoadingSearch(true);
    setTeacherData(undefined);
    setError(undefined);

    const { data, error: err } = await searchTeacherByRut({
      identification,
      identificationTypeId,
      countryId,
    });
    setIsLoadingSearch(false);

    if (err) {
      if (err.code === 'HTTP_ERROR' && err.status === 404) {
        setError('NOT-FOUND');
      } else {
        setError('UNKNOWN');
      }

      return;
    }

    setTeacherData(data || undefined);
  };

  const handleEmailChangeInUI = (email: string) => {
    setTeacherData((prev) => {
      return prev ? { ...prev, email } : undefined;
    });
  };

  const handleDeactivationInUI = () => {
    setTeacherData((prev) => {
      return prev
        ? { ...prev, status: TeacherAccountStatus.INACTIVE }
        : undefined;
    });
  };

  const handleReset = () => {
    setTeacherData(undefined);
    setError(undefined);
  };

  return (
    <div className="mx-3">
      <Search handleSearch={handleSearch} handleReset={handleReset} />
      <Card
        className="p-4"
        style={{
          height: teacherData || error === 'NOT-FOUND' ? 'fit-content' : 400,
        }}
      >
        {teacherData ? (
          <>
            <div className="d-flex flex-wrap justify-content-between mb-5">
              <h1 className="text-primary text-uppercase fs-22 fw-600">
                {t(`formTitle`)}
              </h1>
              <AccountDetailBox
                status={teacherData?.status || ''}
                lastLogin={teacherData?.lastLogin || ''}
              />
              <DisplayInfoUser />
            </div>
            <TeacherDataForm
              canDeactivateAccount={
                teacherData.status === TeacherAccountStatus.ACTIVE
              }
              canActivateAccount={
                teacherData.status === TeacherAccountStatus.INACTIVE
              }
              canRecoverPassword={
                teacherData.status === TeacherAccountStatus.ACTIVE
              }
              initialValues={{
                identificationTypeId: lastSearchedIdentificationTypeId,
                countryId: lastSearchedCountryId,
                identification: teacherData?.identification,
                names: teacherData?.name,
                maternalLastName: teacherData?.maternalLastName,
                paternalLastName: teacherData?.paternalLastName,
                email: teacherData?.email,
                personalEmail: teacherData?.personalEmail,
                birthday: teacherData?.birthday,
              }}
              onEmailChangeSuccess={handleEmailChangeInUI}
              onDeactivationSuccess={handleDeactivationInUI}
            />
          </>
        ) : (
          <div className="d-flex align-items-center justify-content-center h-100">
            {isLoadingSearch && <Loading />}
            {!isLoadingSearch && haventSearchYet && (
              <DisplayInfo
                title={t(`searchDefaultMessages.title`)}
                textBody={t(`searchDefaultMessages.description`)}
                maxWidth={250}
              />
            )}
            {error === 'UNKNOWN' && (
              <DisplayInfo
                title={t(`errorSearchingRut.title`)}
                textBody={t(`errorSearchingRut.description`)}
                maxWidth={320}
              />
            )}
            {error === 'NOT-FOUND' && (
              <div className="d-flex flex-column w-100">
                <div className="align-self-end">
                  <AccountDetailBox status="unregistered" />
                </div>
                <DisplayInfo
                  title={t(`rutNotFound.title`)}
                  textBody={t(`rutNotFound.description`)}
                  maxWidth={480}
                />
                <h1 className="text-primary text-uppercase fs-22 fw-600 mb-4 mt-3">
                  {t(`formTitle`)}
                </h1>
                <TeacherDataForm
                  canActivateAccount
                  enableAllFields
                  initialValues={{
                    identificationTypeId: lastSearchedIdentificationTypeId,
                    identification: lastSearchedIdentification,
                    countryId: lastSearchedCountryId,
                  }}
                  onEmailChangeSuccess={handleEmailChangeInUI}
                />
              </div>
            )}
          </div>
        )}
      </Card>
      <ConfirmModal
        ref={ConfirmModalRef as React.Ref<ConfirmModalRef>}
        onDecline={handleDecline}
        onConfirm={handleConfirm}
      />
    </div>
  );
}
