import { Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';
import { TranslationsKeys } from '../../../../../locales/translations';

const prefix = 'teacherForm';

export function DisplayInfoUser(): JSX.Element {
  const { t } = useTranslation(
    TranslationsKeys.TEACHER_MANAGMENT_TEACHER_ACTIVATION,
  );

  return (
    <Col md={12}>
      <div className="py-2 d-flex justify-content-start align-items-center">
        <Icon name="information" color="secondary" size="16px" />
        <span className="fs-16 px-2">{t(`${prefix}.infoFound`)}</span>
      </div>
    </Col>
  );
}
