import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../../../locales/translations';

export interface TotalPercentageFooterProps {
  dataLength: number;
  columnsLength: number;
  error?: boolean;
  percentageValid?: boolean;
  percentage?: string | number;
  isSecondaryFooter?: boolean;
}

export default function TotalPercentageFooter({
  dataLength,
  columnsLength,
  error,
  percentageValid,
  percentage,
  isSecondaryFooter = false,
}: TotalPercentageFooterProps) {
  const { t } = useTranslation(
    TranslationsKeys.CONFIGURATION_GRADING_STRUCTURE,
  );

  const contentTr = (children: React.ReactNode) =>
    isSecondaryFooter ? (
      <tr
        style={{
          background: '#f0f0f0',
        }}
      >
        {children}
      </tr>
    ) : (
      <tr className="alert-primary text-primary">{children}</tr>
    );

  if (dataLength <= 0) {
    return (
      <tr>
        <td colSpan={columnsLength} className="text-center py-4">
          <span>{t('form.partialEvaluationGradeEntry.empty')}</span>
        </td>
      </tr>
    );
  }
  return contentTr(
    <>
      <td
        className="text-primary"
        style={{ height: 'unset', minHeight: 'unset' }}
        colSpan={columnsLength - 1}
      >
        {isSecondaryFooter ? (
          <span>{t('form.partialEvaluationGradeEntry.totalSecondary')}</span>
        ) : (
          <span>{t('form.partialEvaluationGradeEntry.total')}</span>
        )}
      </td>
      <td
        className={error && !percentageValid ? 'text-danger' : 'text-primary'}
        style={{ height: 'unset', minHeight: 'unset' }}
      >
        {percentage ?? '-'}%
      </td>
    </>,
  );
}
